import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import {
    Autocomplete,
    Chip,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    MenuItem,
    Modal,
    Radio,
    RadioGroup,
    Switch,
    TextField,
    Tooltip,
    tooltipClasses
} from "@mui/material";
import { Avatar } from "react-lorem-ipsum";
import { CssTextField3 } from "./CssTextField3";
import { ReactSession } from "react-client-session";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import useFetch from "./UseFetch";
import Editor3 from "./Editor3";
import Fragen from "./Fragen";
import { makeStyles, styled } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";
import { CssTextField2 } from "./CssTextField2";
import ContentState from "draft-js/lib/ContentState";
import { EditorState } from "draft-js";
import htmlToDraft from 'html-to-draftjs';
import ImageUploading from "react-images-uploading";
import { ReactComponent as LogoUser } from "../images/svg/Login.svg";
import { stateToHTML } from "draft-js-export-html";
import ErrorIcon from "@mui/icons-material/Error";
import Vorschau from "./Vorschau";
import { CustomButton } from "./CustomButton";
import ReplyIcon from '@mui/icons-material/Reply';
import InfoIcon from "@mui/icons-material/Info";
import { ReactComponent as TrashIcon } from "../images/svg/mulleimer.svg";
import { CssTextField4 } from "./CssTextField4";
import validator from "validator";
import { PriorityHigh } from "@mui/icons-material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const EditAddress = React.forwardRef((props, ref) => {
    const {
        type,
        index,
        id,
        createCompanyAddress,
        updateCompanyAddress,
        deleteCompanyAddress,
        isDefault,
        setIsDefault,
        companyName,
        setCompanyName,
        companyNameError,
        setCompanyNameError,
        street,
        setStreet,
        streetError,
        setStreetError,
        nr,
        setNr,
        nrError,
        setNrError,
        zipcode,
        setZipcode,
        zipcodeError,
        setZipcodeError,
        city,
        setCity,
        cityError,
        setCityError,
        phonenumber,
        setPhonenumber,
        phonenumberError,
        setPhonenumberError,
        email,
        setEmail,
        emailError,
        setEmailError,
        website,
        setWebsite,
        websiteError,
        setWebsiteError,
        newAddress,
        setNewAddress,
        setEditAddress
    } = props;

    const validateEmail = email => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );
    };

    const validateCompanyAddress = () => {
        let error = false;
        if (!companyName || companyName == '') {
            setCompanyNameError('Firmenname darf nicht leer sein');
            error = true;
        } else {
            setCompanyNameError(false);
        }
        if (!street || street == '') {
            setStreetError('Straße darf nicht leer sein');
            error = true;
        } else {
            setStreetError(false);
        }
        if (!zipcode || zipcode == '') {
            setZipcodeError('PLZ darf nicht leer sein');
            error = true;
        } else {
            setZipcodeError(false);
        }
        if (!city || city == '') {
            setCityError('Ort darf nicht leer sein');
            error = true;
        } else {
            setCityError(false);
        }
        if ((!phonenumber || phonenumber == '') && !email) {
            setPhonenumberError('Telefon oder E-Mail darf nicht leer sein');
            error = true;
        } else {
            setPhonenumberError(false);
        }
        if (website && !validator.isURL(website)) {
            setWebsiteError('Keine gültige URL');
            error = true;
        } else {
            setWebsiteError(false);
        }


        if ((!email || email == '') && !phonenumber) {
            setEmailError('E-Mail oder Telefon darf nicht leer sein');
            error = true;
        } else {
            setEmailError(false);
        }
        if (phonenumber && !/[0-9]/.test(phonenumber)) {
            setPhonenumberError('Keine gültige Telefonnummer');
            error = true;
        }
        if (email && !validateEmail(email)) {
            setEmailError('Keine gültige E-Mail');
            error = true;
        }
        return !error;
    }


    return (
        <div ref={ref} key={id} style={{
            border: '2px solid #C7D300',
            borderRadius: 25,
            // height: 200,
            marginTop: (index === 0 || id === -1) ? 35 : 15,
            marginBottom: 15,
            // padding: '10px 15px 15px 15px',
            padding: '10px 15px 20px 15px',
            textAlign: 'left'
        }}>
            <Grid container>
                <Grid item xs={8} style={{ paddingTop: 5 }}>
                    <p style={{
                        margin: 0,
                        padding: 0,
                        textAlign: 'left',
                        fontFamily: 'VagRoundedBold',
                        fontSize: 14
                    }}
                       className={'color-blue'}>Adresse {type === 'new' ? <>anlegen</> : <>bearbeiten</>}</p>
                    <FormControl className={'testC'} style={{ color: '#C7D300' }}>
                        <RadioGroup row>
                            <FormControlLabel onClick={(e) => {
                                e.preventDefault()
                                setIsDefault(!isDefault)
                            }} control={<Radio checked={isDefault}/>}
                                              label="Als Standardadresse festlegen"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'right' }}>
                    {(id && id !== -1) && <TrashIcon
                        onClick={() => {
                            deleteCompanyAddress(id);
                        }}
                        style={{
                            height: 30,
                            width: 30,
                            marginRight: 2,
                            transition: 'all 0.2s ease-in-out',
                            textAlign: 'center',
                            fill: '#C7D300',
                            cursor: 'pointer',
                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                        }}
                    />}
                    <ClearIcon
                        className={'not-yellow'}
                        onClick={() => {
                            setIsDefault(false);
                            setCompanyName('');
                            setCompanyNameError(false);
                            setStreet('');
                            setStreetError(false);
                            setNr('');
                            setNrError(false);
                            setZipcode('');
                            setZipcodeError(false);
                            setCity('');
                            setCityError(false);
                            setPhonenumber('');
                            setPhonenumberError(false);
                            setEmail('');
                            setEmailError(false);
                            setWebsite('');
                            setWebsiteError(false);
                            if (type === 'new') {
                                setNewAddress(false);
                                window.scrollTo(0, 0);
                            } else if (type === 'edit') {
                                setEditAddress(false);
                            }
                        }}
                        style={{
                            height: 30,
                            width: 30,
                            marginRight: 2,
                            transition: 'all 0.2s ease-in-out',
                            textAlign: 'center',
                            fill: '#00374e',
                            cursor: 'pointer',
                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CssTextField4
                        label="Firmenname*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setCompanyNameError(true);
                            } else {
                                setCompanyNameError(false);
                            }
                            setCompanyName(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={companyName}
                        error={!!companyNameError}
                        helperText={companyNameError}
                    />
                </Grid>
                <Grid item xs={10}>
                    <CssTextField4
                        label="Straße*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setStreetError(true);
                            } else {
                                setStreetError(false);
                            }
                            setStreet(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={street}
                        error={!!streetError}
                        helperText={streetError}
                    />
                </Grid>
                <Grid item xs={2}>
                    <CssTextField4
                        label="Nr"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                            } else {
                                setNrError(false);
                            }
                            setNr(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={nr}
                        error={!!nrError}
                        helperText={nrError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="PLZ*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setZipcodeError(true);
                            } else {
                                setZipcodeError(false);
                            }
                            setZipcode(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={zipcode}
                        error={!!zipcodeError}
                        helperText={zipcodeError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="Ort / Sitz*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setCityError(true);
                            } else {
                                setCityError(false);
                            }
                            setCity(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={city}
                        error={!!cityError}
                        helperText={cityError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="Telefonnummer"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                            } else {
                                setPhonenumberError(false);
                            }
                            setPhonenumber(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={phonenumber}
                        error={!!phonenumberError}
                        helperText={phonenumberError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="E-Mail"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                            } else {
                                setEmailError(false);
                            }
                            setEmail(e.target.value.replace(/ /g, ''));
                        }}
                        // onKeyPress={handleKeypress}
                        value={email}
                        error={!!emailError}
                        helperText={emailError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="Website"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                            } else {
                                setWebsiteError(false);
                            }
                            setWebsite(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={website}
                        error={!!websiteError}
                        helperText={websiteError}
                    />
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right', marginTop: 7 }}>
                    <CustomButton
                        buttontype={'yellowBlue'}
                        style={{ padding: '1.7px 15px', marginRight: 10 }}
                        onClick={() => {
                            if (validateCompanyAddress()) createCompanyAddress();
                        }}
                    >Speichern</CustomButton>
                </Grid>
            </Grid>
        </div>
    );
});

const EditContact = React.forwardRef((props, ref) => {
    const {
        type,
        index,
        id,
        isDefaultContact,
        setIsDefaultContact,
        anrede,
        setAnrede,
        anredeError,
        setAnredeError,
        vorname,
        setVorname,
        vornameError,
        setVornameError,
        nachname,
        setNachname,
        nachnameError,
        setNachnameError,
        telefon,
        setTelefon,
        telefonError,
        setTelefonError,
        emailContact,
        setEmailContact,
        emailContactError,
        setEmailContactError,
        contactImage,
        setContactImage,
        contactImageId,
        setContactImageId,
        contactImageError,
        setContactImageError,
        createContact,
        updateContact,
        deleteContact,
        newContact,
        setNewContact,
        setEditContact
    } = props;

    const validateContact = () => {
        let error = false;
        if (!anrede || anrede == '') {
            setAnredeError('Anrede darf nicht leer sein');
            error = true;
        } else {
            setAnredeError(false);
        }
        if (!nachname || nachname == '') {
            setNachnameError('Nachname darf nicht leer sein');
            error = true;
        } else {
            setNachnameError(false);
        }
        // if ((!telefon || telefon == '') && !emailContact) {
        //     setTelefonError('Telefon oder E-Mail darf nicht leer sein');
        //     error = true;
        // } else {
        //     setTelefonError(false);
        // }
        // if ((!emailContact || emailContact == '') && !telefon) {
        //     setEmailContactError('E-Mail oder Telefon darf nicht leer sein');
        //     error = true;
        // } else {
        //     setEmailContactError(false);
        // }
        if (emailContact && !validateEmail(emailContact)) {
            setEmailContactError('Keine gültige E-Mail');
            error = true;
        }
        if (telefon && !/[0-9]/.test(telefon)) {
            setTelefonError('Keine gültige Telefonnummer');
            error = true;
        }
        return !error;
    }

    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        setContactImage(imageList);
    };
    const validateEmail = email => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );
    };

    return (
        <div ref={ref} key={id} style={{
            border: '2px solid #C7D300',
            borderRadius: 25,
            // height: 200,
            marginTop: (index === 0 || id === -1) ? 35 : 15,
            // padding: '10px 15px 15px 15px',
            padding: '10px 15px 20px 15px',
            textAlign: 'left'
        }}>
            <Grid container>
                <Grid item xs={8} style={{ paddingTop: 5 }}>
                    <p style={{
                        margin: 0,
                        padding: 0,
                        textAlign: 'left',
                        fontFamily: 'VagRoundedBold',
                        fontSize: 14
                    }}
                       className={'color-blue'}>Ansprechpartner {type === 'new' ? <>anlegen</> : <>bearbeiten</>}</p>
                    <FormControl className={'testC'} style={{ color: '#C7D300' }}>
                        <RadioGroup row>
                            <FormControlLabel onClick={(e) => {
                                e.preventDefault()
                                setIsDefaultContact(!isDefaultContact)
                            }} control={<Radio checked={isDefaultContact}/>}
                                              label="Als Standardansprechpartner festlegen"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'right' }}>
                    {(id && id !== -1) && <TrashIcon
                        onClick={() => {
                            deleteContact(id);
                        }}
                        style={{
                            height: 30,
                            width: 30,
                            marginRight: 2,
                            transition: 'all 0.2s ease-in-out',
                            textAlign: 'center',
                            fill: '#C7D300',
                            cursor: 'pointer',
                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                        }}
                    />}
                    <ClearIcon
                        className={'not-yellow'}
                        onClick={() => {
                            setIsDefaultContact(false);
                            setAnrede('');
                            setAnredeError(false);
                            setVorname('');
                            setVornameError(false);
                            setNachname('');
                            setNachnameError(false);
                            setTelefon('');
                            setTelefonError(false);
                            setEmailContact('');
                            setEmailContactError(false);
                            setContactImage([]);
                            setContactImageError(false);
                            if (type === 'new') {
                                setNewContact(false);
                                window.scrollTo(0, 0);
                            } else if (type === 'edit') {
                                setEditContact(false);
                            }
                        }}
                        style={{
                            height: 30,
                            width: 30,
                            marginRight: 2,
                            transition: 'all 0.2s ease-in-out',
                            textAlign: 'center',
                            fill: '#00374e',
                            cursor: 'pointer',
                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <CssTextField4
                        label="Anrede*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            // minHeight: '44.59px',
                            minHeight: 29,
                            width: 'calc(50% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setAnredeError(true);
                            } else {
                                setAnredeError(false);
                            }
                            setAnrede(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={anrede}
                        error={anredeError != false}
                        helperText={anredeError}
                    />
                    <CssTextField4
                        label="Vorname"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                            } else {
                                setVornameError(false);
                            }
                            setVorname(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={vorname}
                        error={vornameError != false}
                        helperText={vornameError}
                    />
                    <CssTextField4
                        label="Nachname*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setNachnameError(true);
                            } else {
                                setNachnameError(false);
                            }
                            setNachname(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={nachname}
                        error={nachnameError != false}
                        helperText={nachnameError}
                    />
                    <CssTextField4
                        label="Telefon"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setTelefonError(true);
                            } else {
                                setTelefonError(false);
                            }
                            setTelefon(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={telefon}
                        error={telefonError != false}
                        helperText={telefonError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <div style={{ minHeight: 111, height: 111 }}>
                        <ImageUploading
                            multiple
                            value={contactImage}
                            onChange={onChange}
                            maxNumber={1}
                            dataURLKey="data_url"
                            acceptType={["jpg"]}
                        >
                            {({
                                  imageList,
                                  onImageUpload,
                                  onImageRemoveAll,
                                  onImageUpdate,
                                  onImageRemove,
                                  isDragging,
                                  dragProps
                              }) => (
                                <div className="upload__image-wrapper" style={{
                                    height: '100%',
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    {/*<button*/}
                                    {/*    style={isDragging ? {*/}
                                    {/*        color: "red",*/}
                                    {/*        width: 80,*/}
                                    {/*        marginRight: 10,*/}
                                    {/*        padding: 0*/}
                                    {/*    } : {width: 160, marginRight: 10, padding: 0}}*/}
                                    {/*    onClick={onImageUpload}*/}
                                    {/*    {...dragProps}*/}
                                    {/*>*/}
                                    {/*    Click or Drop here*/}
                                    {/*</button>*/}
                                    {/*<button onClick={onImageRemoveAll} style={{width: 160}}>Remove image</button>*/}
                                    {(!contactImage || contactImage.length == 0) && <LogoUser
                                        style={{
                                            height: 111,
                                            width: '100%',
                                            cursor: 'pointer',
                                            fill: 'transparent'
                                        }}
                                        onClick={() => onImageUpload()}
                                    />}
                                    {(!contactImage || contactImage.length == 0) &&
                                        <p onClick={() => onImageUpload()} style={{
                                            position: 'absolute',
                                            cursor: 'pointer',
                                            color: 'white',
                                            fontSize: 10,
                                            top: '65%',
                                        }}>Bild hochladen</p>}
                                    {imageList.map((image, index) => (
                                        <div key={index} className="image-item" style={{
                                            height: '100%',
                                            position: 'relative',
                                            textAlign: 'center'
                                        }}>

                                            <img
                                                src={(image && image.data_url) ? image.data_url : ENV.REACT_APP_API_URL + '/file/' + image + '/unlogged/content'}
                                                style={{
                                                    maxHeight: 200,
                                                    maxWidth: '100%',
                                                    width: 'auto',
                                                    cursor: 'pointer'
                                                }} onClick={() => onImageUpdate(0)} alt="" width="100"/>
                                            {/*<div className="image-item__btn-wrapper">*/}
                                            {/*    <button style={{marginLeft: 0, width: 160, marginBottom: 10}}*/}
                                            {/*            onClick={() => onImageUpdate(index)}>Update*/}
                                            {/*    </button>*/}
                                            {/*    <button style={{marginLeft: 0, width: 160, marginBottom: 0}}*/}
                                            {/*            onClick={() => onImageRemove(index)}>Remove*/}
                                            {/*    </button>*/}
                                            {/*</div>*/}

                                        </div>
                                    ))}
                                    {(contactImage && contactImage.length > 0) && <>
                                        <div style={{
                                            position: 'absolute',
                                            bottom: '-5%',
                                        }}>
                                            <span onClick={() => onImageUpdate(0)} style={{
                                                color: 'white',
                                                cursor: 'pointer',
                                                background: '#00374e',
                                                padding: '2px 5px',
                                                borderRadius: 4,
                                                fontSize: 10,
                                            }}>Bild ändern
                                            </span>
                                            <span onClick={() => {
                                                onImageRemove(0)
                                                setContactImage([])
                                                setContactImageId(null)
                                            }} style={{
                                                color: 'red',
                                                cursor: 'pointer',
                                                background: '#00374e',
                                                padding: '2px 5px',
                                                borderRadius: 4,
                                                fontSize: 10,
                                            }}>X</span>
                                        </div>

                                    </>}


                                </div>
                            )}
                        </ImageUploading>
                    </div>
                    <CssTextField4
                        label="E-Mail"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setEmailContactError(true);
                            } else {
                                setEmailContactError(false);
                            }
                            setEmailContact(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={emailContact}
                        error={emailContactError != false}
                        helperText={emailContactError}
                    />
                </Grid>


                <Grid item xs={12} style={{ textAlign: 'right', marginTop: 7 }}>
                    <CustomButton
                        buttontype={'yellowBlue'}
                        style={{ padding: '1.7px 15px', marginRight: 10 }}
                        onClick={() => {
                            if (validateContact()) createContact();
                        }}
                    >Speichern</CustomButton>
                </Grid>
            </Grid>
        </div>
    );
});

const useStyles = makeStyles({
    select: {
        "& ul.MuiList-padding": {
            padding: 0
        },
        "& li": {
            color: '#00374e',
        }
    },
});

export default function HorizontalLinearStepper(props) {

    const { selected, type, id } = props;
    const history = useHistory();
    const api = useFetch();
    const steps = ['Jobansicht ausfüllen', 'swiitcher Fragen', 'Stellenanzeige Inhalt'];

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }}/>
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            border: '1px solid #dadde9',
        },
    }));

    const vorschauStyle = {
        position: 'absolute',
        top: '55%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxHeight: '70%',
        overflow: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        zIndex: 4
    };

    const deleteStyle = {
        position: 'absolute',
        top: '55%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '20%',
        minWidth: 200,
        maxHeight: '70%',
        overflow: 'auto',
        backgroundColor: 'rgba(0, 55, 78, 0.95)',
        border: '2px solid #C7D300',
        borderRadius: 5,
        padding: '5px 32px 20px',
        boxShadow: 24,
        zIndex: 4,
        lineHeight: '13px'
    };

    const refStep1 = useRef(null);
    const refStep2 = useRef(null);
    const refStep3 = useRef(null);
    const refStep4 = useRef(null);

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());


    const [loaded, setLoaded] = useState(type !== 'update');
    const [question, setQuestion] = useState([]);
    const [buttonHover, setButtonHover] = useState('');
    const [stellenanzeige, setStellenanzeige] = useState('');
    const [saveAsDraft, setSaveAsDraft] = useState(false);


    let temp = [];
    if (ReactSession.get('file_id')) temp = [{ id: ReactSession.get('file_id') }];
    const [userImage, setUserImage] = useState(temp);
    const [fileId, setFileId] = useState(false);
    const [teaserImage, setTeaserImage] = useState([]);
    const [title, setTitle] = useState({
        input: '',
        title: ''
    });
    const [titleError, setTitleError] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [subTitleError, setSubTitleError] = useState('');
    const [slogan, setSlogan] = useState('');
    const [sloganError, setSloganError] = useState('');
    const [badgesError, setBadgesError] = useState([]);
    const [description, setDescription] = useState('');
    const [imageDescription, setImageDescription] = useState('');
    const [imageDescriptionError, setImageDescriptionError] = useState('');
    const [editorText, setEditorText] = useState('');
    const [editorTextFromHtml, setEditorTextFromHtml] = useState('');
    const [jobTypes, setJobTypes] = useState(null);
    const [selectedJobTypes, setSelectedJobTypes] = useState([]);
    const [multiSelectOpen, setMultiSelectOpen] = useState(false);
    const [gendering, setGendering] = useState(true);

    const [avatarComp, setAvatarComp] = useState(false);
    const [companyAddresses, setCompanyAddresses] = React.useState([]);
    const [companyAddressInput, setCompanyAddressInput] = React.useState('');
    const [selectedCompanyAddresses, setSelectedCompanyAddresses] = React.useState([]);
    const [selectedCompanyAddressesError, setSelectedCompanyAddressesError] = React.useState(false);

    const [contacts, setContacts] = React.useState([]);
    const [contactInput, setContactInput] = React.useState('');
    const [selectedContacts, setSelectedContacts] = React.useState([]);
    const [selectedContactsError, setSelectedContactsError] = React.useState(false);


    const [answers, setAnswers] = React.useState([]);
    const [autocomplete, setAutocomplete] = React.useState([]);
    const [autocompleteInput, setAutocompleteInput] = React.useState('');
    const [isFocused, setIsFocused] = React.useState(null);
    const [answeredQuestions, setAnsweredQuestions] = React.useState([]);
    const [errors, setErrors] = React.useState([]);
    const [vorschau, setVorschau] = React.useState(false);
    const [onDelete, setOnDelete] = React.useState(false);
    const [selectedReason, setSelectedReason] = React.useState(0);
    const [visitedEditor, setVisitedEditor] = React.useState(false);
    const fragenRef = useRef(null);

    const [isDefault, setIsDefault] = useState(false);
    const [companyName, setCompanyName] = React.useState('');
    const [companyNameError, setCompanyNameError] = React.useState(false);
    const [street, setStreet] = React.useState('');
    const [streetError, setStreetError] = React.useState(false);
    const [nr, setNr] = React.useState('');
    const [nrError, setNrError] = React.useState(false);
    const [zipcode, setZipcode] = React.useState('');
    const [zipcodeError, setZipcodeError] = React.useState(false);
    const [city, setCity] = React.useState('');
    const [cityError, setCityError] = React.useState(false);
    const [phonenumber, setPhonenumber] = React.useState('');
    const [phonenumberError, setPhonenumberError] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);
    const [website, setWebsite] = React.useState('');
    const [websiteError, setWebsiteError] = React.useState(false);


    const [editAddress, setEditAddress] = React.useState(false);
    const [newAddress, setNewAddress] = React.useState(false);
    const [getRef, setGetRef] = React.useState(false);
    const newAddressRef = React.createRef();


    const [isDefaultContact, setIsDefaultContact] = useState(false);
    const [jobId, setJobId] = useState(false);
    const [anrede, setAnrede] = useState('');
    const [anredeError, setAnredeError] = useState(false);
    const [vorname, setVorname] = useState('');
    const [vornameError, setVornameError] = useState(false);
    const [nachname, setNachname] = useState('');
    const [nachnameError, setNachnameError] = useState(false);
    const [telefon, setTelefon] = useState('');
    const [telefonError, setTelefonError] = useState(false);
    const [emailContact, setEmailContact] = useState('');
    const [emailContactError, setEmailContactError] = useState(false);
    const [contactImage, setContactImage] = useState([]);
    const [contactImageUpdate, setContactImageUpdate] = useState([]);
    const [contactImageError, setContactImageError] = useState(false);

    const [editContact, setEditContact] = React.useState(false);
    const [newContact, setNewContact] = React.useState(false);
    const [getContactRef, setGetContactRef] = React.useState(false);
    const newContactRef = React.createRef();
    const [autocompleteLoading, setAutocompleteLoading] = useState(false);
    const [isDraft, setIsDraft] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [draftButtonDone, setDraftButtonDone] = useState(false);
    const [onContingentEmpty, setOnContingentEmpty] = useState(false);
    const [onMustHaveModal, setOnMustHaveModal] = useState(false);
    const [checked, setChecked] = useState(false);

    const selectItems = [
        'Stelle über swiitcher besetzt', 'Stelle anderweitig besetzt', 'Ohne Grund löschen'
    ];

    const getAnswers = (answers) => {
        setAnswers(answers);
    }

    const getQuestions = (questions) => {

        setQuestions(questions);
    }

    const getUser = async () => {
        let url = ENV.REACT_APP_API_URL + '/user/' + ReactSession.get('userId');

        let { response, data } = await api(url)


        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/login');
            return;
        }
        if (data && data.data) {

            setGendering(data.data.attributes.m_w_d == 1);
            ReactSession.set('file_id', data.data.attributes.file_id);
            if(data.data.attributes.file_id) {
                setUserImage([{ id: data.data.attributes.file_id }])
            } else {
                setUserImage([])
            }

        } else if (response.error) {
            //notify('API Fehler: ' + response.status, true);
        }
    }

    const updateUser = async () => {
        let updatedAttributes = {
            data: {
                attributes: {
                    m_w_d: !gendering,
                }
            }
        }

        let url = ENV.REACT_APP_API_URL + "/user/" + ReactSession.get('userId');
        const { response, data } = await api(url, 'PATCH', JSON.stringify(updatedAttributes));

        if (response && response.status != '404' && data && response.status != '422') {
            getUser();
        } else if (response.status == '404') {

        }
    }

    const getContingent = async () => {
        let url = ENV.REACT_APP_API_URL + '/user/' + ReactSession.get('userId') + '?include=jobAdvertisementContingent';

        let { response, data } = await api(url)
        return data.included[0].attributes
    }

    const getAdverts = async () => {
        let url = ENV.REACT_APP_API_URL + '/arbeitgeber/1/job-advertisements?'
        let { response, data } = await api(url)
        return data.data.filter((advert) => advert.attributes.active !== null)
    }

    function findAdvertById(adverts, advertId) {
        for (const advert of adverts) {
            if (advert.id === advertId) {
                return true;
            }
        }
        return false;
    }

    const validateContingent = async (advertId) => {
        const userData = await getContingent()
        const adverts = await getAdverts()
        // if (userData.availableSingleJobAdvertisements !== 0 || (userData.flatActive !== 0 && userData.isFlatExpired !== 1) || findAdvertById(adverts, advertId)) {
        if (userData.availableSingleJobAdvertisements !== 0 || (!!userData.flatDuration) || findAdvertById(adverts, advertId)) {

            setOnContingentEmpty(false)
            return true
        } else {
            setOnContingentEmpty(true)
            return false
        }

    }

    const validate = async (advertId) => {
        if (await validateContingent(advertId)) {
            let errors = false;
            if (fragenRef) {
                let temp = fragenRef.validateQuestions();
                setErrors(temp);
                if (temp.length > 0) errors = true;
            }

            if (!selectedCompanyAddresses || selectedCompanyAddresses.length === 0) {
                errors = true;
                setSelectedCompanyAddressesError('Wähle mindestens einen Arbeitsort')
            } else {
                setSelectedCompanyAddressesError(false)
            }
            if (badgesError.filter(i => i == true).length > 0) {
                errors = true;
            }
            if ((!title || !title.title || title.title == '')) {
                setTitleError('Titel darf nicht leer sein')
                errors = true;
            } else {
                let t = [...answers];
                if (!answers.includes({ id: '52', answer: title?.title })) {
                    answers.push({ id: '52', answer: title?.title })
                }
                if (!answers.includes({ id: '60', answer: title?.title })) {
                    answers.push({ id: '60', answer: title?.title })
                }
            }
            if (!errors) {
                //history.push('/neue-stellenanzeige');

                // window.scrollTo(0, 0);
                checkMustHave()
                // saveChanges()
            }
        }
    }

    const [badges, setBadges] = useState([
        { text: 'Benefit 1 (optional)', value: '' },
        { text: 'Benefit 2 (optional)', value: '' },
        { text: 'Benefit 3 (optional)', value: '' },
        { text: 'Benefit 4 (optional)', value: '' },
    ]);

    useEffect(() => {
        setAvatarComp(<Avatar
            style={{
                margin: 10,
                opacity: 0.5,
                borderRadius: 70
            }}
            gender="female"
            className="avatar"
            width="110"
            height="110"
            alt="Avatar"
        />);


        getUser();
        getCompanyImage();
        getCompanyAddresses();
        getContacts();


        if (type === 'update' && id) {
            getStellenanzeige();
        }
    }, [])

    const getCompanyAddresses = async () => {

        let rows = [];
        let moreData = true;
        let pageNumber = 1;

        for (let i = 1; i < pageNumber + 1; i++) {
            let url = ENV.REACT_APP_API_URL + '/company-addresses?page%5Bsize%5D=20&page%5Bnumber%5D=' + i;

            //if (this.state.sort) url += '&sort=' + this.state.sort;

            let { response, data } = await api(url)


            if (!response || response.status == 401) {
                ReactSession.set('token', undefined);
                ReactSession.set('refreshToken', undefined);
                history.push('/login');
                return;
            }

            if (data && data.data) {
                data.data.map((item, index) => {
                    item.attributes.isDefault = (item.attributes.isDefault == 1) ? true : false;
                    if (rows.filter(i => i.id === item.id).length === 0) {
                        rows.push(item);
                    } else {
                        rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])] = item;
                    }
                })

                if (data.meta.pagination.total === rows.length) {
                    moreData = false;
                }

            } else if (response.error) {
                notify('API Fehler: ' + response.status, true);
            }
        }
        rows.sort((a, b) => (a.attributes.isDefault == 1 ? -1 : 1));
        setCompanyAddresses(rows);
    }

    useEffect(() => {

    }, [editorText])

    const getContacts = async () => {

        let rows = [];
        let moreData = true;
        let pageNumber = 1;

        for (let i = 1; i < pageNumber + 1; i++) {
            let url = ENV.REACT_APP_API_URL + '/contacts?page%5Bsize%5D=20&page%5Bnumber%5D=' + i;

            //if (this.state.sort) url += '&sort=' + this.state.sort;

            let { response, data } = await api(url)


            if (!response || response.status == 401) {
                ReactSession.set('token', undefined);
                ReactSession.set('refreshToken', undefined);
                history.push('/login');
                return;
            }

            if (data && data.data) {
                data.data.map((item, index) => {

                    if (selectedContacts && selectedContacts.length > 0 && item.id == selectedContacts[0].id) {
                        setContactImageUpdate([{ file_id: item.attributes.file_id }])
                        setSelectedContacts([item])
                    }
                    if (rows.filter(i => i.id === item.id).length === 0) {
                        // rows.push(item);
                        rows.push(item);
                    } else {
                        rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])] = item;
                    }
                })

                if (data.meta.pagination.total === rows.length) {
                    moreData = false;
                }

            } else if (response.error) {
                notify('API Fehler: ' + response.status, true);
            }
        }
        setContacts(rows);
    }

    const getStellenanzeige = async () => {

        let url = ENV.REACT_APP_API_URL + '/job-advertisement/' + id + '?include=contacts,companyAddresses,answeredQuestions';

        //if (this.state.sort) url += '&sort=' + this.state.sort;

        let { response, data } = await api(url)


        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/login');
            return;
        }

        if (data && data.data) {

            setTitle({ title: data.data.attributes.title, input: data.data.attributes.title })
            setSubTitle(data.data.attributes.subTitle)
            setSlogan(data.data.attributes.slogan)
            setDescription(data.data.attributes.description)
            setImageDescription(data.data.attributes.imageDescription)
            setIsDraft(data.data.attributes.draft)
            if (data.data.attributes.file_id) {
                setFileId(data.data.attributes.file_id);
                setTeaserImage([{ id: data.data.attributes.file_id }]);
            }

            if (data.data.attributes.text) {
                const contentBlock = htmlToDraft(data.data.attributes.text);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    setEditorTextFromHtml(editorState);

                    let options = {
                        blockStyleFn: block => {
                            let data = block.getData();
                            if (data.size == 0) return;

                            let style = {};
                            if (data.get('text-align')) style = { ...style, textAlign: data.get('text-align') }
                            return { style };
                        }
                    };

                    setEditorText(stateToHTML(editorState.getCurrentContent(), options));

                }
            }


            if (data.data.attributes.badges) {
                let badge = [];
                let temp = JSON.parse(data.data.attributes.badges);
                temp.map((item, index) => {
                    badge.push(item);
                })
                setBadges(badge);
            }

            if (data.included) {

                setSelectedContacts(data.included.filter(i => i.type == 'contacts'));
                if (data.included.filter(i => i.type == 'contacts').length > 0) {
                    setContactImageUpdate([{ file_id: data.included.filter(i => i.type == 'contacts')[0].attributes.file_id }])
                }
                setSelectedCompanyAddresses(data.included.filter(i => i.type == 'company-addresses'));

                setAnsweredQuestions(data.included.filter(i => i.type == 'answered-questions'));
            }
            setLoaded(true);

        } else if (response.error) {
            notify('API Fehler: ' + response.status, true);
        }
    }

    const getCompanyImage = async () => {
    }

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    useEffect(() => {

    }, [question])


    useEffect(() => {
        let yOffset = 0;
        let element = null;
        let y = null;
        switch (activeStep) {
            case 0:
                yOffset = -100;
                element = refStep1.current;
                if (element) y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                // element.scrollIntoView({behavior: 'smooth'})
                break;
            case 1:
                yOffset = -100;
                element = refStep2.current;
                if (element) y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                break;
            case 2:
                yOffset = -100;
                element = refStep3.current;
                if (element) y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                setVisitedEditor(true);
                if (fragenRef) {
                    let temp = fragenRef.validateQuestions();
                    setErrors(temp);
                }
                break;
            case 3:
                // yOffset = -100;
                // element = refStep4.current;
                // y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                break;
            case 4:
                // yOffset = -100;
                // element = refStep5.current;
                // y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                break;

        }
        setTimeout(() => {
            //window.scrollTo({top: y, behavior: 'smooth'});

        }, 50)
    }, [activeStep])

    useEffect(() => {
        // console.log(title);
        // if (title) setAutocompleteInput('');
    }, [title])

    const createStellenanzeige = async (isDraft = false) => {
        let con = [];
        let com = [];


        selectedContacts.map((item, index) => {
            con.push({
                type: 'Contact',
                id: item.id
            })
        })

        selectedCompanyAddresses.map((item, index) => {
            com.push({
                type: 'Company-Address',
                id: item.id
            })
        })

        const formData = new FormData();
        formData.append(
            'data',
            JSON.stringify({
                data: {
                    attributes: {
                        title: title.title,
                        subTitle,
                        slogan,
                        description,
                        imageDescription,
                        employmentTypes: jobTypes.selected.answer.join(' • '),
                        text: editorText,
                        badges: JSON.stringify(badges),
                        logo_id: ReactSession.get('file_id') ?? null,
                        draft: isDraft
                    },
                    relationships: {
                        contacts: {
                            data: con
                        },
                        companyAddresses: {
                            data: com
                        }
                    }
                },
                meta: {
                    scenario: 'default'
                },
                answers
            }),
        );

        if (teaserImage && teaserImage[0] && !teaserImage[0].id) formData.append('file', teaserImage[0].file);

        const {
            response,
            data
        } = await api(ENV.REACT_APP_API_URL + "/job-advertisements", 'POST', formData, true);

        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/login');
            return;
        }


        if (response && response.status != '404' && data && data.data) {
            setJobId(data.data.id)

            if (isDraft) {
                setDraftButtonDone(true)
                setTimeout(() => {
                    setDraftButtonDone(false)
                }, 5000)
            } else {
                setActiveStep(3);
            }
            // notify('Deine Stellenanzeige wurde erfolgreich angelegt');
            // history.push('/stellenanzeigen');
        } else if (response.status == '404') {
            notify('Stellenanzeige existiert bereits', true);
        } else {
            notify('API Fehler: ' + response.status, true);
        }
    }

    const updateStellenanzeige = async (isDraft = false) => {

        let con = [];
        let com = [];


        selectedContacts.map((item, index) => {
            con.push({
                type: 'Contact',
                id: item.id
            })
        })

        selectedCompanyAddresses.map((item, index) => {
            com.push({
                type: 'Company-Address',
                id: item.id
            })
        })


        const formData = new FormData();
        formData.append(
            'data',
            JSON.stringify({
                data: {
                    attributes: {
                        title: title.title,
                        subTitle,
                        slogan,
                        description,
                        imageDescription,
                        employmentTypes: jobTypes.selected.answer.join(' • '),
                        text: editorText,
                        badges: JSON.stringify(badges),
                        logo_id: ReactSession.get('file_id') ?? null,
                        draft: isDraft
                    },
                    relationships: {
                        contacts: {
                            data: con
                        },
                        companyAddresses: {
                            data: com
                        }
                    }
                },
                answers
            }),
        );


        if (teaserImage && teaserImage[0] && !teaserImage[0].id) formData.append('file', teaserImage[0].file);

        let {
            response,
            data
        } = await api(ENV.REACT_APP_API_URL + "/job-advertisement/" + id + '/upload/file', 'POST', formData, true)

        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/login');
            return;
        }


        if (response && response.status != '404') {
            if (data.data.draft === 1) {
                setIsDraft(true)
                setDraftButtonDone(true)
                setTimeout(() => {
                    setDraftButtonDone(false)
                }, 5000)
            } else {
                setIsDraft(false)
                setActiveStep(3);
            }

            // notify('Deine Stellenanzeige wurde erfolgreich angelegt');
            // window.scrollToTop();
            //history.push('/stellenanzeigen');
        } else if (response.status == '404') {
            notify('Stellenanzeige existiert bereits', true);
        } else {
            notify('API Fehler: ' + response.status, true);
        }
    }

    const deleteStellenanzeige = async () => {

        const formData = new FormData();
        formData.append(
            'data',
            JSON.stringify({
                data: {
                    attributes: {
                        deleted: true,
                        reason: selectItems[selectedReason]
                    }
                }
            }),
        );

        let {
            response,
            data
        } = await api(ENV.REACT_APP_API_URL + "/job-advertisement/" + id + '/upload/file', 'POST', formData, true)

        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/login');
            return;
        }


        if (response && response.status != '404' && response.status != '403') {
            setSelectedReason(0);
            setOnDelete(false);
            history.push('/stellenanzeigen')
            notify('Deine Stellenanzeige wurde erfolgreich gelöscht');
        } else if (response.status == '404') {
            notify('Stellenanzeige existiert bereits', true);
        } else {
            notify('API Fehler: ' + response.status, true);
        }
    }

    const deleteTeaserImage = async () => {
        const formData = new FormData();

        let {
            response,
            data
        } = await api(ENV.REACT_APP_API_URL + "/job-advertisement/" + id + '/upload/file', 'POST', formData, true)

        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/login');
            return;
        }


        if (data && data.data) {
            setTeaserImage([]);
            setImageDescription('');
            setImageDescriptionError('');
        }
    }

    const onChangeTeaserImage = async (imageList, addUpdateIndex) => {
        // data for submit
        setTeaserImage(imageList);
    }


    const onChange = async (imageList, addUpdateIndex) => {
        // data for submit
        setUserImage(imageList);


        const formData = new FormData();

        formData.append('file', imageList[0].file);

        let url = ENV.REACT_APP_API_URL + '/user/upload/photo';
        const { response, data } = await api(url, 'POST', formData, true);

        if (data && data.data) {
            ReactSession.set('file_id', data.data.attributes.file_id)
        }
    };

    const onContactImageChange = async (imageList, addUpdateIndex) => {
        // data for submit
        setContactImageUpdate(imageList);

        if (contactImageUpdate.length == 1 && imageList.length == 1) {
            await updateContact(imageList, selectedContacts[0].id, true, false);
        }

        if (imageList && imageList.length > 0) {
            updateContact(imageList, selectedContacts[0].id);
        }
    };

    const deleteImage = async () => {
        // userImage
        const formData = new FormData();

        let url = ENV.REACT_APP_API_URL + '/user/upload/photo';
        const { response, data } = await api(url, 'POST', formData, true);

        if (data && data.data) {
            ReactSession.set('file_id', null);
            setUserImage([]);
        }
    }

    const notify = (message, error) => {
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: { marginTop: 30 }
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: { marginTop: 30 }
            });
        }
    }

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.charCode === 13) {
            // login();
        }
    };
    const classes = useStyles();

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        disableScrollLock: true,
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                minHeight: 'unset',
            },
        },
        classes: { paper: classes.select }
    };


    useEffect(() => {

    }, [editorText])

    const createCompanyAddress = async () => {
        let updatedAttributes = {
            data: {
                attributes: {
                    isDefault: isDefault === true ? 1 : 0,
                    companyName: companyName,
                    street: street,
                    houseNumber: nr,
                    zipcode: zipcode,
                    city: city,
                    telephonenumber: phonenumber,
                    email,
                    website: website ? 'https://' + website : null
                },
                relationships: {}
            }
        }

        let url = ENV.REACT_APP_API_URL + "/company-addresses";
        const { response, data } = await api(url, 'POST', JSON.stringify(updatedAttributes));

        if (response && response.status != '404' && data) {
            let t = [...selectedCompanyAddresses];
            t.push(data.data);
            setSelectedCompanyAddresses(t);
            setIsDefault(false);
            setCompanyName('');
            setCompanyNameError(false);
            setStreet('');
            setStreetError(false);
            setNr('');
            setNrError(false);
            setZipcode('');
            setZipcodeError(false);
            setCity('');
            setCityError(false);
            setPhonenumber('');
            setPhonenumberError(false);
            setEmail('');
            setEmailError(false);
            setWebsite('');
            setWebsiteError(false);
            setNewAddress(false);
            setEditAddress(false);
            getCompanyAddresses();
            notify('Deine Adresse wurde erfolgreich angelegt');
        } else if (response.status == '404') {

        }
    }

    const createContact = async () => {
        const formData = new FormData();
        formData.append(
            'data',
            JSON.stringify({
                data: {
                    attributes: {
                        anrede: anrede,
                        vorname: vorname,
                        nachname: nachname,
                        telefon: telefon,
                        email: emailContact,
                    },
                },
            }),
        );

        if (contactImage[0]) formData.append('file', contactImage[0].file);

        let url = ENV.REACT_APP_API_URL + "/contacts";
        const { response, data } = await api(url, 'POST', formData, true);

        if (response && response.status != '404' && data) {
            let t = [...selectedContacts];
            t.push(data.data);
            setContactImageUpdate([{ file_id: t[0].attributes.file_id }])
            setSelectedContacts(t);
            setIsDefaultContact(false);
            setAnrede('');
            setAnredeError(false);
            setVorname('');
            setVornameError(false);
            setNachname('');
            setNachnameError(false);
            setTelefon('');
            setTelefonError(false);
            setEmailContact('');
            setEmailContactError(false);
            setContactImage([]);
            setContactImageError(false);
            setNewContact(false);
            setEditContact(false);
            setContactImage(false);
            getContacts();
            notify('Dein Ansprechpartner wurde erfolgreich angelegt');
        } else if (response.status == '404') {

        }
    }

    const updateContact = async (files, id, fileDelete = false, getNewData = true) => {
        const formData = new FormData();
        formData.append(
            'data',
            JSON.stringify({
                data: {
                    file: fileDelete ? null : files[0].file_id
                },
            }),
        );
        if (files && files[0] && !files[0].file_id && fileDelete == false) {

            formData.append('file', files[0].file);
        }
        let url = ENV.REACT_APP_API_URL + "/contacts/" + id + "/upload/file";
        const { response, data } = await api(url, 'POST', formData, true);


        if (response && response.status != '404' && data && getNewData) {
            getContacts();
        } else if (response.status == '404') {

        }
    }

    const checkMustHave = async () => {
        setOnMustHaveModal(true)
    }
    const saveChanges = async () => {
        setOnMustHaveModal(false)

        if (type === 'update' || id) {
            await updateStellenanzeige(saveAsDraft);
        } else if (!id) {
            await createStellenanzeige(saveAsDraft);
        }
        window.scrollTo(0, 0)
    }
    // const saveDraft = async () => {
    //
    //     if (type === 'update' || id) {
    //         updateStellenanzeige(true);
    //     } else if (!id) {
    //         createStellenanzeige(true);
    //     }
    // }

    return (
        <Box sx={{ width: '100%' }}>
            <Modal
                style={{
                    width: '500px',
                    height: '500px',
                    position: 'relative',
                    left: 0,
                    right: 0,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    zIndex: 100000000// Add the zIndex property
                }}
                open={onContingentEmpty}
                onClose={() => {
                    setOnContingentEmpty(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box style={{ backgroundColor: 'white', padding: '20px' }}>
                    <h3 style={{ marginTop: 0, marginBottom: 30, fontFamily: 'VagRoundedBold', }}>Fehlendes
                        Kontingent<PriorityHigh
                            style={{
                                color: '#92D050',
                                marginBottom: -5,
                                width: 40,
                                height: 40,
                            }}
                        /></h3>

                    <p style={{ fontSize: 12 }}>Damit du eine Stellenanzeige erstellen kannst, buche eine Einzelanzeige
                        oder Flat.</p>
                    <CustomButton
                        buttontype={'yellowBlue'}
                        onClick={() => {
                            // login();
                            history.push('/arbeitgeber#produkte-und-preise');
                        }}
                        style={{
                            marginTop: 20,
                            width: '100%'
                        }}
                    >PRODUKTE & PREISE</CustomButton>
                    <span style={{ position: "absolute", right: 5, top: 5 }}>
                        <ClearIcon
                            className={'not-yellow-white'}
                            onClick={() => {
                                setOnContingentEmpty(false)
                            }}
                            style={{
                                height: 26,
                                width: 26,
                                marginRight: 2,
                                transition: 'all 0.2s ease-in-out',
                                textAlign: 'center',
                                cursor: 'pointer',
                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                            }}
                        />
                    </span>
                </Box>
            </Modal>

            <Modal
                style={{
                    width: '50vw',
                    minWidth: '500px',
                    height: '98vh',
                    minHeight: '500px',
                    margin: 'auto',
                    position: 'fixed',
                    overflowY: 'auto',
                    fontFamily: '"Sofia Sans", Inter, system-ui, Avenir, Helvetica, Arial, sans-serif',
                    zIndex: 100000000// Add the zIndex property
                }}
                open={onMustHaveModal}
                onClose={() => {
                    setOnMustHaveModal(false);
                    setSaveAsDraft(false)
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box style={{ backgroundColor: 'white', padding: '20px', overflow: 'auto' }}>
                    <h2 style={{
                        marginTop: 0,
                        marginBottom: 30,
                        fontFamily: '"Sofia Sans", Inter, system-ui, Avenir, Helvetica, Arial, sans-serif',
                    }}>Must Have
                        Kriterien</h2>

                    {/*<p style={{*/}
                    {/*    fontSize: 14,*/}
                    {/*    fontFamily: '"Sofia Sans", Inter, system-ui, Avenir, Helvetica, Arial, sans-serif'*/}
                    {/*}}>Hilf uns die Qualität deiner Matches zu verbessern.</p>*/}
                    <p style={{
                        fontSize: 14,
                        fontFamily: '"Sofia Sans", Inter, system-ui, Avenir, Helvetica, Arial, sans-serif'
                    }}>Welche Kriterien sind dir für diesen Job <b>besonders
                        wichtig</b>?</p>
                    <p style={{
                        fontSize: 14,
                        fontFamily: '"Sofia Sans", Inter, system-ui, Avenir, Helvetica, Arial, sans-serif'
                    }}>Wir zeigen dir nur Matches, die <b>mindestens</b> deinen <b>ausgewählten
                        Kriterien</b> entsprechen.</p>
                    <p style={{
                        fontSize: 14,
                        fontFamily: '"Sofia Sans", Inter, system-ui, Avenir, Helvetica, Arial, sans-serif'
                    }}>Die restlichen Kriterien wirken sich weiterhin auf die prozentuale Bewertung deiner Matches
                        aus.</p>


                    {questions.map((question) => {
                        if (!question.canBeStrictFilter || question.id == '52' || question.id == '59' || question.id == '60' || (question.selected.answer?.length == 0 && question.selected.autocomplete?.length == 0) || question.selected.answer[0] == 'Weniger als 1 Jahr' || question.selected.answer[0] == 'Kein Abschluss' || question.selected.answer[0] == 'Nein' || question.selected.answer[0] == 'Kein Abschluss' || question.selected.answer[0] == 'Eine gültige Fahrerlaubnis ist nicht erforderlich' || question.selected.answer[0] == 'Egal') return
                        if (question.id == 53) {
                            return (
                                <Accordion defaultExpanded>
                                    <AccordionSummary id="panel-header"
                                                      aria-controls="panel-content">
                                        <div style={{
                                            padding: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <Checkbox onClick={event => event.stopPropagation()}
                                                      checked={!answers.find((ans) => !!(typeof ans.answer != 'string' && ans.answer?.find((answer) => answer.id == question.id && !answer.isStrictFilter) || ans.autocomplete?.find((answer) => answer.id == question.id && !answer.isStrictFilter))) ?? false}
                                                      onChange={() => {

                                                          const tempAnswers = [...answers]
                                                          tempAnswers.forEach((answer) => {
                                                              if (answer.id == question.id) {
                                                                  if (answer.answer?.find((ans) => !ans.isStrictFilter) || answer.autocomplete?.find((ans) => !ans.isStrictFilter)) {
                                                                      answer.answer?.forEach((ans) => ans.isStrictFilter = true)
                                                                      answer.autocomplete?.forEach((ans) => ans.isStrictFilter = true)
                                                                  } else {
                                                                      answer.answer?.forEach((ans) => ans.isStrictFilter = false)
                                                                      answer.autocomplete?.forEach((ans) => ans.isStrictFilter = false)
                                                                  }
                                                              }
                                                          })

                                                          setAnswers(tempAnswers)

                                                      }
                                                      }/>
                                            <p>Der/Die
                                                Bewerbende <b>muss</b> mindestens <b>{question.selected.answer[0]}</b> Berufserfahrung
                                                haben</p>

                                        </div>

                                    </AccordionSummary>
                                </Accordion>
                            )
                        } else if (question.id == 88) {
                            return (
                                <Accordion defaultExpanded>
                                    <AccordionSummary id="panel-header"
                                                      aria-controls="panel-content">
                                        <div style={{
                                            padding: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <Checkbox onClick={event => event.stopPropagation()}
                                                      checked={!answers.find((ans) => !!(typeof ans.answer != 'string' && ans.answer?.find((answer) => answer.id == question.id && !answer.isStrictFilter) || ans.autocomplete?.find((answer) => answer.id == question.id && !answer.isStrictFilter))) ?? false}
                                                      onChange={() => {

                                                          const tempAnswers = [...answers]
                                                          tempAnswers.forEach((answer) => {
                                                              if (answer.id == question.id) {
                                                                  if (answer.answer?.find((ans) => !ans.isStrictFilter) || answer.autocomplete?.find((ans) => !ans.isStrictFilter)) {
                                                                      answer.answer?.forEach((ans) => ans.isStrictFilter = true)
                                                                      answer.autocomplete?.forEach((ans) => ans.isStrictFilter = true)
                                                                  } else {
                                                                      answer.answer?.forEach((ans) => ans.isStrictFilter = false)
                                                                      answer.autocomplete?.forEach((ans) => ans.isStrictFilter = false)
                                                                  }
                                                              }
                                                          })

                                                          setAnswers(tempAnswers)

                                                      }
                                                      }/>
                                            <p>Der/Die
                                                Bewerbende <b>muss</b> {question.selected.answer.map((selected, index) => {
                                                    // const tempAns = question.answers.find((ans) => ans.answer == selected.title || ans.label == selected.title)
                                                    // if (!tempAns?.id) return
                                                    if (index < question.selected.answer.length - 1) return `${selected} oder `
                                                    return `${selected}`
                                                })} sein</p>

                                        </div>

                                    </AccordionSummary>
                                </Accordion>
                            )
                        } else if (question.id == 54 && question.selected.answer[0] != 'Studium') {
                            return (
                                <Accordion defaultExpanded>
                                    <AccordionSummary id="panel-header"
                                                      aria-controls="panel-content">
                                        <div style={{
                                            padding: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <Checkbox onClick={event => event.stopPropagation()}
                                                      checked={!answers.find((ans) => !!(typeof ans.answer != 'string' && ans.answer?.find((answer) => answer.id == question.id && !answer.isStrictFilter) || ans.autocomplete?.find((answer) => answer.id == question.id && !answer.isStrictFilter))) ?? false}
                                                      onChange={() => {

                                                          const tempAnswers = [...answers]
                                                          tempAnswers.forEach((answer) => {
                                                              if (answer.id == question.id) {
                                                                  if (answer.answer?.find((ans) => !ans.isStrictFilter) || answer.autocomplete?.find((ans) => !ans.isStrictFilter)) {
                                                                      answer.answer?.forEach((ans) => ans.isStrictFilter = true)
                                                                      answer.autocomplete?.forEach((ans) => ans.isStrictFilter = true)
                                                                  } else {
                                                                      answer.answer?.forEach((ans) => ans.isStrictFilter = false)
                                                                      answer.autocomplete?.forEach((ans) => ans.isStrictFilter = false)
                                                                  }
                                                              }
                                                          })

                                                          setAnswers(tempAnswers)

                                                      }
                                                      }/>
                                            <p>Der/Die Bewerbende <b>muss</b> mindestens
                                                einen <b>{question.selected.answer[0]}</b> haben</p>

                                        </div>

                                    </AccordionSummary>
                                </Accordion>
                            )
                        } else if (question.id == 54 || question.id == 55) {
                            return (
                                <Accordion defaultExpanded>
                                    <AccordionSummary id="panel-header" expandIcon={<ExpandMoreIcon/>}
                                                      aria-controls="panel-content">
                                        <div style={{
                                            padding: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <p>Der/Die
                                                Bewerbende <b>muss</b> {(question.id == 54) ? 'folgenden Studienabschluss' : 'folgende Ausbildung'} haben:
                                            </p>

                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails style={{
                                        width: '100%',
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <div style={{
                                            display: 'inline-block',
                                            minWidth: '50%',
                                            maxWidth: '70%',
                                            height: '100%'
                                        }}>

                                            <FormGroup style={{ marginLeft: 40 }}>
                                                {question.selected.answer.map((selected) => {
                                                    const tempAns = question.answers.find((ans) => ans.answer == selected || ans.label == selected)
                                                    if (!tempAns?.id) return

                                                    return <FormControlLabel control={<Checkbox
                                                        checked={!!answers.find((ans) => typeof ans.answer != 'string' && ans.answer?.find((answer) => answer.answerId == tempAns.id && answer.isStrictFilter)) ?? false}

                                                        onClick={() => {
                                                            const tempAnswers = [...answers]
                                                            let temp = tempAnswers.find((ans) => typeof ans.answer != 'string' && ans.answer?.find((answer) => answer.answerId == tempAns.id))
                                                            if (!temp) temp = tempAnswers.find((ans) => typeof ans.answer != 'string' && ans.answer?.find((answer) => answer.answerId == tempAns.id))
                                                            const temp2 = temp.answer.find((answer) => answer.answerId == tempAns.id)


                                                            if (temp2.isStrictFilter) {
                                                                temp2.isStrictFilter = false
                                                            } else {
                                                                temp2.isStrictFilter = true
                                                            }

                                                            setAnswers(tempAnswers)

                                                        }
                                                        }
                                                    />}
                                                                             label={((question.id == '54' && selected == 'Studium') || (question.id == '55' && selected == 'Ja')) ? (question.id == '54') ? 'Beliebiger Studiengang' : 'Beliebige Ausbildung' : selected}/>
                                                })}
                                                <FormControlLabel control={<Checkbox
                                                    checked={!!(answers.find((ans) => ans.id == question.id)?.autocomplete && answers.find((ans) => ans.id == question.id)?.autocomplete[0]?.isStrictFilter) ?? false}
                                                    onClick={() => {
                                                        const tempAnswers = [...answers]

                                                        let temp = tempAnswers.find((ans) => ans.id == question.id)
                                                        console.log(temp)
                                                        temp.autocomplete?.forEach((temp2) => {
                                                            if (temp2.isStrictFilter) {
                                                                temp2.isStrictFilter = false
                                                            } else {
                                                                temp2.isStrictFilter = true
                                                            }
                                                        })

                                                        setAnswers(tempAnswers)

                                                    }
                                                    }
                                                />} label={question.selected.autocomplete.map((selected, index) => {
                                                    // const tempAns = question.answers.find((ans) => ans.answer == selected.title || ans.label == selected.title)
                                                    // if (!tempAns?.id) return
                                                    if (index < question.selected.autocomplete.length - 1) return `${selected.title} oder `
                                                    return `${selected.title}`
                                                })}/>
                                            </FormGroup>
                                        </div>
                                        <div style={{
                                            display: 'inline-block',
                                            color: 'darkgrey',
                                            height: '100%',
                                            width: 'auto',
                                            maxWidth: '30%'
                                        }}>
                                            {(question.id == 54) ?
                                                <p style={{ display: (!answers.find((ans) => ans.id === question.id)?.autocomplete?.find(temp => temp.isStrictFilter) && !answers.find((ans) => ans.id === question.id)?.answer?.find(temp => temp.isStrictFilter)) ? 'block' : 'none' }}>
                                                    Der/Die Bewerbende muss keinen Studeienabschluss haben
                                                </p> :
                                                <p style={{ display: (!answers.find((ans) => ans.id === question.id)?.autocomplete?.find(temp => temp.isStrictFilter) && !answers.find((ans) => ans.id === question.id)?.answer?.find(temp => temp.isStrictFilter)) ? 'block' : 'none' }}>
                                                    Der/Die Bewerbende muss keine Ausbildung haben
                                                </p>}
                                            {(question.id == 54) ?
                                                <p style={{ display: (answers.find((ans) => ans.id === question.id)?.autocomplete?.find(temp => temp.isStrictFilter) || answers.find((ans) => ans.id === question.id)?.answer?.find(temp => temp.isStrictFilter)) ? 'block' : 'none' }}>
                                                    Der/Die
                                                    Bewerbende <b>muss</b> einen {(answers.find((ans) => ans.id === question.id)?.answer.find(temp => temp.isStrictFilter)) ? 'beliebigen Studienabschluss' : 'Studienabschluss'} {answers.find((ans) => ans.id === question.id)?.autocomplete?.filter(temp => temp.isStrictFilter)?.map((selected, index) => {
                                                    // const tempAns = question.answers.find((ans) => ans.answer == selected.title || ans.label == selected.title)
                                                    // if (!tempAns?.id) return
                                                    let string = ''
                                                    if (index == 0 && answers.find((ans) => ans.id === question.id)?.answer?.find(temp => temp.isStrictFilter)) {
                                                        string += 'oder einen in '
                                                    } else if (index == 0 && index < answers.find((ans) => ans.id === question.id)?.autocomplete.filter(temp => temp.isStrictFilter).length - 1) {
                                                        string += `in `
                                                    } else if (index == 0) {
                                                        string += `in `
                                                    }

                                                    if (index < answers.find((ans) => ans.id === question.id)?.autocomplete.filter(temp => temp.isStrictFilter).length - 1) return string + `${selected.answer} oder `
                                                    return string + `${selected.answer}`
                                                })} haben
                                                </p> :
                                                <p style={{ display: (answers.find((ans) => ans.id === question.id)?.autocomplete?.find(temp => temp.isStrictFilter) || answers.find((ans) => ans.id === question.id)?.answer?.find(temp => temp.isStrictFilter)) ? 'block' : 'none' }}>
                                                    Der/Die
                                                    Bewerbende <b>muss</b> eine {(answers.find((ans) => ans.id === question.id)?.answer.find(temp => temp.isStrictFilter)) ? 'beliebige Ausbildung' : 'Ausbildung'} {answers.find((ans) => ans.id === question.id)?.autocomplete?.filter(temp => temp.isStrictFilter)?.map((selected, index) => {
                                                    // const tempAns = question.answers.find((ans) => ans.answer == selected.title || ans.label == selected.title)
                                                    // if (!tempAns?.id) return
                                                    let string = ''
                                                    if (index == 0 && answers.find((ans) => ans.id === question.id)?.answer?.find(temp => temp.isStrictFilter)) {
                                                        string += 'oder eine als '
                                                    } else if (index == 0 && index < answers.find((ans) => ans.id === question.id)?.autocomplete.filter(temp => temp.isStrictFilter).length - 1) {
                                                        string += `als `
                                                    } else if (index == 0) {
                                                        string += `als `
                                                    }

                                                    if (index < answers.find((ans) => ans.id === question.id)?.autocomplete.filter(temp => temp.isStrictFilter).length - 1) return string + `${selected.answer} oder `
                                                    return string + `${selected.answer}`
                                                })} haben
                                                </p>}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        } else if (question.id == 56 || question.id == 58) {
                            return (
                                <Accordion defaultExpanded>
                                    <AccordionSummary id="panel-header" expandIcon={<ExpandMoreIcon/>}
                                                      aria-controls="panel-content">
                                        <div style={{
                                            padding: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <p>Der/Die
                                                Bewerbende <b>muss</b> {(question.id == 56) ? 'folgende Sprachen beherrschen' : 'folgende Fahrerlaubnis besitzen'}:
                                            </p>

                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails style={{
                                        width: '100%',
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        {/*{JSON.stringify(question.selected)}*/}
                                        <div style={{
                                            display: 'inline-block',
                                            minWidth: '50%',
                                            maxWidth: '70%',
                                            height: '100%'
                                        }}>
                                            <FormGroup style={{ marginLeft: 40 }}>
                                                {question.selected.answer.map((selected) => {
                                                    const tempAns = question.answers.find((ans) => ans.answer == selected || ans.label == selected)
                                                    if (!tempAns?.id) return

                                                    return <FormControlLabel control={<Checkbox
                                                        checked={!!answers.find((ans) => typeof ans.answer != 'string' && ans.answer?.find((answer) => answer.answerId == tempAns.id && answer.isStrictFilter)) ?? false}

                                                        onClick={() => {
                                                            const tempAnswers = [...answers]
                                                            let temp = tempAnswers.find((ans) => typeof ans.answer != 'string' && ans.answer?.find((answer) => answer.answerId == tempAns.id))
                                                            if (!temp) temp = tempAnswers.find((ans) => typeof ans.answer != 'string' && ans.answer?.find((answer) => answer.answerId == tempAns.id))
                                                            const temp2 = temp.answer.find((answer) => answer.answerId == tempAns.id)


                                                            if (temp2.isStrictFilter) {
                                                                temp2.isStrictFilter = false
                                                            } else {
                                                                temp2.isStrictFilter = true
                                                            }

                                                            setAnswers(tempAnswers)

                                                        }
                                                        }
                                                    />}
                                                                             label={((question.id == '54' && selected == 'Studium') || (question.id == '55' && selected == 'Ja')) ? (question.id == '54') ? 'Beliebiger Studiengang' : 'Beliebige Ausbildung' : selected}/>
                                                })}
                                                {question.selected.autocomplete.map((selected) => {
                                                    // const tempAns = question.answers.find((ans) => ans.answer == selected.title || ans.label == selected.title)
                                                    // if (!tempAns?.id) return
                                                    return <FormControlLabel control={<Checkbox
                                                        checked={!!answers.find((ans) => ans.autocomplete?.find((answer) => answer.answer == selected.title && answer.isStrictFilter)) ?? false}
                                                        onClick={() => {
                                                            const tempAnswers = [...answers]
                                                            let temp = tempAnswers.find((ans) => ans.autocomplete?.find((answer) => answer.answer == selected.title))

                                                            if (!temp) temp = tempAnswers.find((ans) => ans.autocomplete.find((answer) => answer.answer == selected.title))
                                                            const temp2 = temp.autocomplete.find((answer) => answer.answer == selected.title)


                                                            if (temp2.isStrictFilter) {
                                                                temp2.isStrictFilter = false
                                                            } else {
                                                                temp2.isStrictFilter = true
                                                            }

                                                            setAnswers(tempAnswers)

                                                        }
                                                        }
                                                    />} label={selected.title}/>
                                                })}
                                            </FormGroup>
                                        </div>
                                        <div style={{
                                            display: 'inline-block',
                                            color: 'darkgrey',
                                            height: '100%',
                                            width: 'auto',
                                            maxWidth: '30%'
                                        }}>
                                            {(question.id == 58) ?
                                                <p style={{ display: (answers.find((ans) => ans.id === question.id)?.answer.find(temp => temp.isStrictFilter)) ? 'block' : 'none' }}>
                                                    Der/Die
                                                    Bewerbende <b>muss</b> Fahrerlaubnis {answers.find((ans) => ans.id === question.id)?.answer.filter(temp => temp.isStrictFilter)?.map((selected, index) => {
                                                    // const tempAns = question.answers.find((ans) => ans.answer == selected.title || ans.label == selected.title)
                                                    // if (!tempAns?.id) return
                                                    if (index < answers.find((ans) => ans.id === question.id)?.answer.filter(temp => temp.isStrictFilter).length - 1) return `${selected.answer} und `
                                                    return `${selected.answer}`
                                                })} besitzen
                                                </p> :
                                                <p style={{ display: (answers.find((ans) => ans.id === question.id)?.autocomplete.find(temp => temp.isStrictFilter)) ? 'block' : 'none' }}>
                                                    Der/Die
                                                    Bewerbende <b>muss</b> {answers.find((ans) => ans.id === question.id)?.autocomplete.filter(temp => temp.isStrictFilter)?.map((selected, index) => {
                                                    // const tempAns = question.answers.find((ans) => ans.answer == selected.title || ans.label == selected.title)
                                                    // if (!tempAns?.id) return
                                                    if (index < answers.find((ans) => ans.id === question.id)?.autocomplete.filter(temp => temp.isStrictFilter).length - 1) return `${selected.answer} und `
                                                    return `${selected.answer}`
                                                })} beherrschen
                                                </p>}

                                            {(question.id == 58) ?
                                                <p style={{ display: (answers.find((ans) => ans.id === question.id)?.answer.find(temp => !temp.isStrictFilter)) ? 'block' : 'none' }}>
                                                    Optional: {answers.find((ans) => ans.id === question.id)?.answer.filter(temp => !temp.isStrictFilter)?.map((selected, index) => {
                                                    // const tempAns = question.answers.find((ans) => ans.answer == selected.title || ans.label == selected.title)
                                                    // if (!tempAns?.id) return
                                                    if (index < answers.find((ans) => ans.id === question.id)?.answer.filter(temp => !temp.isStrictFilter).length - 1) return `${selected.answer}, `
                                                    return `${selected.answer}`
                                                })}
                                                </p> :
                                                <p style={{ display: (answers.find((ans) => ans.id === question.id)?.autocomplete.find(temp => !temp.isStrictFilter)) ? 'block' : 'none' }}>
                                                    Optional: {answers.find((ans) => ans.id === question.id)?.autocomplete.filter(temp => !temp.isStrictFilter)?.map((selected, index) => {
                                                    // const tempAns = question.answers.find((ans) => ans.answer == selected.title || ans.label == selected.title)
                                                    // if (!tempAns?.id) return
                                                    if (index < answers.find((ans) => ans.id === question.id)?.autocomplete.filter(temp => !temp.isStrictFilter).length - 1) return `${selected.answer}, `
                                                    return `${selected.answer}`
                                                })}
                                                </p>}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        }
                    })}


                    <CustomButton
                        buttontype={'yellowBlue'}
                        onClick={() => {
                            saveChanges()
                        }}
                        style={{
                            marginTop: 20,
                            width: '100%'
                        }}
                    >Speichern</CustomButton>
                    <span style={{ position: "absolute", right: 5, top: 5 }}>
                        <ClearIcon
                            className={'not-yellow-white'}
                            onClick={() => {
                                setOnMustHaveModal(false)
                                setSaveAsDraft(false)
                            }}
                            style={{
                                height: 26,
                                width: 26,
                                marginRight: 2,
                                transition: 'all 0.2s ease-in-out',
                                textAlign: 'center',
                                cursor: 'pointer',
                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                            }}
                        />
                    </span>
                </Box>
            </Modal>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    // if (isStepOptional(index)) {
                    //     labelProps.optional = (
                    //         <Typography variant="caption">Optional</Typography>
                    //     );
                    // }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}></StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <div className="image-item" style={{
                        width: '100%',
                        position: 'relative',
                        textAlign: 'center'
                    }}>
                        {userImage.map((image, index) => (
                            <img key={index}
                                 src={image ? image.data_url ? image.data_url : image.id ? ENV.REACT_APP_API_URL + '/file/' + image.id + '/unlogged/content' : null : null}
                                 style={{
                                     height: 200,
                                     width: 'auto',
                                     maxWidth: 200,
                                     maxHeight: 200,
                                     marginTop: 20,
                                 }} alt=""/>
                        ))}

                    </div>
                    <p style={{
                        fontFamily: 'VagRoundedBold',
                        fontSize: 18,
                        margin: 0,
                        marginTop: (!userImage || !userImage.length) ? 30 : 10
                    }} className={'color-blue'}>
                        {title.title ?? ''} {subTitle ?? ''}
                    </p>
                    <p style={{
                        fontFamily: 'VagRoundedBold',
                        fontSize: 24,
                        color: '#C7D300',
                        margin: 0,
                        marginTop: 20,
                    }} className={'color-blue'}>
                        Erledigt!
                    </p>
                    {!id && <><p style={{
                        fontFamily: 'VagRoundedBold',
                        fontSize: 16,
                        color: '#C7D300',
                        margin: 0,
                        marginBottom: 30
                    }} className={'color-blue'}>
                        Deine Anzeige geht innerhalb von 24 Stunden online!
                    </p>

                        <p className={'color-blue'} style={{ fontSize: 12 }}>Du kannst nicht auf Bewerbungen
                            warten?</p>
                        <p className={'color-blue'} style={{ fontSize: 12 }}>Dann schau doch mal in deine <a
                            onClick={() => history.push('/matches-arbeitgeber')} style={{
                            fontFamily: 'VagRoundedBold',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}>Matches</a></p></>}
                    {id && <><p style={{
                        fontFamily: 'VagRoundedBold',
                        fontSize: 16,
                        color: '#C7D300',
                        margin: 0,
                        marginBottom: 30
                    }} className={'color-yellow'}>
                        Deine Anzeige wurde aktualisiert.
                    </p>

                        <p className={'color-blue'} style={{ fontSize: 12 }}>Du kannst nicht auf Bewerbungen
                            warten?</p>
                        <p className={'color-blue'} style={{ fontSize: 12 }}>Dann schau doch mal in deine <a
                            onClick={() => history.push('/matches-arbeitgeber')} style={{
                            fontFamily: 'VagRoundedBold',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}>Matches</a></p></>}


                    <Typography sx={{ mt: 2, mb: 1 }}>
                        {/*All steps completed - you&apos;re finished*/}
                        <button className={'fadeInOut'}
                                onMouseOver={() => setButtonHover('newStellenanzeigeButtonHover')}
                                onMouseOut={() => setButtonHover('')} style={{
                            width: 225,
                            fontFamily: 'VagRoundedBold',
                            //background: logoutButtonHover ? '#00374e' : '#C7D300',
                            background: buttonHover == 'newStellenanzeigeButtonHover' ? '#00374e' : '#C7D300',
                            cursor: 'pointer',
                            textAlign: 'center',
                            padding: '10px 10px 10px 10px',
                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                            color: buttonHover == 'newStellenanzeigeButtonHover' ? '#C7D300' : '#00374e',
                            border: '2px solid ' + (buttonHover == 'newStellenanzeigeButtonHover' ? '#00374e' : '#C7D300'),
                        }} onClick={() => {
                            history.push('/stellenanzeigen')
                        }}>ZU MEINEN STELLENANZEIGEN
                        </button>
                    </Typography>

                    {/*<p className={'color-blue'} style={{fontSize: 10}}><a*/}
                    {/*    onClick={() => history.push('/stellenanzeigen')}*/}
                    {/*    style={{fontFamily: 'VagRoundedBold', textDecoration: 'underline', cursor: 'pointer'}}>Zurück*/}
                    {/*    zu meinen Stellenanzeigen</a></p>*/}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={{ margin: '10px 0' }}>
                        <CustomButton
                            buttontype={'yellowWhiteDisabled'}
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                            style={{
                                borderRadius: 20
                            }}
                        >
                            <ReplyIcon
                                style={{
                                    marginBottom: 3,
                                    width: 30,
                                    height: 30,
                                    paddingRight: 3
                                }}
                            />
                            Zurück
                        </CustomButton>
                        <Box sx={{ flex: '1 1 auto' }}/>
                        <CustomButton
                            buttontype={'yellowWhiteDisabled'}
                            disabled={activeStep === steps.length - 1}
                            onClick={handleNext}
                            sx={{ mr: 1 }}
                            style={{
                                borderRadius: 20
                            }}
                        >
                            Nächster Schritt
                            <ReplyIcon
                                style={{
                                    transform: 'scaleX(-1)',
                                    marginBottom: 3,
                                    width: 30,
                                    height: 30,
                                    paddingRight: 3
                                }}
                            />
                        </CustomButton>
                    </Box>
                    {loaded && <React.Fragment><Typography sx={{ mt: 2, mb: 1 }} style={{
                        textAlign: 'left',
                        padding: '0 0 10px 15px',
                        color: '#C7D300',
                        fontFamily: 'VagRoundedBold',
                        fontSize: 32
                    }}>{activeStep + 1}. {steps[activeStep]}</Typography>
                        <Grid container>
                            <Grid item xs={9}>
                                <Grid container>
                                    <Grid
                                        ref={refStep1}
                                        item
                                        xs={12}
                                        style={{
                                            // background: activeStep == 1 ? 'rgba(0, 55, 78, 0.9)' : '',
                                            width: '100%',
                                            padding: 20,
                                            position: 'relative',
                                            zIndex: 2,
                                            minHeight: 200,
                                            marginBottom: 20,
                                        }}>
                                        <div style={{ visibility: activeStep != 0 ? 'visible' : 'visible' }}>
                                            <Grid container>
                                                <Grid item xs={12} style={{ display: 'flex' }}>
                                                    <Autocomplete
                                                        options={autocomplete}
                                                        getOptionLabel={(option) => {
                                                            return option.title ?? '';
                                                        }}
                                                        id={'jobAdvertisementTitel'}
                                                        dataSourceConfig={{ text: props.title, value: props.input }}
                                                        name={''}
                                                        freeSolo
                                                        openOnFocus={false}
                                                        value={title}
                                                        style={{
                                                            flexGrow: 1
                                                        }}
                                                        onChange={(event, newValue) => {
                                                            if (typeof newValue === 'string') {
                                                                setTitle({
                                                                    input: newValue,
                                                                    title: newValue,
                                                                });
                                                            } else if (newValue && newValue.title) {
                                                                // Create a new value from the user input
                                                                setTitle({
                                                                    input: newValue.title,
                                                                    title: newValue.title,
                                                                });
                                                            } else {
                                                                setTitle(newValue);
                                                            }
                                                            setIsFocused(null)
                                                        }}
                                                        onInputChange={async (event, newInputValue) => {
                                                            if (!title || title.title != newInputValue) setIsFocused('Berufe')
                                                            if (newInputValue == undefined || newInputValue == 'undefined') {
                                                                newInputValue = '';
                                                            }
                                                            setAutocompleteLoading(true);
                                                            setAutocompleteInput(newInputValue);
                                                            if (typeof newInputValue === 'string') {
                                                                setTitle({
                                                                    input: newInputValue,
                                                                    title: newInputValue,
                                                                });
                                                            } else if (newInputValue && newInputValue.title) {
                                                                // Create a new value from the user input
                                                                setTitle({
                                                                    input: newInputValue.title,
                                                                    title: newInputValue.title,
                                                                });
                                                            } else {
                                                                setTitle(newInputValue);
                                                            }

                                                            let t = [];
                                                            if (newInputValue && newInputValue.length > 0) {
                                                                let url = ENV.REACT_APP_API_URL + '/autocompletes?filter%5BautocompleteType%5D%5Beq%5D=Berufe&filter%5BautocompleteData%5D%5Blike%5D=' + newInputValue;

                                                                let { response, data } = await api(url);

                                                                if (data && data.data) {
                                                                    data.data.map((item, index) => {
                                                                        if (!t.includes({
                                                                            input: item.attributes.autocompleteData,
                                                                            title: item.attributes.autocompleteData
                                                                        })) t.push({
                                                                            input: item.attributes.autocompleteData,
                                                                            title: item.attributes.autocompleteData
                                                                        })
                                                                    })
                                                                }
                                                            }
                                                            setAutocompleteLoading(false);
                                                            setAutocomplete(t)
                                                        }}
                                                        open={(autocomplete.length > 0 && isFocused == 'Berufe')}
                                                        groupBy={(option) => 'Vorschläge'}
                                                        className={'text-input-label-blue ' + (isFocused === 'Berufe' ? '' : '')}
                                                        onFocus={() => {
                                                            setIsFocused('Berufe')
                                                        }}
                                                        onBlur={() => {
                                                            setIsFocused(null)
                                                            if (autocompleteInput) setTitle({
                                                                input: autocompleteInput,
                                                                title: autocompleteInput
                                                            });
                                                        }}
                                                        selectOnFocus
                                                        clearOnBlur
                                                        handleHomeEndKeys
                                                        renderInput={(params) =>
                                                            <>
                                                                <CssTextField2
                                                                    {...params}
                                                                    sx={{ input: { color: 'white' } }}
                                                                    label={'Titel*'}
                                                                    placeholder="z.B. Verkäufer*in"
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                    variant="outlined"
                                                                    style={{
                                                                        width: '100%',
                                                                        margin: '5px 0 15px 0',
                                                                        padding: 0,
                                                                    }}
                                                                    error={!!titleError}
                                                                    helperText={'Nutze wenn möglich unsere Vorschläge!'}

                                                                />
                                                            </>
                                                        }

                                                    />
                                                    <FormGroup style={{ marginTop: 15 }}>
                                                        <FormControlLabel id={'mWDSwitch'}
                                                                          control={<Switch color="secondary"
                                                                                           checked={gendering}
                                                                                           onChange={() => {
                                                                                               updateUser()
                                                                                           }}/>}
                                                                          label={
                                                                              <Typography sx={{
                                                                                  fontSize: '14px',
                                                                                  position: 'relative',
                                                                                  paddingRight: '15px'
                                                                              }}>
                                                                                  (m/w/d)
                                                                                  <HtmlTooltip placement="right"
                                                                                               PopperProps={{
                                                                                                   modifiers: [
                                                                                                       {
                                                                                                           name: "offset",
                                                                                                           options: {
                                                                                                               offset: [0, -40],
                                                                                                           },
                                                                                                       },
                                                                                                   ],
                                                                                               }}
                                                                                               title={
                                                                                                   <React.Fragment>
                                                                                                       <p className={'color-blue'}
                                                                                                          style={{
                                                                                                              textAlign: 'center',
                                                                                                              fontSize: 14,
                                                                                                              marginBottom: 2
                                                                                                          }}>"(m/w/d)"</p>
                                                                                                       <p className={'color-blue'}
                                                                                                          style={{
                                                                                                              textAlign: 'center',
                                                                                                              fontSize: 14,
                                                                                                              marginTop: 2
                                                                                                          }}>(männlich/weiblich/divers)</p>
                                                                                                       <p className={'color-blue'}
                                                                                                          style={{
                                                                                                              textAlign: 'center',
                                                                                                              fontSize: 14
                                                                                                          }}>Wird in
                                                                                                           deinen
                                                                                                           Stellenanzeigen
                                                                                                           automatisch
                                                                                                           angezeigt.</p>

                                                                                                   </React.Fragment>
                                                                                               }>
                                                                                      <InfoIcon style={{
                                                                                          position: 'absolute',
                                                                                          right: 0,
                                                                                          top: 0,
                                                                                      }}
                                                                                                fontSize={'14px'}
                                                                                                id={'infoIcon'}/>
                                                                                  </HtmlTooltip>
                                                                              </Typography>
                                                                          }/>

                                                    </FormGroup>
                                                    <FormControl
                                                        sx={{
                                                            width: '40%'
                                                        }}
                                                        style={{
                                                            marginTop: 5,
                                                            transition: 'all 0.15s ease',
                                                            minHeight: 46,
                                                        }}
                                                        className={'testClassSelect jobTypes'}>
                                                        <TextField
                                                            value={jobTypes?.selected?.answer ? jobTypes.selected.answer : []}
                                                            select // tell TextField to render select
                                                            multiple
                                                            SelectProps={{
                                                                multiple: true,
                                                                value: jobTypes?.selected?.answer ? jobTypes.selected.answer : [],
                                                                open: multiSelectOpen,
                                                                onClick: () => {
                                                                    if (!multiSelectOpen) setMultiSelectOpen(true);
                                                                },
                                                                onClose: () => {
                                                                    setMultiSelectOpen(false);
                                                                }
                                                            }}
                                                            label="Beschäftigungsart (optional)"
                                                            onChange={(e) => {
                                                                jobTypes.selected.answer = e.target.value
                                                                setJobTypes(jobTypes);
                                                                setMultiSelectOpen(false)
                                                                fragenRef.checkQuestions();
                                                            }}
                                                            style={{
                                                                height: 55
                                                            }}
                                                        >
                                                            <MenuItem disabled value="">
                                                            </MenuItem>
                                                            {(jobTypes && jobTypes.answers && jobTypes.answers.length > 0) && jobTypes.answers.map((childItem, childIndex) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={childItem.answer}>{childItem.answer}{(childItem.helperText) &&
                                                                        <span style={{ color: 'white' }}>
                                                                    <Tooltip enterTouchDelay={0}
                                                                             title={childItem.helperText}>
                                                                        <InfoIcon
                                                                            style={{ marginLeft: 4 }}
                                                                            fontSize={'14px'}
                                                                            id={'infoIcon'}
                                                                        />
                                                                    </Tooltip>
                                                                </span>
                                                                    }</MenuItem>
                                                                );
                                                            })}
                                                        </TextField>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <CssTextField2
                                                        label="Alternative Berufsbezeichnung / Untertitel (optional)"
                                                        placeholder="z.B. Gebietsleiter*in im Außendienst"
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        className={'text-input-label-blue'}
                                                        style={{
                                                            transition: 'all 0.2s ease',
                                                            margin: '10px 5px 10px 0',
                                                            minHeight: 60,
                                                            width: 'calc(100% - 5px)'
                                                        }}
                                                        autoComplete={'off'}
                                                        onChange={e => {
                                                            setSubTitle(e.target.value);
                                                        }}
                                                        value={subTitle}
                                                        error={!!subTitleError}
                                                        helperText={subTitleError === true ? 'SubTitle darf nicht leer sein!' : subTitleError}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <CssTextField2
                                                        label="Slogan (optional)"
                                                        placeholder="z.B. Gemeinsam zum Erfolg!"
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        className={'text-input-label-blue'}
                                                        style={{
                                                            transition: 'all 0.2s ease',
                                                            margin: '10px 5px 10px 0',
                                                            minHeight: 60,
                                                            width: 'calc(100% - 5px)'
                                                        }}
                                                        autoComplete={'off'}
                                                        onChange={e => {
                                                            setSlogan(e.target.value);
                                                        }}
                                                        value={slogan}
                                                        error={!!sloganError}
                                                        helperText={sloganError === true ? 'Slogan darf nicht leer sein!' : sloganError}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div style={{
                                                        textAlign: 'left'
                                                    }}>
                                                        {badges.map((item, index) => {


                                                            let placeholder = ['z.B. Firmenwagen', 'z.B. 13. Gehalt', '', '']

                                                            return (
                                                                <CssTextField2
                                                                    key={index}
                                                                    width={'25%'}
                                                                    label={item.text}
                                                                    placeholder={placeholder[index]}
                                                                    value={badges[index].value}
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                    className={'text-input-label-blue teeeest'}
                                                                    style={{
                                                                        transition: 'all 0.2s ease',
                                                                        margin: '10px 0 10px 0',
                                                                        // paddingRight: 5,
                                                                        minHeight: 60,
                                                                        width: '25%',
                                                                        display: (index === 0) ? 'inline-block' : badges[index - 1].value !== '' ? 'inline-block' : 'none'
                                                                    }}
                                                                    onChange={(e) => {
                                                                        let temp = [...badges];
                                                                        let t = [...badgesError];
                                                                        if (e.target.value.length > 25) {
                                                                            t[index] = true;
                                                                        } else {
                                                                            delete t[index];
                                                                        }
                                                                        setBadgesError(t);
                                                                        temp[index].value = e.target.value;

                                                                        setBadges(temp);
                                                                    }}
                                                                    error={badgesError[index]}
                                                                    helperText={badgesError[index] === true ? ('max. 25 Zeichen möglich!') : badgesError[index - 1]}
                                                                />
                                                            );
                                                        })}
                                                    </div>
                                                </Grid>
                                                {/*<Grid item xs={12}>*/}
                                                {/*    <div style={{*/}
                                                {/*        textAlign: 'left'*/}
                                                {/*    }}>*/}
                                                {/*        {badges.map((item, index) => {*/}


                                                {/*            let placeholder = ['z.B. Firmenwagen', 'z.B. 13. Gehalt', '', '']*/}

                                                {/*            return (*/}
                                                {/*                <CssTextField2*/}
                                                {/*                    key={index}*/}
                                                {/*                    width={'25%'}*/}
                                                {/*                    label={item.text}*/}
                                                {/*                    placeholder={placeholder[index]}*/}
                                                {/*                    value={badges[index].value}*/}
                                                {/*                    InputLabelProps={{*/}
                                                {/*                        shrink: true*/}
                                                {/*                    }}*/}
                                                {/*                    className={'text-input-label-blue teeeest'}*/}
                                                {/*                    style={{*/}
                                                {/*                        transition: 'all 0.2s ease',*/}
                                                {/*                        margin: '10px 0 10px 0',*/}
                                                {/*                        // paddingRight: 5,*/}
                                                {/*                        minHeight: 60,*/}
                                                {/*                        width: '25%',*/}
                                                {/*                        display: (index === 0) ? 'inline-block' : badges[index - 1].value !== '' ? 'inline-block' : 'none'*/}
                                                {/*                    }}*/}
                                                {/*                    onChange={(e) => {*/}
                                                {/*                        let temp = [...badges];*/}
                                                {/*                        let t = [...badgesError];*/}
                                                {/*                        if (e.target.value.length > 25) {*/}
                                                {/*                            t[index] = true;*/}
                                                {/*                        } else {*/}
                                                {/*                            delete t[index];*/}
                                                {/*                        }*/}
                                                {/*                        setBadgesError(t);*/}
                                                {/*                        temp[index].value = e.target.value;*/}

                                                {/*                        setBadges(temp);*/}
                                                {/*                    }}*/}
                                                {/*                    error={badgesError[index]}*/}
                                                {/*                    helperText={badgesError[index] === true ? ('max. 25 Zeichen möglich!') : badgesError[index - 1]}*/}
                                                {/*                />*/}
                                                {/*            );*/}
                                                {/*        })}*/}
                                                {/*    </div>*/}
                                                {/*</Grid>*/}
                                                <Grid item xs={12}>
                                                    <div style={{ maxHeight: 200, height: 'auto' }}>
                                                        <ImageUploading
                                                            multiple
                                                            value={teaserImage}
                                                            onChange={onChangeTeaserImage}
                                                            maxNumber={1}
                                                            dataURLKey="data_url"
                                                            acceptType={["jpg"]}
                                                        >
                                                            {({
                                                                  imageList,
                                                                  onImageUpload,
                                                                  onImageRemoveAll,
                                                                  onImageUpdate,
                                                                  onImageRemove,
                                                                  isDragging,
                                                                  dragProps
                                                              }) => (
                                                                <div className="upload__image-wrapper" style={{
                                                                    width: '100%',
                                                                    height: 200,
                                                                    maxHeight: 200,
                                                                    position: 'relative',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    zIndex: 2
                                                                }}>
                                                                    {(!teaserImage || teaserImage.length === 0) &&
                                                                        <span style={{
                                                                            width: 'fit-content',
                                                                            height: '100%',
                                                                            position: 'relative'
                                                                        }}>
                                                                            <LogoUser
                                                                                style={{
                                                                                    height: '100%',
                                                                                    width: 'auto',
                                                                                    cursor: 'pointer',
                                                                                    fill: '#C7D300',
                                                                                    maxHeight: 200,
                                                                                    position: 'relative'
                                                                                }}
                                                                                onClick={() => onImageUpload()}
                                                                            >
                                                                            </LogoUser>
                                                                            <span style={{
                                                                                position: 'absolute',
                                                                                bottom: '-5px',
                                                                                right: 0
                                                                            }}>
                                                                                <ClearIcon
                                                                                    className={'not-yellow-white'}
                                                                                    onClick={() => {
                                                                                        onImageUpload()
                                                                                    }}
                                                                                    style={{
                                                                                        height: 35,
                                                                                        width: 35,
                                                                                        marginRight: 2,
                                                                                        padding: 5,
                                                                                        transition: 'all 0.2s ease-in-out',
                                                                                        textAlign: 'center',
                                                                                        cursor: 'pointer',
                                                                                        fill: 'white',
                                                                                        background: '#00374a',
                                                                                        transform: 'rotate(45deg)',
                                                                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                                    }}
                                                                                />
                                                                            </span>
                                                                        </span>
                                                                    }
                                                                    {(!teaserImage || teaserImage.length === 0) &&

                                                                        <p onClick={() => onImageUpload()}
                                                                           className={'color-blue'} style={{
                                                                            position: 'absolute',
                                                                            cursor: 'pointer',
                                                                            background: 'transparent',
                                                                            fontFamily: 'VagRoundedBold',
                                                                            padding: '3px 5px 2px',
                                                                            borderRadius: 4,
                                                                            fontSize: 10,
                                                                            top: '42%',
                                                                        }}>Imagebild hochladen<Tooltip
                                                                            enterTouchDelay={0}
                                                                            title={'Hebe deine Stellenanzeige mit einem Imagebild hervor!'}>
                                                                            <InfoIcon
                                                                                style={{ marginLeft: 4 }}
                                                                                fontSize={'small'}
                                                                                id={'infoIcon'}
                                                                            />
                                                                        </Tooltip></p>}
                                                                    {teaserImage.map((image, index) => (

                                                                        <div key={index} className="image-item" style={{
                                                                            width: '100%',
                                                                            position: 'relative',
                                                                            textAlign: 'center'
                                                                        }}>
                                                                            <img
                                                                                src={image ? image.data_url ? image.data_url : image.id ? ENV.REACT_APP_API_URL + '/file/' + image.id + '/unlogged/content' : null : null}
                                                                                style={{
                                                                                    maxHeight: 200,
                                                                                    maxWidth: '100%', width: 'auto',
                                                                                    cursor: 'pointer',
                                                                                }} onClick={() => onImageUpdate(index)}
                                                                                alt=""
                                                                                width="100"/>
                                                                            {/*<div className="image-item__btn-wrapper">*/}
                                                                            {/*    <button style={{marginLeft: 0, width: 160, marginBottom: 10}}*/}
                                                                            {/*            onClick={() => onImageUpdate(index)}>Update*/}
                                                                            {/*    </button>*/}
                                                                            {/*    <button style={{marginLeft: 0, width: 160, marginBottom: 0}}*/}
                                                                            {/*            onClick={() => onImageRemove(index)}>Remove*/}
                                                                            {/*    </button>*/}
                                                                            {/*</div>*/}

                                                                        </div>
                                                                    ))}
                                                                    {(teaserImage && teaserImage.length > 0) &&
                                                                        <p style={{
                                                                            position: 'absolute',
                                                                            bottom: '0',
                                                                        }}>
                                                        <span onClick={() => onImageUpdate(0)} style={{
                                                            color: 'white',
                                                            cursor: 'pointer',
                                                            background: '#00374e',
                                                            padding: '2px 5px',
                                                            borderRadius: 4,
                                                            fontSize: 10,
                                                        }}>Imagebild ändern</span>
                                                                            {teaserImage && <span style={{
                                                                                position: 'relative',
                                                                                marginLeft: 2,
                                                                            }}
                                                                                                  onClick={() => {
                                                                                                      //

                                                                                                      if (fileId) {
                                                                                                          deleteTeaserImage();
                                                                                                      } else {
                                                                                                          setTeaserImage([]);
                                                                                                          setImageDescription('');
                                                                                                          setImageDescriptionError('');
                                                                                                      }


                                                                                                  }}
                                                                            >
                                                        <ClearIcon
                                                            className={'not-yellow'}
                                                            onClick={() => {
                                                                //setGetRef(true);
                                                                //setNewAddress(true);
                                                            }}
                                                            style={{
                                                                position: 'absolute',
                                                                bottom: 0,
                                                                background: '#00374e',
                                                                borderRadius: 4,
                                                                padding: 1,
                                                                height: 12,
                                                                width: 12,
                                                                marginRight: 2,
                                                                transition: 'all 0.2s ease-in-out',
                                                                textAlign: 'center',
                                                                fill: 'red',
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </span>}
                                                                        </p>}


                                                                </div>
                                                            )}
                                                        </ImageUploading>
                                                    </div>
                                                </Grid>
                                                {(teaserImage && teaserImage.length !== 0) && <Grid item xs={12}>
                                                    <CssTextField3
                                                        label="Bildunterschrift (optional)"
                                                        placeholder="z.B. Standort Kempten"
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        className={'text-input-label-blue'}
                                                        style={{
                                                            transition: 'all 0.2s ease',
                                                            margin: '10px 5px 10px 0',
                                                            minHeight: 60,
                                                            width: 'calc(100% - 5px)'
                                                        }}
                                                        autoComplete={'off'}
                                                        onChange={e => {
                                                            setImageDescription(e.target.value);
                                                        }}
                                                        value={imageDescription}
                                                    />
                                                </Grid>}
                                            </Grid>
                                        </div>
                                        <div style={{
                                            transition: 'opacity 0.05s ease-in-out',
                                            position: activeStep != 0 ? 'absolute' : 'relative',
                                            zIndex: 3,
                                            width: '100%',
                                            height: activeStep != 0 ? '100%' : 0,
                                            background: 'rgba(0, 55, 78, 0.9)',
                                            display: activeStep != 0 ? 'flex' : 'none',
                                            opacity: activeStep != 0 ? 1 : 0,
                                            top: 0,
                                            left: 0,
                                            borderRadius: 25,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: (!title || !title.title || title.title == '' || badgesError.filter(i => i == true).length > 0) ? '#FF0000' : 'white',
                                            fontSize: 96,
                                            fontFamily: 'VagRoundedBold',
                                            // paddingTop: 15,
                                        }} onClick={() => {
                                            setActiveStep(0);
                                        }}>
                                            <p style={{ margin: 0, lineHeight: '40px', position: 'absolute' }}>
                                                1{(
                                                    (!title || title == '' || title.title == '')
                                                ) &&
                                                <Tooltip enterTouchDelay={0}
                                                         title={'*Pflichtfeld'}>
                                                    <ErrorIcon
                                                        style={{ fontSize: 18, position: 'absolute', marginTop: -20 }}
                                                        fontSize={'10px'}
                                                        id={'erroricon'}/>
                                                </Tooltip>
                                            }<span style={{ display: 'block', marginBottom: 0 }}/><span style={{
                                                fontSize: 32,
                                            }}>{steps[0]}</span>
                                            </p>
                                        </div>

                                    </Grid>
                                    {/* swiitcher question */}
                                    <Grid
                                        ref={refStep2}
                                        item
                                        xs={12}
                                        style={{
                                            // background: activeStep == 1 ? 'rgba(0, 55, 78, 0.9)' : '',
                                            width: '100%',
                                            // padding: 20,
                                            position: 'relative',

                                            minHeight: 200,
                                            marginBottom: 20,
                                        }} onClick={() => {
                                        setActiveStep(1);
                                    }}>
                                        <div style={{
                                            transition: 'all 0.05s ease-in-out',
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%',
                                            background: 'rgba(0, 55, 78, 0.9)',
                                            display: 'flex',
                                            opacity: activeStep != 1 ? 1 : 0,
                                            zIndex: activeStep != 1 ? 3 : 0,
                                            top: 0,
                                            left: 0,
                                            borderRadius: 25,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: errors.length > 0 ? '#FF0000' : 'white',
                                            fontSize: 96,
                                            fontFamily: 'VagRoundedBold',
                                            // paddingTop: 15,
                                        }}>
                                            <p style={{ margin: 0, lineHeight: '40px' }}>
                                                2{(errors.length > 0) &&
                                                <Tooltip enterTouchDelay={0}
                                                         title={'*Pflichtfeld'}>
                                                    <ErrorIcon style={{
                                                        marginLeft: 4,
                                                        position: 'absolute',
                                                        fontSize: 18,
                                                        marginTop: -20
                                                    }}
                                                               fontSize={'10px'}
                                                               id={'erroricon'}/>
                                                </Tooltip>
                                            }<span style={{ display: 'block', marginBottom: 0 }}/><span style={{
                                                fontSize: 32,
                                            }}>{steps[1]}</span>
                                            </p>
                                        </div>
                                        <div style={{
                                            position: 'relative',
                                            zIndex: 3,
                                            visibility: activeStep != 1 ? 'visible' : 'none'
                                        }}>
                                            <div style={{ textAlign: 'left' }}>
                                                <Fragen jobTypes={jobTypes}
                                                        setJobTypes={setJobTypes}
                                                        selectedCompanyAddresses={selectedCompanyAddresses}
                                                        selectedJobTypes={selectedJobTypes}
                                                        setSelectedJobTypes={setSelectedJobTypes}
                                                        answeredQuestions={answeredQuestions}
                                                        fragenRef={fragenRef}
                                                        getAnswers={getAnswers}
                                                        getQuestions={getQuestions}
                                                        activeStep={activeStep}
                                                        selected={selected}/>
                                            </div>
                                        </div>
                                    </Grid>


                                    <Grid
                                        ref={refStep3}
                                        item
                                        xs={12}
                                        style={{
                                            // background: activeStep == 1 ? 'rgba(0, 55, 78, 0.9)' : '',
                                            width: '100%',
                                            padding: 20,
                                            position: 'relative',
                                            zIndex: 2,
                                            minHeight: 200,
                                            marginBottom: 20,
                                        }}>
                                        <div style={{ visibility: activeStep != 2 ? 'visible' : 'visible' }}>
                                            <Editor3 editorTextFromHtml={editorTextFromHtml}
                                                     setEditorText={setEditorText}/>
                                        </div>
                                        <div style={{
                                            transition: 'opacity 0.05s ease-in-out',
                                            position: activeStep != 2 ? 'absolute' : 'relative',
                                            zIndex: 3,
                                            width: '100%',
                                            height: activeStep != 2 ? '100%' : 0,
                                            background: 'rgba(0, 55, 78, 0.9)',
                                            display: activeStep != 2 ? 'flex' : 'none',
                                            opacity: activeStep != 2 ? 1 : 0,
                                            top: 0,
                                            left: 0,
                                            borderRadius: 25,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: ((editorText === '' || editorText === '<p><br></p>') && visitedEditor) ? '#FF0000' : 'white',
                                            fontSize: 96,
                                            fontFamily: 'VagRoundedBold',
                                            // paddingTop: 15,
                                        }} onClick={() => {
                                            setActiveStep(2);


                                            setTimeout(() => {
                                                let yOffset = -75;
                                                let element = refStep3.current;
                                                let y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                                                window.scrollTo({ top: y, behavior: 'smooth' });
                                            }, 100)
                                        }}>
                                            <p style={{ margin: 0, lineHeight: '40px' }}>
                                                3{((editorText === '' || editorText === '<p><br></p>') && visitedEditor) &&
                                                <Tooltip enterTouchDelay={0}
                                                         title={'*Pflichtfeld'}>
                                                    <ErrorIcon style={{
                                                        marginLeft: 4,
                                                        position: 'absolute',
                                                        fontSize: 18,
                                                        marginTop: -20
                                                    }}
                                                               fontSize={'10px'}
                                                               id={'erroricon'}/>
                                                </Tooltip>
                                            }<span style={{ display: 'block', marginBottom: 0 }}/><span style={{
                                                fontSize: 32,
                                            }}>{steps[2]}</span>
                                            </p>
                                        </div>
                                    </Grid>


                                </Grid>
                            </Grid>
                            <Grid item xs={3} style={{ padding: '0 20px', width: 'calc(100%-20px)' }}>
                                <Grid
                                    ref={refStep4}
                                    item
                                    xs={12}
                                    style={{
                                        // background: activeStep == 1 ? 'rgba(0, 55, 78, 0.9)' : '',
                                        width: '100%',
                                        padding: 0,
                                        position: 'relative',
                                        minHeight: 200,
                                        marginBottom: 20,
                                    }}>
                                    <div style={{ visibility: activeStep != 3 ? 'visible' : 'visible' }}>
                                        <div style={{ minHeight: 111, height: 200 }}>
                                            <ImageUploading
                                                multiple
                                                value={userImage}
                                                onChange={onChange}
                                                maxNumber={1}
                                                dataURLKey="data_url"
                                                acceptType={["jpg", "png"]}
                                            >
                                                {({
                                                      imageList,
                                                      onImageUpload,
                                                      onImageRemoveAll,
                                                      onImageUpdate,
                                                      onImageRemove,
                                                      isDragging,
                                                      dragProps
                                                  }) => (
                                                    <div className="upload__image-wrapper" style={{
                                                        width: '100%',
                                                        height: 200,
                                                        maxHeight: 200,
                                                        position: 'relative',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        zIndex: 2
                                                    }}>
                                                        {/*<button*/}
                                                        {/*    style={isDragging ? {*/}
                                                        {/*        color: "red",*/}
                                                        {/*        width: 80,*/}
                                                        {/*        marginRight: 10,*/}
                                                        {/*        padding: 0*/}
                                                        {/*    } : {width: 160, marginRight: 10, padding: 0}}*/}
                                                        {/*    onClick={onImageUpload}*/}
                                                        {/*    {...dragProps}*/}
                                                        {/*>*/}
                                                        {/*    Click or Drop here*/}
                                                        {/*</button>*/}
                                                        {/*<button onClick={onImageRemoveAll} style={{width: 160}}>Remove image</button>*/}
                                                        {(!userImage || userImage.length === 0) &&
                                                            <span style={{
                                                                width: 'fit-content',
                                                                height: '100%',
                                                                position: 'relative'
                                                            }}>
                                                                            <LogoUser
                                                                                style={{
                                                                                    height: '100%',
                                                                                    width: 'auto',
                                                                                    cursor: 'pointer',
                                                                                    fill: '#C7D300',
                                                                                    maxHeight: 200,
                                                                                    position: 'relative'
                                                                                }}
                                                                                onClick={() => onImageUpload()}
                                                                            >
                                                                            </LogoUser>
                                                                            <span style={{
                                                                                position: 'absolute',
                                                                                bottom: '-5px',
                                                                                right: 0
                                                                            }}>
                                                                                <ClearIcon
                                                                                    className={'not-yellow-white'}
                                                                                    onClick={() => {
                                                                                        onImageUpload()
                                                                                    }}
                                                                                    style={{
                                                                                        height: 35,
                                                                                        width: 35,
                                                                                        marginRight: 2,
                                                                                        padding: 5,
                                                                                        transition: 'all 0.2s ease-in-out',
                                                                                        textAlign: 'center',
                                                                                        cursor: 'pointer',
                                                                                        fill: 'white',
                                                                                        background: '#00374a',
                                                                                        transform: 'rotate(45deg)',
                                                                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                                    }}
                                                                                />
                                                                            </span>
                                                                        </span>}
                                                        {(!userImage || userImage.length === 0) &&
                                                            <p onClick={() => onImageUpload()}
                                                               className={'color-blue'} style={{
                                                                position: 'absolute',
                                                                cursor: 'pointer',
                                                                background: 'transparent',
                                                                fontFamily: 'VagRoundedBold',
                                                                padding: '3px 5px 2px',
                                                                borderRadius: 4,
                                                                fontSize: 10,
                                                                top: '46%',
                                                            }}>Firmenlogo hochladen</p>}
                                                        {userImage.map((image, index) => (

                                                            <div key={index} className="image-item" style={{
                                                                width: '100%',
                                                                position: 'relative',
                                                                textAlign: 'center'
                                                            }}>
                                                                <img
                                                                    src={image ? image.data_url ? image.data_url : image.id ? ENV.REACT_APP_API_URL + '/file/' + image.id + '/unlogged/content' : null : null}
                                                                    style={{
                                                                        maxHeight: 200,
                                                                        maxWidth: '100%', width: 'auto',
                                                                        cursor: 'pointer'
                                                                    }} onClick={() => onImageUpdate(index)} alt=""
                                                                    width="100"/>
                                                                {/*<div className="image-item__btn-wrapper">*/}
                                                                {/*    <button style={{marginLeft: 0, width: 160, marginBottom: 10}}*/}
                                                                {/*            onClick={() => onImageUpdate(index)}>Update*/}
                                                                {/*    </button>*/}
                                                                {/*    <button style={{marginLeft: 0, width: 160, marginBottom: 0}}*/}
                                                                {/*            onClick={() => onImageRemove(index)}>Remove*/}
                                                                {/*    </button>*/}
                                                                {/*</div>*/}

                                                            </div>
                                                        ))}
                                                        {(userImage && userImage.length > 0) &&
                                                            <p style={{
                                                                position: 'absolute',
                                                                bottom: '0',
                                                            }}>
                                                        <span onClick={() => onImageUpdate(0)} style={{
                                                            color: 'white',
                                                            cursor: 'pointer',
                                                            background: '#00374e',
                                                            padding: '2px 5px',
                                                            borderRadius: 4,
                                                            fontSize: 10,
                                                        }}>Firmenlogo ändern</span>
                                                                {userImage && <span style={{
                                                                    position: 'relative',
                                                                    marginLeft: 2,
                                                                }}
                                                                                    onClick={() => {
                                                                                        deleteImage();
                                                                                    }}
                                                                >
                                                        <ClearIcon
                                                            className={'not-yellow'}
                                                            onClick={() => {
                                                                //setGetRef(true);
                                                                //setNewAddress(true);
                                                            }}
                                                            style={{
                                                                position: 'absolute',
                                                                bottom: 0,
                                                                background: '#00374e',
                                                                borderRadius: 4,
                                                                padding: 1,
                                                                height: 12,
                                                                width: 12,
                                                                marginRight: 2,
                                                                transition: 'all 0.2s ease-in-out',
                                                                textAlign: 'center',
                                                                fill: 'red',
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </span>}
                                                            </p>}


                                                    </div>
                                                )}
                                            </ImageUploading>
                                        </div>
                                        <div style={{ position: 'relative' }}>
                                            {!editAddress &&
                                                <div style={{ position: 'absolute', right: 15, top: 25, zIndex: 10 }}>
                                                    <ClearIcon
                                                        className={'not-yellow clearIconAdvertisement'}
                                                        onClick={() => {
                                                            setEditAddress(true);
                                                        }}
                                                        style={{
                                                            background: '#C7D300',
                                                            padding: 3,
                                                            height: 17,
                                                            width: 17,
                                                            marginRight: 2,
                                                            transition: 'all 0.2s ease-in-out',
                                                            textAlign: 'center',
                                                            fill: '#00374e',
                                                            cursor: 'pointer',
                                                            transform: 'rotate(45deg)',
                                                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                        }}
                                                    />
                                                </div>}

                                            <Autocomplete
                                                options={companyAddresses}
                                                getOptionLabel={(option) => {
                                                    return option.attributes.companyName + "\n" + option.attributes.zipcode + ' ' + option.attributes.city + "\n" + option.attributes.street + ' ' + option.attributes.houseNumber;
                                                }}
                                                // dataSourceConfig={{text: props.title, value: props.input}}
                                                // ref={autocompleteRef}
                                                name={''}
                                                className={'hideCursor text-input-label-blue editAddress'}
                                                multiple
                                                openOnFocus={false}
                                                value={selectedCompanyAddresses}
                                                onChange={(event, newValue) => {
                                                    let isValid = false;
                                                    let t = [];
                                                    newValue.map((item, index) => {
                                                        if (companyAddresses.includes(item)) {
                                                            t.push(item);
                                                        } else {
                                                            isValid = false;
                                                        }
                                                    })
                                                    if (Array.isArray(newValue) && newValue.length == 0) t = [];
                                                    setSelectedCompanyAddresses(t);
                                                }}
                                                inputValue={companyAddressInput}
                                                onInputChange={async (event, newInputValue) => {
                                                }}
                                                selectOnFocus
                                                clearOnBlur
                                                handleHomeEndKeys
                                                renderInput={(params) =>
                                                    <>
                                                        <CssTextField2
                                                            {...params}
                                                            // sx={{input: {color: 'white'}}}
                                                            label={'Arbeitsorte'}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            variant="outlined"
                                                            style={{
                                                                zIndex: 1,
                                                                width: '100%',
                                                                margin: '15px 0 10px 0',
                                                                padding: 0,
                                                            }}
                                                            error={!!selectedCompanyAddressesError}
                                                            helperText={selectedCompanyAddressesError ? selectedCompanyAddressesError : false}
                                                        />
                                                    </>
                                                }
                                            />
                                        </div>
                                        {editAddress && <EditAddress
                                            type={'edit'}
                                            isDefault={isDefault}
                                            setIsDefault={setIsDefault}
                                            createCompanyAddress={createCompanyAddress}
                                            companyName={companyName}
                                            setCompanyName={setCompanyName}
                                            companyNameError={companyNameError}
                                            setCompanyNameError={setCompanyNameError}
                                            street={street}
                                            setStreet={setStreet}
                                            streetError={streetError}
                                            setStreetError={setStreetError}
                                            nr={nr}
                                            setNr={setNr}
                                            nrError={nrError}
                                            setNrError={setNrError}
                                            zipcode={zipcode}
                                            setZipcode={setZipcode}
                                            zipcodeError={zipcodeError}
                                            setZipcodeError={setZipcodeError}
                                            city={city}
                                            setCity={setCity}
                                            cityError={cityError}
                                            setCityError={setCityError}
                                            phonenumber={phonenumber}
                                            setPhonenumber={setPhonenumber}
                                            phonenumberError={phonenumberError}
                                            setPhonenumberError={setPhonenumberError}
                                            email={email}
                                            setEmail={setEmail}
                                            emailError={emailError}
                                            setEmailError={setEmailError}
                                            website={website}
                                            setWebsite={setWebsite}
                                            websiteError={websiteError}
                                            setWebsiteError={setWebsiteError}
                                            setEditAddress={setEditAddress}
                                        />}

                                        {(selectedContacts && selectedContacts.length > 0) ? <>
                                            <div style={{ minHeight: 111, height: 200 }}>
                                                <ImageUploading
                                                    multiple
                                                    value={contactImage}
                                                    onChange={onContactImageChange}
                                                    maxNumber={1}
                                                    dataURLKey="data_url"
                                                    acceptType={["jpg", "png"]}
                                                >
                                                    {({
                                                          imageList,
                                                          onImageUpload,
                                                          onImageRemoveAll,
                                                          onImageUpdate,
                                                          onImageRemove,
                                                          isDragging,
                                                          dragProps
                                                      }) => (
                                                        <div className="upload__image-wrapper" style={{
                                                            width: '100%',
                                                            height: 200,
                                                            maxHeight: 200,
                                                            position: 'relative',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            zIndex: 2
                                                        }}>
                                                            {/*<button*/}
                                                            {/*    style={isDragging ? {*/}
                                                            {/*        color: "red",*/}
                                                            {/*        width: 80,*/}
                                                            {/*        marginRight: 10,*/}
                                                            {/*        padding: 0*/}
                                                            {/*    } : {width: 160, marginRight: 10, padding: 0}}*/}
                                                            {/*    onClick={onImageUpload}*/}
                                                            {/*    {...dragProps}*/}
                                                            {/*>*/}
                                                            {/*    Click or Drop here*/}
                                                            {/*</button>*/}
                                                            {/*<button onClick={onImageRemoveAll} style={{width: 160}}>Remove image</button>*/}
                                                            {(contactImageUpdate && (contactImageUpdate.length == 0 || contactImageUpdate.length > 0 && (!contactImageUpdate[0].file_id && !contactImageUpdate[0].data_url))) &&
                                                                <span style={{
                                                                    width: 'fit-content',
                                                                    height: '100%',
                                                                    position: 'relative'
                                                                }}>
                                                                            <LogoUser
                                                                                style={{
                                                                                    height: '100%',
                                                                                    width: 'auto',
                                                                                    cursor: 'pointer',
                                                                                    fill: '#C7D300',
                                                                                    maxHeight: 200,
                                                                                    position: 'relative'
                                                                                }}
                                                                                onClick={() => onImageUpload()}
                                                                            >
                                                                            </LogoUser>
                                                                            <span style={{
                                                                                position: 'absolute',
                                                                                bottom: '-5px',
                                                                                right: 0
                                                                            }}>
                                                                                <ClearIcon
                                                                                    className={'not-yellow-white'}
                                                                                    onClick={() => {
                                                                                        onImageUpload()
                                                                                    }}
                                                                                    style={{
                                                                                        height: 35,
                                                                                        width: 35,
                                                                                        marginRight: 2,
                                                                                        padding: 5,
                                                                                        transition: 'all 0.2s ease-in-out',
                                                                                        textAlign: 'center',
                                                                                        cursor: 'pointer',
                                                                                        fill: 'white',
                                                                                        background: '#00374a',
                                                                                        transform: 'rotate(45deg)',
                                                                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                                    }}
                                                                                />
                                                                            </span>
                                                                        </span>}
                                                            {(contactImageUpdate && (contactImageUpdate.length == 0 || contactImageUpdate.length > 0 && (!contactImageUpdate[0].file_id && !contactImageUpdate[0].data_url))) &&
                                                                <p onClick={() => onImageUpload()}
                                                                   className={'color-blue'} style={{
                                                                    position: 'absolute',
                                                                    cursor: 'pointer',
                                                                    background: 'transparent',
                                                                    fontFamily: 'VagRoundedBold',
                                                                    padding: '3px 5px 2px',
                                                                    borderRadius: 4,
                                                                    fontSize: 10,
                                                                    maxWidth: '160px',
                                                                    top: '46%',
                                                                }}>Bild Ansprechpartner hochladen (optional)</p>}
                                                            {contactImageUpdate && contactImageUpdate.length > 0 && contactImageUpdate.map((image, index) => {

                                                                if ((image.data_url || image.file_id)) {
                                                                    return (
                                                                        <div key={index} className="image-item" style={{
                                                                            width: '100%',
                                                                            position: 'relative',
                                                                            textAlign: 'center'
                                                                        }}>
                                                                            <img
                                                                                src={image ? image.data_url ? image.data_url : image.file_id ? ENV.REACT_APP_API_URL + '/file/' + image.file_id + '/unlogged/content' : null : null}
                                                                                style={{
                                                                                    maxHeight: 200,
                                                                                    maxWidth: '100%',
                                                                                    width: 'auto',
                                                                                    cursor: 'pointer'
                                                                                }} onClick={() => onImageUpdate(index)}
                                                                                alt=""
                                                                                width="100"/>
                                                                            {/*<div className="image-item__btn-wrapper">*/}
                                                                            {/*    <button style={{marginLeft: 0, width: 160, marginBottom: 10}}*/}
                                                                            {/*            onClick={() => onImageUpdate(index)}>Update*/}
                                                                            {/*    </button>*/}
                                                                            {/*    <button style={{marginLeft: 0, width: 160, marginBottom: 0}}*/}
                                                                            {/*            onClick={() => onImageRemove(index)}>Remove*/}
                                                                            {/*    </button>*/}
                                                                            {/*</div>*/}
                                                                        </div>
                                                                    )
                                                                }
                                                            })}
                                                            {(contactImageUpdate.length > 0 && (contactImageUpdate[0].file_id || contactImageUpdate[0].data_url)) &&
                                                                <p style={{
                                                                    position: 'absolute',
                                                                    bottom: '0',
                                                                }}>
                                                        <span onClick={() => onImageUpdate(0)} style={{
                                                            color: 'white',
                                                            cursor: 'pointer',
                                                            background: '#00374e',
                                                            padding: '2px 5px',
                                                            borderRadius: 4,
                                                            fontSize: 10,
                                                        }}>Bild Ansprechpartner ändern</span>
                                                                    {contactImageUpdate && <span style={{
                                                                        position: 'relative',
                                                                        marginLeft: 2,
                                                                    }}
                                                                                                 onClick={() => {
                                                                                                     updateContact(contactImageUpdate, selectedContacts[0].id, true);
                                                                                                 }}
                                                                    >
                                                        <ClearIcon
                                                            className={'not-yellow'}
                                                            onClick={() => {
                                                                //setGetRef(true);
                                                                //setNewAddress(true);
                                                            }}
                                                            style={{
                                                                position: 'absolute',
                                                                bottom: 0,
                                                                background: '#00374e',
                                                                borderRadius: 4,
                                                                padding: 1,
                                                                height: 12,
                                                                width: 12,
                                                                marginRight: 2,
                                                                transition: 'all 0.2s ease-in-out',
                                                                textAlign: 'center',
                                                                fill: 'red',
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </span>}
                                                                </p>}


                                                        </div>
                                                    )}
                                                </ImageUploading>
                                            </div>
                                        </> : null}

                                        <div style={{ position: 'relative' }}>
                                            {!editContact &&
                                                <div style={{ position: 'absolute', right: 15, top: 25, zIndex: 10 }}>
                                                    <ClearIcon
                                                        className={'not-yellow'}
                                                        onClick={() => {
                                                            setEditContact(true);
                                                        }}
                                                        style={{
                                                            background: '#C7D300',
                                                            padding: 3,
                                                            height: 17,
                                                            width: 17,
                                                            marginRight: 2,
                                                            transition: 'all 0.2s ease-in-out',
                                                            textAlign: 'center',
                                                            fill: '#00374e',
                                                            cursor: 'pointer',
                                                            transform: 'rotate(45deg)',
                                                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                        }}
                                                    />
                                                </div>}
                                            <Autocomplete
                                                options={contacts}
                                                getOptionLabel={(option) => {
                                                    return option.attributes.anrede + " " + option.attributes.vorname + " " + option.attributes.nachname;
                                                }}
                                                // dataSourceConfig={{text: props.option, value: props.id}}
                                                // ref={autocompleteRef}
                                                name={''}
                                                className={'hideCursor text-input-label-blue editContact'}
                                                multiple
                                                openOnFocus={false}
                                                value={selectedContacts}
                                                onChange={(event, newValue) => {
                                                    let isValid = false;
                                                    let t = [];
                                                    newValue.map((item, index) => {
                                                        if (contacts.includes(item)) {
                                                            t.push(item);
                                                        } else {
                                                            isValid = false;
                                                        }
                                                    })
                                                    if (Array.isArray(newValue) && newValue.length == 0) t = [];
                                                    if (Array.isArray(newValue) && newValue.length > 1) t = [t[0]];
                                                    setSelectedContacts(t);
                                                    setContactImageUpdate([{ file_id: t[0].attributes.file_id }])
                                                }}
                                                inputValue={contactInput}
                                                onInputChange={async (event, newInputValue) => {
                                                    // if (newInputValue == undefined || newInputValue == 'undefined') {
                                                    //     newInputValue = '';
                                                    // }
                                                    // setContactInput(newInputValue);
                                                }}
                                                // open={questions[index].selected.autocompleteInput.length > 2}
                                                // className={'text-input-label-white ' + (isFocused === ('answer-' + index + '-' + childIndex) || (questions[index].selected.autocomplete && questions[index].selected.autocomplete.length > 0) ? '' : 'borderWhite')}
                                                // onFocus={() => {
                                                //     setIsFocused(('answer-' + index + '-' + childIndex))
                                                // }}
                                                // onBlur={() => {
                                                //     setIsFocused(null)
                                                // }}
                                                selectOnFocus
                                                clearOnBlur
                                                handleHomeEndKeys
                                                renderInput={(params) =>
                                                    <>
                                                        <CssTextField2
                                                            {...params}
                                                            // sx={{input: {color: 'white'}}}
                                                            label={'Ansprechpartner (optional)'}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            variant="outlined"
                                                            style={{
                                                                zIndex: 1,
                                                                width: '100%',
                                                                margin: '15px 0 10px 0',
                                                                padding: 0,
                                                            }}
                                                            error={!!selectedContactsError}
                                                            helperText={selectedContactsError ? selectedContactsError : false}
                                                        />
                                                    </>
                                                }
                                            />
                                        </div>
                                        {editContact && <EditContact
                                            type={'edit'}
                                            isDefaultContact={isDefaultContact}
                                            setIsDefaultContact={setIsDefaultContact}
                                            anrede={anrede}
                                            setAnrede={setAnrede}
                                            anredeError={anredeError}
                                            setAnredeError={setAnredeError}
                                            vorname={vorname}
                                            setVorname={setVorname}
                                            vornameError={vornameError}
                                            setVornameError={setVornameError}
                                            nachname={nachname}
                                            setNachname={setNachname}
                                            nachnameError={nachnameError}
                                            setNachnameError={setNachnameError}
                                            telefon={telefon}
                                            setTelefon={setTelefon}
                                            telefonError={telefonError}
                                            setTelefonError={setTelefonError}
                                            emailContact={emailContact}
                                            setEmailContact={setEmailContact}
                                            emailContactError={emailContactError}
                                            setEmailContactError={setEmailContactError}
                                            contactImage={contactImage}
                                            setContactImage={setContactImage}
                                            contactImageError={contactImageError}
                                            setContactImageError={setContactImageError}
                                            createContact={createContact}
                                            setEditContact={setEditContact}
                                        />}

                                        <Typography sx={{ mt: 2, mb: 1 }}>
                                            <button className={'fadeInOut'}
                                                    onMouseOver={() => setButtonHover('vorschauButtonHover')}
                                                    onMouseOut={() => setButtonHover('')} style={{
                                                width: '100%',
                                                fontFamily: 'VagRoundedBold',
                                                //background: logoutButtonHover ? '#00374e' : '#C7D300',
                                                background: buttonHover == 'vorschauButtonHover' ? '#00374e' : 'lightgrey',
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                padding: '10px 10px 10px 10px',
                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                color: buttonHover == 'vorschauButtonHover' ? '#C7D300' : '#00374e',
                                                border: '2px solid ' + (buttonHover == 'vorschauButtonHover' ? '#00374e' : 'lightgrey'),
                                            }} onClick={() => {
                                                setVorschau(true);
                                            }}>Vorschau
                                            </button>
                                        </Typography>
                                        <Typography sx={{ mt: 2, mb: 1 }}>
                                            <button className={'fadeInOut'}
                                                    onMouseOver={() => setButtonHover('draftButtonHover')}
                                                    onMouseOut={() => setButtonHover('')} style={{
                                                width: '100%',
                                                fontFamily: 'VagRoundedBold',
                                                //background: logoutButtonHover ? '#00374e' : '#C7D300',
                                                background: buttonHover == 'draftButtonHover' && !draftButtonDone ? '#00374e' : 'lightgrey',
                                                cursor: draftButtonDone ? 'auto' : 'pointer',
                                                textAlign: 'center',
                                                padding: '10px 10px 10px 10px',
                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                color: buttonHover == 'draftButtonHover' && !draftButtonDone ? '#C7D300' : '#00374e',
                                                border: '2px solid ' + (buttonHover == 'draftButtonHover' && !draftButtonDone ? '#00374e' : 'lightgrey'),
                                                disabled: draftButtonDone,
                                                position: 'relative',
                                            }} onClick={() => {
                                                setSaveAsDraft(true)
                                                checkMustHave()
                                            }}>{(draftButtonDone) ? 'Entwurf gespeichert!' : 'Als Entwurf speichern'}
                                                {/*{*/}
                                                {/*    (draftButtonDone) ?*/}
                                                {/*<CheckCircleOutline*/}
                                                {/*    style={{*/}
                                                {/*        position: 'absolute',*/}
                                                {/*        top: 3,*/}
                                                {/*        left: 60,*/}
                                                {/*        marginTop: 0,*/}
                                                {/*        width: 30,*/}
                                                {/*        height: 30,*/}
                                                {/*        padding: 0*/}
                                                {/*    }}*/}
                                                {/*/> : <></>*/}
                                                {/*}*/}
                                            </button>
                                        </Typography>
                                        <Typography sx={{ mt: 2, mb: 1 }}>
                                            {/*All steps completed - you&apos;re finished*/}
                                            <button className={'fadeInOut'}
                                                    onMouseOver={() => setButtonHover('newStellenanzeigeButtonHover')}
                                                    onMouseOut={() => setButtonHover('')} style={{
                                                width: '100%',
                                                fontFamily: 'VagRoundedBold',
                                                //background: logoutButtonHover ? '#00374e' : '#C7D300',
                                                background: buttonHover == 'newStellenanzeigeButtonHover' ? '#00374e' : '#C7D300',
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                padding: '10px 10px 10px 10px',
                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                color: buttonHover == 'newStellenanzeigeButtonHover' ? '#C7D300' : '#00374e',
                                                border: '2px solid ' + (buttonHover == 'newStellenanzeigeButtonHover' ? '#00374e' : '#C7D300'),
                                            }} onClick={() => {
                                                //history.push('/neue-stellenanzeige');
                                                // if (type === 'update') {
                                                //     updateStellenanzeige();
                                                // } else {
                                                //     createStellenanzeige();
                                                // }
                                                validate(props.id);
                                            }}>{(type === 'update' && !isDraft) ? 'ANZEIGE AKTUALISIEREN' : 'ANZEIGE FREISCHALTEN'}
                                            </button>
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>

                        </Grid></React.Fragment>}


                </React.Fragment>
            )}
            <Modal
                open={vorschau}
                onClose={() => setVorschau(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={vorschauStyle}>
                    <Vorschau title={title}
                              subTitle={subTitle}
                              slogan={slogan}
                              m_w_d={gendering}
                              employmentTypes={jobTypes?.selected?.answer?.join(' • ')}
                              description={description}
                              imageDescription={imageDescription}
                              editorText={editorText}
                              companyImage={userImage ? userImage[0] : null}
                              teaserImage={teaserImage ? teaserImage[0] : null}

                              selectedCompanyAddresses={selectedCompanyAddresses}

                              selectedContacts={selectedContacts}

                              badges={badges}


                    />
                </Box>
            </Modal>
            <Modal
                open={onDelete}
                onClose={() => setOnDelete(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={deleteStyle}>
                    <h3 style={{ color: '#C7D300', fontSize: 20 }}>Stellenanzeige löschen</h3>

                    <p style={{ color: 'white', fontSize: 12 }}>Warum möchtest du diese Stellenanzeige löschen?</p>
                    <FormGroup>
                        <FormControlLabel
                            style={{ marginLeft: 0 }}
                            control={
                                <Radio
                                    sx={{
                                        p: '4px 9px',
                                        color: '#C7D300',
                                        '&.Mui-checked': {
                                            color: '#C7D300',
                                        },
                                    }}
                                    onClick={() => {
                                        if (selectedReason === 0) {
                                            // setSelectedReason(false);
                                        } else {
                                            setSelectedReason(0);
                                        }
                                    }}
                                    checked={selectedReason === 0}
                                />
                            }
                            label={<Typography style={{
                                fontSize: 12,
                                color: 'white',
                                fontFamily: 'VagRoundedBold'
                            }}>Stelle über swiitcher besetzt</Typography>}
                            sx={{
                                '& .MuiSvgIcon-root': { fontSize: 12 },
                                '& .MuiTypography-root': { marginLeft: '10px' }
                            }}
                        />
                        <FormControlLabel
                            style={{ marginLeft: 0 }}
                            control={
                                <Radio
                                    sx={{
                                        p: '4px 9px',
                                        color: '#C7D300',
                                        '&.Mui-checked': {
                                            color: '#C7D300',
                                        },
                                    }}
                                    onClick={() => {
                                        if (selectedReason === 1) {
                                            // setSelectedReason(false);
                                        } else {
                                            setSelectedReason(1);
                                        }
                                    }}
                                    checked={selectedReason === 1}
                                />
                            }
                            label={<Typography style={{
                                fontSize: 12,
                                color: 'white',
                                fontFamily: 'VagRoundedBold'
                            }}>Stelle anderweitig besetzt</Typography>}
                            sx={{
                                '& .MuiSvgIcon-root': { fontSize: 12 },
                                '& .MuiTypography-root': { marginLeft: '10px' }
                            }}
                        />
                        <FormControlLabel
                            style={{ marginLeft: 0 }}
                            control={
                                <Radio
                                    sx={{
                                        p: '4px 9px',
                                        color: '#C7D300',
                                        '&.Mui-checked': {
                                            color: '#C7D300',
                                        },
                                    }}
                                    onClick={() => {
                                        if (selectedReason === 2) {
                                            // setSelectedReason(false);
                                        } else {
                                            setSelectedReason(2);
                                        }
                                    }}
                                    checked={selectedReason === 2}
                                />
                            }
                            label={<Typography style={{
                                fontSize: 12,
                                color: 'white',
                                fontFamily: 'VagRoundedBold'
                            }}>Ohne Grund löschen</Typography>}
                            sx={{
                                '& .MuiSvgIcon-root': { fontSize: 12 },
                                '& .MuiTypography-root': { marginLeft: '10px' }
                            }}
                        />
                    </FormGroup>
                    <Typography sx={{ mt: 2, mb: 1 }}>

                        <button className={'fadeInOut'}
                                onMouseOver={() => setButtonHover('doDeleteButtonHover')}
                                onMouseOut={() => setButtonHover('')} style={{
                            width: '100%',
                            fontFamily: 'VagRoundedBold',
                            //background: logoutButtonHover ? '#00374e' : '#C7D300',
                            background: buttonHover == 'doDeleteButtonHover' ? '#00374e' : '#C7D300',
                            cursor: 'pointer',
                            textAlign: 'center',
                            padding: '5px 10px',
                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                            color: buttonHover == 'doDeleteButtonHover' ? '#C7D300' : '#00374e',
                            border: '2px solid ' + (buttonHover == 'doDeleteButtonHover' ? '#C7D300' : '#C7D300'),
                        }} onClick={() => {
                            deleteStellenanzeige();
                        }}>Löschen
                        </button>
                    </Typography>

                    <p style={{ fontSize: 10, color: 'white', marginTop: 10 }}>
                        <span style={{ fontFamily: 'VagRoundedBold' }}>Wusstest du schon?</span><br/>
                        Beim Löschen deiner Stellenanzeigen lösen sich alle von dir angefragten Matches automatisch auf.
                        Somit entstehen keine Kosten.
                    </p>
                </Box>
            </Modal>
        </Box>
    );
}
