import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import './App.css';
import { createTheme, Grid, IconButton, Slider } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import MenuAppBar from "./MenuAppBar";
import { BrowserRouter as Router, Link, Redirect, Route, Switch, } from "react-router-dom";
import Home from "./components/Home";
import { toast, ToastContainer } from "react-toastify";
import moment from 'moment';
import 'moment/locale/de';
import ScrollToTop from "./components/ScrollToTop";
import { ReactComponent as TikTokSvg } from './images/svg/tiktok.svg';
import { ReactComponent as FacebookSvg } from './images/svg/facebook.svg';
import { ReactComponent as InstagramSvg } from './images/svg/instagram.svg';
import useWindowDimensions from "./components/useWindowDimensions";
import AnonymSwiitchen from "./components/AnonymSwiitchen";
import Jobsuche from "./components/Jobsuche";
import { ReactComponent as LogoSvg } from './images/svg/swiitcher_logo.svg';
import { ReactComponent as LogoSvgWhite } from './images/svg/swiitcher_logo_weiss.svg';
import Arbeitgeber from "./components/Arbeitgeber";
import { isMobile } from 'react-device-detect';
import { ReactSession } from 'react-client-session';
import ScrollToTopOnPageLoad from "./components/ScrollToTopOnPageLoad";
import Chat from "./components/Chat";
import Übersicht from "./components/Übersicht";
import UeberUns from "./components/UeberUns";
import Matches from "./components/Matches";
import Stellenanzeigen from "./components/Stellenanzeigen";
import { ReactComponent as LogoSvgArbeitgeber1 } from "./images/svg/swiitcher_icon_blau.svg";
import { CssTextField3 } from "./components/CssTextField3";
import { styled } from "@mui/styles";
import BouncingDotsLoader from "./components/BouncingDotsLoader";
import debounce from 'lodash.debounce';
import NeueStellenanzeige from "./components/NeueStellenanzeige";
import MatchesArbeitgeber from "./components/MatchesArbeitgeber";
import Verify from "./components/Verify";
import Registrierung from "./components/Registrierung";
import Unternehmensprofil from "./components/Unternehmensprofil";
import Privacy from "./components/Privacy";
import Impressum from "./components/Impressum";
import useFetch from "./components/UseFetch";
import AGB from "./components/AGB";
import Matchkorb from "./components/Matchkorb";
import { ReactComponent as Einkaufswagen } from "./images/svg/einkaufswagen.svg";
import { CustomButton } from "./components/CustomButton";
import Einstellungen from "./components/Einstellungen";
import Rechnungen from "./components/Rechnungen";
import LoginAndRegistration from './components/newComponents/LoginAndRegistration/LoginAndRegistration';
import AgOffer from './components/newComponents/AgOffer/AgOffer'
import { useHistory } from "react-router-dom";

// ? Params for user coming from AN-Mobile app
const urlParams = new URLSearchParams(window.location.search);
const isFromMobileApp = urlParams.get('mobile') === 'true'
const mobileUser = urlParams.get('user') !== '' ? JSON.parse(urlParams.get('user')) : null

console.log('Mobile Params', isFromMobileApp, mobileUser)
if (isFromMobileApp && mobileUser && mobileUser.attributes) {
    localStorage.setItem('__react_session__',
        JSON.stringify(
            {
                menuState: false,
                userId: mobileUser.id,
                login: mobileUser.attributes.email,
                token: mobileUser.token,
                user: mobileUser.attributes.userType
            })
    )
} else if (isFromMobileApp) {
    localStorage.removeItem('__react_session__')
}
const theme = createTheme({
    palette: {
        primary: {
            main: '#00374e',
        },
        secondary: {
            main: '#C7D300',
        },
    },
});

const PrettoSlider = styled(Slider)({
    color: 'white !important',
    height: 5,
    width: '100%',
    padding: 0,
    marginTop: 5,
    marginBottom: 20,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 16,
        width: 16,
        backgroundColor: '#C7D300',
        border: '2px solid #C7D300',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
        '&:after': {
            height: 16,
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#52af77',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

moment.updateLocale('de', {
    relativeTime: {
        future: "in %s",
        past: "vor %s",
        s: function (number, withoutSuffix, key, isFuture) {
            return number + ' Sekunden';
        },
        m: "einer Minute",
        mm: function (number, withoutSuffix, key, isFuture) {
            return number + ' Minuten';
        },
        h: "einer Stunde",
        hh: "%d Stunden",
        d: "einem Tag",
        dd: "%d Tagen",
        M: "einem Monat",
        MM: "%d Monaten",
        y: "einem Jahr",
        yy: "%d jahren"
    }
});

function App() {

    let getElementInterval = null;

    // const history = useHistory();
    const { height, width } = useWindowDimensions();
    const menuAppBarRef = useRef();
    const menuAppBarRefNew = useRef();
    const api = useFetch();
    const [facebookButtonHover, setFacebookButtonHover] = useState(false);
    const [instagramButtonHover, setInstagramButtonHover] = useState(false);
    const [tiktokButtonHover, setTiktokButtonHover] = useState(false);
    const [menuState, setMenuState] = useState(false);
    const [location, setLocation] = useState('/');
    const [login, setLogin] = useState(null);

    const [test, setTest] = useState(false);
    const [stickyButtonClick, setStickyButtonClick] = useState(false);
    const [stickyButtonClickHover, setStickyButtonClickHover] = useState(false);
    const [logoutButtonHover, setLogoutButtonHover] = useState(false);
    const [activeMenuItem, setActiveMenuitem] = useState('Übersicht');
    const [hoverMenuItem, setHoverMenuItem] = useState(null);
    const [menuItems, setMenuItems] = useState([]);
    const [overAllCount, setOverallCount] = useState(0);
    const [history, setHistory] = useState(null);

    const [currentRange, setCurrentRange] = useState(30);
    const [results, setResults] = useState(0);
    const [loading, setLoading] = useState(false);
    const [goBack, setGoBack] = useState(false);

    const [jobs, setJobs] = useState('');
    const [plz, setPlz] = useState('');
    const [isOpen, setIsOpen] = useState('');
    const [confirmedMatches, setConfirmedMatches] = useState(0);
    const [unreadMatches, setUnreadMatches] = useState(0);
    const [unreadChats, setUnreadChats] = useState(0);
    const [menuItemsArbeitnehmer, setMenuItemsArbeitnehmer] = useState([
        { text: 'Übersicht', url: 'übersicht', count: 0 },
        { text: 'Matches', url: 'matches', count: 0 },
        { text: 'Chats & Nachrichten', url: 'chat', count: 0 },
        { text: 'Einstellungen', url: 'einstellungen', count: 0 }
    ]);

    const [menuItemsArbeitgeber, setMenuItemsArbeitgeber] = useState([
        { text: 'Übersicht', url: 'übersicht', count: 0 },
        { text: 'Matches', url: 'matches-arbeitgeber', count: 0 },
        { text: 'Chats & Nachrichten', url: 'chat', count: 0 },
        { text: 'Stellenanzeigen', url: 'stellenanzeigen', count: 0 },
        { text: 'Unternehmensprofil', url: 'unternehmensprofil', count: 0 },
        { text: 'Rechnungen', url: 'rechnungen', count: 0 },
        { text: 'Einstellungen', url: 'einstellungen', count: 0 }
    ]);
    const logout = () => {
        ReactSession.set('description', undefined);
        ReactSession.set('temp-auth-key', undefined);
        ReactSession.set('user', undefined);
        ReactSession.set('token', undefined);
        ReactSession.set('login', undefined);
        ReactSession.set('menuState', true);
        localStorage.removeItem('matchStore')
        localStorage.removeItem('lastChat')
        update(null);

        setTimeout(() => {
            window.location.replace('https://www.swiitcher.de/')
        }, 200)
    }

    const loginNeeded = (type = false) => {
        if (type === 'Arbeitgeber') {
            notify('Dieser Bereich ist nur für Arbeitgeber', true);
        } else if (type === 'Arbeitnehmer') {
            notify('Dieser Bereich ist nur für Arbeitnehmer', true);
        } else {
            setGoBack(true);
            notify('Du musst dich vorher einloggen', true);
        }
        return true;
    }

    useImperativeHandle(menuAppBarRefNew, () => ({
        logNeeded(login = false) {
            return loginNeeded(login);
        },
    }));

    // ? Special Conditions
    // ? params for typo3
    const iFrameActive = urlParams.get('iframe') === 'true'
    // ? conditions for new Login
    const isNewLoginLocation = window.location.pathname.toLowerCase().includes('login')
    // Todo: Conditions
    // ? dont show appBar if questions-view
    const isQuestionsView = window.location.pathname.toLowerCase().includes('anonym-swiitchen')
    const isNewPricingView = window.location.pathname.toLowerCase().includes('pricing')

    if (!window.location.pathname.includes('anonym-swiitchen') && ReactSession.get('user') === 'Arbeitnehmer') {
        // ? Arbeitnehmer test with old AG part -> comment out login
        logout()
    }
    if (isNewPricingView){
        ReactSession.set('menuState', false);
    }


    //TRACKING CODE FOR SEO MATOMO
    var _paq = window._paq = window._paq || [];
    React.useEffect(() => {
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
        (function() {
            var u="//matomo.swiitcher.erkoware.cloud/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);

            // ? Dev siteId below
            // _paq.push(['setSiteId', '3']);

            // ? Prod siteId below
            _paq.push(['setSiteId', '1']);

            // ? env-usage once its implemented in devops
            // _paq.push(['setSiteId', ENV.MATOMO_SITE_ID]);


            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
        })();

    }, [])

    // ? Matomo question-unload-event-trigger
    React.useEffect(() => {
            var _mtm = window._mtm = window._mtm || [];
            _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
            (function() {
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];

            // ? Dev container below
            // g.async=true; g.src='https://matomo.swiitcher.erkoware.cloud/js/container_7Z43gKm3.js'; s.parentNode.insertBefore(g,s);

            // ? Prod container below
            g.async=true; g.src='https://matomo.swiitcher.erkoware.cloud/js/container_yjtIDcKd.js'; s.parentNode.insertBefore(g,s);
            // g.async=true; g.src=ENV.MATOMO_CONTAINER; s.parentNode.insertBefore(g,s);

        })();
    }, [])


    useEffect(() => {
        if (stickyButtonClick) setTimeout(() => setStickyButtonClickHover(true), 100)
        setTest(true);
        setTimeout(() => setTest(false), 200)
    }, [stickyButtonClick])


    const stopTimer = () => {
        clearInterval(getElementInterval);
    }

    // const getUserCentricsElement = () => {
    //     const userCentrics = document.querySelector('#usercentrics-root');
    //     if (userCentrics) {
    //         const searchModuleRoot = userCentrics && userCentrics.shadowRoot;
    //         const button = searchModuleRoot.querySelector('button');
    //         if (button) {
    //             button.style.bottom = '10px';
    //             button.style.left = '10px';
    //             stopTimer();
    //         }
    //     }
    // }

    const flatActive = () => {
        const contingent = ReactSession.get('jobAdvertisementContingent')
        return contingent && contingent.flatDuration && moment(contingent.flatDuration).isValid() && moment(contingent.flatDuration) > moment()
    }
    useEffect(() => {
        getMatchesCount();
        const interval = setInterval(() => {
            getMatchesCount();
        }, 5000);

        // getElementInterval = setInterval(() => {
        //     getUserCentricsElement();
        // }, 1000);


        // setupCookieBanner();


        return () => {
            if (interval) clearInterval(interval);
            // if (getElementInterval) clearInterval(getElementInterval);
        }
    }, [])


//     const setupCookieBanner = async () => {
//         const UC = new Usercentrics('4RwaIbkznG7fiS');
//         const initialUIValues = await UC.init();
//
// // getSettingsCore() and getSettingsLabels() returns all Usercentrics settings you need for your custom solution
//         const settings = UC.getSettingsCore();
//         console.log('settings');
//         console.log(settings);
//         const labels = UC.getSettingsLabels();
//         console.log('labels');
//         console.log(labels);
//
//         if (initialUIValues.variant === UI_VARIANT.DEFAULT) {
//             switch (initialUIValues.initialLayer) {
//                 case UI_LAYER.FIRST_LAYER:
//                     // Show first layer (i.e. privacy banner)
//                     return;
//                 case UI_LAYER.PRIVACY_BUTTON:
//                     // Show privacy button
//                     return;
//                 case UI_LAYER.NONE:
//                 default:
//                     // Show nothing
//                     return;
//             }
//         }
//     }

    useEffect(() => {
        if (menuItems.filter(i => location.pathname.includes(i.url)).length > 0) {
            setActiveMenuitem(menuItems.filter(i => location.pathname.includes(i.url))[0].text);
        }
    }, [location])


    const getMatchesCount = async () => {
        if (ReactSession.get('user')) {
            let url = ENV.REACT_APP_API_URL + '/matchings/count';

            //if (this.state.sort) url += '&sort=' + this.state.sort;

            let { response, data } = await api(url)


            if (!response || response.status == 401) {
                ReactSession.set('token', undefined);
                ReactSession.set('refreshToken', undefined);
                return;
            }

            let sel = [];
            // console.log(data);
            if (data && data.data) {
                // console.log(data.data);
                setUnreadMatches(data.data.matching_count);
                setConfirmedMatches(data.data.confirmed_matching_count);
                setUnreadChats(data.data.chat_count);
                setOverallCount((data.data.matching_count + data.data.chat_count))
            }
            let uM = data.data.matching_count;
            let uC = data.data.chat_count;
            // console.log(uC);
            // console.log(login);
            if (ReactSession.get('user') == 'Arbeitnehmer') {
                let t = [...menuItemsArbeitnehmer];
                let temp = t.filter(i => i.text === 'Matches')[0];
                let index = t.indexOf(temp);
                temp.count = parseInt(uM);
                t[index] = temp;
                temp = t.filter(i => i.text === 'Chats & Nachrichten')[0];
                index = t.indexOf(temp);
                temp.count = parseInt(uC);
                t[index] = temp;
                setMenuItemsArbeitnehmer(t);
            } else if (ReactSession.get('user') == 'Arbeitgeber') {
                let t = [...menuItemsArbeitgeber];
                let temp = t.filter(i => i.text === 'Matches')[0];
                let index = t.indexOf(temp);
                temp.count = parseInt(uM);
                t[index] = temp;
                temp = t.filter(i => i.text === 'Chats & Nachrichten')[0];
                index = t.indexOf(temp);
                temp.count = parseInt(uC);
                t[index] = temp;
                setMenuItemsArbeitgeber(t);
            }
        }
    }

    useEffect(() => {
        if (unreadMatches > 0) {
            // console.log(unreadMatches);
            //setOverallCount(unreadMatches);
        }
    }, [unreadMatches])


    useEffect(() => {
        if (ReactSession.get('user')) {
            // console.log(ReactSession.get('user'));
            setLogin(ReactSession.get('user'));
        }

        if (ReactSession.get('menuState') && ReactSession.get('user')) {
            setMenuState(ReactSession.get('menuState'));
        }
    }, [])

    useEffect(() => {


    }, [unreadMatches, unreadChats])


    useEffect(() => {
        const temp = ReactSession.get('user') == 'Arbeitnehmer' ? menuItemsArbeitnehmer : menuItemsArbeitgeber;
        if (menuItems != temp) {
            // getMatchesCount();

            //console.log(menuItemsArbeitnehmer);
            setMenuItems(temp);
        }

    }, [login, menuItemsArbeitnehmer, menuItemsArbeitgeber])

    useEffect(() => {
        if (menuItems) {
            let count = 0;
            Object.keys(menuItems).map((key, index) => {
                if (menuItems[key].count) count += menuItems[key].count;
            })

            if (count > 0) {
                setOverallCount(count);
            }
        }
    }, [menuItems, activeMenuItem])

    useEffect(() => {
        ReactSession.set('menuState', menuState);
    }, [menuState])

    const handleMouseIn = (type) => {
        switch (type) {
            case 'facebookButton':
                setFacebookButtonHover(true);
                break;
            case 'instagramButton':
                setInstagramButtonHover(true);
                break;
            case 'tiktokButton':
                setTiktokButtonHover(true);
                break;
            case 'logoutButton':
                setLogoutButtonHover(true);
                break;
        }
    };

    const handleMouseOut = (type) => {
        switch (type) {
            case 'facebookButton':
                setFacebookButtonHover(false);
                break;
            case 'instagramButton':
                setInstagramButtonHover(false);
                break;
            case 'tiktokButton':
                setTiktokButtonHover(false);
                break;
            case 'logoutButton':
                setLogoutButtonHover(false);
                break;
        }
    };

    const locationChange = (location, token = null) => {
        location.token = token;
        setLocation(location);
        // console.log(location.pathname);
        // console.log(menuItems);
        // console.log(menuItems.filter(i => location.pathname.includes(i.url)));
        if (menuItems.filter(i => location.pathname.includes(i.url)).length > 0) {
            // console.log(menuItems.filter(i => location.pathname.includes(i.url))[0].text);
            setActiveMenuitem(menuItems.filter(i => location.pathname.includes(i.url))[0].text);
        }
    }

    const changeMenuState = () => {
        if (!menuState) {
            // console.log('menu open')
        } else {
            // console.log('menu close')
        }
        if (ReactSession.get('user')) setMenuState(!menuState);
    }

    const notify = (message, error) => {
        // console.log('NOTIFY')

        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: { marginTop: 80 }
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: { marginTop: 80 }
            });
        }

    }

    const update = (login) => {
        setLogin(login);
        if (!window.location.pathname.includes('pricing')){
            setMenuState(!menuState);
        }
    }

    const getHistory = (history) => {
        setHistory(history);

    }

    // useEffect(() => {
    //     const timeOutId = setTimeout(() => getResults(), 2000);
    //     return () => clearTimeout(timeOutId);
    // }, [jobs, plz, currentRange]);

    const getResults = (jobs, plz, currentRange) => {
        setLoading(true);
        let temp = parseInt((currentRange * 13 / 10 + (jobs && jobs.length * 13) + (plz && plz.length * 12)).toString());
        setTimeout(() => {
            setResults(temp + parseInt((temp * 0.3).toString()));
            setLoading(false);
        }, 1000)
    }

    const debouncedChangeHandler = useMemo(
        () => debounce(getResults, 750)
        , []);


    return (
        <React.Fragment>
            {(!isMobile || isMobile && ReactSession.get('user') !== 'Arbeitgeber') ?
                <Router basename={ENV.REACT_APP_BASE_URL}>
                    <ToastContainer style={{ margin: '0 auto', width: 'auto' }}/>
                    <ThemeProvider theme={theme}>
                        <ScrollToTopOnPageLoad/>
                        <div className="App"
                             style={{
                                 background: location.pathname == '/arbeitgeber' ? '#00374e' : '#FFFFFF',
                                 lineHeight: 1.4
                             }}>

                            <header className={'fadeInOut'} style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                zIndex: 999,
                                boxShadow: '0 2px 24px 0 rgb(0 0 0 / 15%)',
                                paddingTop: 0
                            }}>
                                <MenuAppBar logiin={login}
                                            getHistory={getHistory}
                                            overAllCount={overAllCount}
                                            menuState={menuState}
                                            userT={login}
                                            ref={menuAppBarRef}
                                            update={update}
                                            notify={notify}
                                            changeMenuState={changeMenuState}
                                            location={location}
                                            locationChange={locationChange}
                                            LogoSvgWhite={LogoSvgWhite}
                                />
                            </header>

                            <React.Fragment>
                                {ReactSession.get('user') ? <div className={'fadeInOut'} style={{
                                    zIndex: 4,
                                    visibility: menuState ? 'visible' : 'hidden',
                                    position: 'fixed',
                                    top: 64,
                                    right: menuState ? 0 : isMobile ? 0 : -210,
                                    width: 210,
                                    height: '100%',
                                    backgroundColor: ReactSession.get('user') == 'Arbeitgeber' ? '#00374e' : '#FFFFFF',
                                    padding: '100px 0 0 10px',
                                    boxSizing: 'border-box',
                                    boxShadow: 'rgb(0 0 0 / 25%) 5px 5px 5px 7px',
                                }}>
                                    {menuItems && Object.keys(menuItems).map((key, index) => {
                                        let item = menuItems[key];
                                        return (<div key={index} style={{ position: 'relative' }}>
                                            {(!flatActive() && confirmedMatches && confirmedMatches > 0 && item.text == 'Matches') ?
                                                <span style={{ position: 'absolute', top: 2, right: 10, zIndex: 999 }}>
                                            <CustomButton
                                                id={'matchkorb'}
                                                style={{
                                                    position: 'relative',
                                                    borderRadius: 50,
                                                    minWidth: 'unset',
                                                    padding: 0,
                                                }}
                                                onClick={() => {
                                                    if (confirmedMatches && confirmedMatches > 0) history.push('/matchkorb');
                                                }}
                                            >
                                                <Einkaufswagen
                                                    style={{
                                                        width: 30,
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                                {(confirmedMatches && confirmedMatches > 0) &&
                                                    <div className={'count'} style={{
                                                        background: '#C7D300',
                                                        filter: 'unset',
                                                        width: 8,
                                                        minWidth: 8,
                                                        height: 8,
                                                        fontSize: 12,
                                                        top: -11,
                                                        right: -5,
                                                        color: '#00374e',
                                                        padding: '4px',
                                                        fontFamily: 'VagRoundedBold'
                                                    }}>{confirmedMatches}
                                                    </div>}
                                            </CustomButton>
                                        </span> : null}
                                            <p
                                                key={index}
                                                style={{
                                                    textAlign: 'left',
                                                    padding: (item.count > 0 ? '5px 0 4px 15px' : '7px 0 4px 15px'),
                                                    position: 'relative',
                                                    zIndex: 1
                                                }}
                                                // className={'menuItemMain ' + ((activeMenuItem == item.text) ? 'active ' : '')}
                                                className={'menuItemMain ' + ((activeMenuItem == item.text) ? 'active ' : '')}
                                                onMouseOver={() => setHoverMenuItem(item.text)}
                                                onMouseOut={() => setHoverMenuItem(null)}
                                                onClick={() => {
                                                    //setActiveMenuitem(item.text)
                                                    if (item.url) history.push('/' + item.url);
                                                    else history.push('/');
                                                }}
                                            >
                                                {/* , color: activeMenuItem == item.text ? login == 'Arbeitgeber' ? '#C7D300' : '#C7D300' : ''*/}
                                                <span style={{ fontFamily: (item.count > 0 ? 'VagRoundedBold' : '') }}
                                                      key={index}
                                                      className={'menuItem ' + (ReactSession.get('user')) + (hoverMenuItem == item.text ? ' hover ' : ' ')}
                                                >
                                            {item.unterpunkt ? ("- " + item.text) : item.text}
                                                    {item.count > 0 && <span className={'count'}>{item.count}</span>}

                                        </span>
                                            </p></div>)
                                    })}

                                    <div style={{ textAlign: 'left', marginTop: 10, marginLeft: 13 }}>
                                        <button className={'fadeInOut'}
                                                onMouseOver={() => handleMouseIn('logoutButton')}
                                                onMouseOut={() => handleMouseOut('logoutButton')} style={{
                                            fontFamily: 'VagRoundedBold',
                                            //background: logoutButtonHover ? '#00374e' : '#C7D300',
                                            background: logoutButtonHover ? ReactSession.get('user') == 'Arbeitnehmer' ? 'white' : '#00374e' : ReactSession.get('user') == 'Arbeitnehmer' ? '#00374e' : '#C7D300',
                                            cursor: 'pointer',
                                            textAlign: 'center',
                                            padding: '6px 10px 6px 10px',
                                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                            color: logoutButtonHover ? ReactSession.get('user') == 'Arbeitnehmer' ? '#C7D300' : '#C7D300' : ReactSession.get('user') == 'Arbeitnehmer' ? 'white' : '#00374e',
                                            border: '2px solid ' + (logoutButtonHover ? '#C7D300' : '#00374e'),
                                        }} onClick={() => {
                                            logout()
                                        }}>ausloggen
                                        </button>
                                    </div>
                                </div> : null}
                            </React.Fragment>
                            <div className={'fadeInOut subApp'} onClick={() => {
                                if (isMobile && menuState) {
                                    setMenuState(false);
                                }
                            }}
                                 style={{
                                     marginRight: menuState ? isMobile ? 0 : 210 : 0,
                                     position: 'relative',
                                     overflow: 'hidden'
                                 }}>
                                {/*     /!* A <Switch> looks through its children <Route>s and*/}
                                {/*renders the first one that matches the current URL. *!/*/}

                                {!iFrameActive && <ScrollToTop/>}
                                {/*<TransitionGroup>*/}
                                {/*<CSSTransition*/}
                                {/*    timeout={300}*/}
                                {/*    classNames='fade'*/}
                                {/*    key={location.key}*/}
                                {/*>*/}
                                <React.Fragment>
                                    <Switch>
                                        <Route exact path="/">
                                            {(ReactSession.get('user') !== "Arbeitgeber") && (ReactSession.get('user') !== "Arbeitnehmer") ?
                                                <Redirect to="/login"/> : <Redirect to="/übersicht"/>}
                                        </Route>
                                        {/*<Route path="/">*/}
                                        {/*    <Home theme={theme} locationChange={locationChange}/>*/}
                                        {/*</Route>*/}
                                        <Route path="/Login/:token?">
                                            <LoginAndRegistration
                                                notify={notify}
                                                update={update}
                                                theme={theme}
                                                loginRef={menuAppBarRef}
                                                location={location}
                                                locationChange={locationChange}
                                                getHistory={getHistory}
                                                goBackRoot={goBack}
                                            />
                                        </Route>

                                        <Route path="/pricing">
                                            <AgOffer loginRef={menuAppBarRefNew} theme={theme}
                                                     locationChange={locationChange}/>
                                        </Route>

                                        <Route path="/datenschutzerklärung">
                                            <Privacy/>
                                        </Route>

                                        <Route path="/impressum">
                                            <Impressum/>
                                        </Route>

                                        <Route path="/agb">
                                            <AGB/>
                                        </Route>

                                        <Route path="/ueber-uns">
                                            <UeberUns/>
                                        </Route>

                                        <Route path="/verify/:token">
                                            <Verify/>
                                        </Route>

                                        <Route path="/übersicht">
                                            <Übersicht unreadChats={unreadChats} menuAppBarRef={menuAppBarRefNew} login={login} theme={theme}
                                                       locationChange={locationChange}/>
                                        </Route>

                                        <Route path="/matchkorb">
                                            <Matchkorb menuAppBarRef={menuAppBarRefNew} theme={theme}
                                                       locationChange={locationChange}/>
                                        </Route>

                                        <Route path="/matches-arbeitgeber">
                                            <MatchesArbeitgeber confirmedMatchesCount={confirmedMatches} menuAppBarRef={menuAppBarRefNew} theme={theme}
                                                                locationChange={locationChange}/>
                                        </Route>

                                        <Route path="/matches">
                                            <Matches menuAppBarRef={menuAppBarRefNew} theme={theme}
                                                     setMenuState={setMenuState}
                                                     locationChange={locationChange}/>
                                        </Route>

                                        <Route path="/einstellungen">
                                            <Einstellungen menuAppBarRef={menuAppBarRef} theme={theme}
                                                           locationChange={locationChange}/>
                                        </Route>

                                        <Route path="/rechnungen">
                                            <Rechnungen menuAppBarRef={menuAppBarRefNew} theme={theme}
                                                        locationChange={locationChange}/>
                                        </Route>

                                        <Route path="/unternehmensprofil">
                                            <Unternehmensprofil menuAppBarRef={menuAppBarRefNew} theme={theme}
                                                                locationChange={locationChange}/>
                                        </Route>

                                        <Route path="/neue-stellenanzeige">
                                            <NeueStellenanzeige menuAppBarRef={menuAppBarRefNew} theme={theme}
                                                                locationChange={locationChange}/>
                                        </Route>

                                        <Route path="/stellenanzeigen">
                                            <Stellenanzeigen menuAppBarRef={menuAppBarRefNew} theme={theme}
                                                             locationChange={locationChange}/>
                                        </Route>

                                        <Route path="/stellenanzeige/:id/update">
                                            <NeueStellenanzeige menuAppBarRef={menuAppBarRefNew} type={'update'}
                                                                theme={theme}
                                                                locationChange={locationChange}/>
                                        </Route>

                                        <Route path="/jobsuche/:id">
                                            <Jobsuche theme={theme} locationChange={locationChange}/>
                                        </Route>

                                        <Route path="/jobsuche">
                                            <Jobsuche theme={theme} locationChange={locationChange}/>
                                        </Route>

                                        <Route path="/anonym-swiitchen">
                                            <AnonymSwiitchen menuAppBarRef={menuAppBarRef} login={login}
                                                             loginRef={menuAppBarRef} theme={theme}
                                                             locationChange={locationChange}/>
                                        </Route>

                                        <Route path="/registrierung">
                                            <Registrierung loginRef={menuAppBarRef} theme={theme}
                                                           locationChange={locationChange}/>
                                        </Route>

                                        <Route path="/arbeitgeber">
                                            <Arbeitgeber loginRef={menuAppBarRef} theme={theme}
                                                         locationChange={locationChange}/>
                                        </Route>

                                        <Route path="/ueber-uns">
                                            <UeberUns loginRef={menuAppBarRef} theme={theme}
                                                      locationChange={locationChange}/>
                                        </Route>

                                        <Route path="/chat">
                                            <Chat menuAppBarRef={menuAppBarRefNew} login={login} unreadChats={unreadChats}
                                                  theme={theme}
                                                  locationChange={locationChange}/>
                                        </Route>

                                        <Route path="/passwort-reset/:token">
                                            <Home theme={theme} locationChange={locationChange}/>
                                        </Route>

                                        {/*/!* Main entry page *!/*/}


                                    </Switch>
                                    {/* Todo: here is the footer */}

                                    <div style={{
                                        position: 'fixed',
                                        display: 'none',
                                        left: 0,
                                        // top: stickyButtonClick ? 'calc(100vh - 400px)' : 'calc(100vh - 90px)',
                                        // top: 'calc(100vh - 500px)',
                                        bottom: 0,
                                        width: stickyButtonClick ? 280 : 0,
                                        // height: stickyButtonClick ? 400 : 90,
                                        height: 430,
                                        background: stickyButtonClick ? 'rgba(0, 55, 78, 0.95)' : '',
                                        borderRadius: 2,
                                        transition: 'all 0.2s ease-in-out',
                                        boxShadow: stickyButtonClick ? '0px 0px 3px rgba(0, 55, 78, 0.95)' : '',
                                        paddingTop: 10,
                                        padding: 0,
                                        margin: 0,
                                        zIndex: 999,
                                    }}>
                                        <div style={{
                                            // display: stickyButtonClick ? 'block' : 'none',
                                            // zIndex: stickyButtonClick ? 1 : 9999,
                                            zIndex: 1,
                                            //scale: stickyButtonClickHover ? 'scaleX(1)' : 'scaleX(0)',
                                            padding: '0 20px 20px',
                                            transition: 'all 0.2s ease-in-out',
                                            opacity: stickyButtonClick ? 1 : 0,
                                            position: 'relative',
                                            height: '100%',
                                            width: stickyButtonClick ? 'calc(100% - 40px)' : 0,
                                            overflow: 'hidden',
                                        }}>
                                            <p style={{
                                                textAlign: 'left',
                                                color: 'white',
                                                fontFamily: 'VagRoundedBold',
                                                marginBottom: 20
                                            }}>Filter</p>
                                            <CssTextField3
                                                sx={{ input: { color: 'black' } }}
                                                label="Jobs, Unternehmen, Keywords"
                                                // placeholder={'Jobs, Unternehmen, Keywords'}
                                                // InputLabelProps={{
                                                //     shrink: true
                                                // }}
                                                style={{
                                                    opacity: stickyButtonClick ? 1 : 0,
                                                    transition: 'all 0.2s ease',
                                                    margin: '0 0 8px 0',
                                                    minHeight: 38,
                                                    width: '100%'
                                                }}
                                                value={jobs}
                                                // onChange={e => {
                                                //     // if (e.target.value.trim().length === 0) {
                                                //     //     setUsernameError(true);
                                                //     // } else {
                                                //     //     setUsernameError(false);
                                                //     // }
                                                //     // setUsername(e.target.value);
                                                //     setJobs(e.target.value);
                                                //     setLoading(true);
                                                // }}
                                                onChange={(e) => {
                                                    setJobs(e.target.value)
                                                    debouncedChangeHandler(e.target.value, plz, currentRange);
                                                }}
                                                // onKeyPress={handleKeypress}
                                                // value={username}
                                                // error={!!usernameError}
                                                // helperText={usernameError === true ? 'Username darf nicht leer sein!' : usernameError}
                                            />
                                            <p style={{
                                                textAlign: 'left',
                                                color: 'white',
                                                marginTop: 5,
                                                marginBottom: 0,
                                                fontSize: 12
                                            }}>Umkreis</p>

                                            <PrettoSlider
                                                size={'small'}
                                                defaultValue={30}
                                                step={10}
                                                min={10}
                                                value={currentRange}
                                                onChange={(event, newValue) => {

                                                    if (newValue !== currentRange) {
                                                        setCurrentRange(newValue);
                                                        debouncedChangeHandler(jobs, plz, newValue);
                                                        //getResults(newValue);
                                                    }

                                                }}
                                            />
                                            <div style={{ position: 'relative' }}>
                                                <CssTextField3
                                                    sx={{ input: { color: 'black' } }}
                                                    autoComplete="new-password"
                                                    label="PLZ oder Ort"
                                                    // InputLabelProps={{
                                                    //     shrink: true
                                                    // }}
                                                    style={{
                                                        opacity: stickyButtonClick ? 1 : 0,
                                                        transition: 'all 0.2s ease',
                                                        margin: '0 0 8px 0',
                                                        minHeight: 38,
                                                        width: '100%'
                                                    }}
                                                    value={plz}
                                                    // onChange={e => {
                                                    //     // if (e.target.value.trim().length === 0) {
                                                    //     //     setUsernameError(true);
                                                    //     // } else {
                                                    //     //     setUsernameError(false);
                                                    //     // }
                                                    //     // setUsername(e.target.value);
                                                    //     setPlz(e.target.value);
                                                    //     setLoading(true);
                                                    // }}
                                                    onChange={(e) => {
                                                        setPlz(e.target.value)
                                                        debouncedChangeHandler(jobs, e.target.value, currentRange);
                                                    }}
                                                    // onKeyPress={handleKeypress}
                                                    // value={username}
                                                    // error={!!usernameError}
                                                    // helperText={usernameError === true ? 'Username darf nicht leer sein!' : usernameError}
                                                />
                                                <div style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 1,
                                                    width: 40.13,
                                                    height: 40.13,
                                                    borderRadius: 25,
                                                    background: '#C7D300',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    lineHeight: '12px'
                                                }}>
                                                    <p style={{
                                                        position: 'absolute',
                                                        marginTop: 10,
                                                        color: 'white'
                                                    }}>{currentRange}</p>
                                                    <p style={{
                                                        position: 'absolute',
                                                        fontSize: 10,
                                                        marginTop: 25,
                                                        color: 'white'
                                                    }}>km</p>
                                                </div>
                                                {/*<div id={'detailsBlock'} style={{*/}
                                                {/*    fontSize: 14,*/}
                                                {/*    opacity: stickyButtonClick ? 1 : 0,*/}
                                                {/*    transition: 'all 0.2s ease',*/}
                                                {/*    overflow: 'hidden',*/}
                                                {/*    whiteSpace: 'nowrap'*/}
                                                {/*}}>*/}
                                                {/*    <details key={isOpen + 1} open={isOpen == 'Neueste Jobs'}*/}
                                                {/*             onClick={() => {*/}
                                                {/*                 if (isOpen == 'Neueste Jobs') setIsOpen('');*/}
                                                {/*                 else setIsOpen('Neueste Jobs')*/}
                                                {/*             }}>*/}
                                                {/*        <summary style={{cursor: 'pointer'}}>Neueste Jobs</summary>*/}
                                                {/*        <ul>*/}
                                                {/*            <li>Text1</li>*/}
                                                {/*            <li>Text2</li>*/}
                                                {/*            <li>Text3</li>*/}
                                                {/*            <li>Text4</li>*/}
                                                {/*            <li>Text5</li>*/}
                                                {/*        </ul>*/}
                                                {/*    </details>*/}
                                                {/*    <details key={isOpen + 2} open={isOpen == 'Arbeitszeit'}*/}
                                                {/*             onClick={() => {*/}
                                                {/*                 if (isOpen == 'Arbeitszeit') setIsOpen('');*/}
                                                {/*                 else setIsOpen('Arbeitszeit')*/}
                                                {/*             }}>*/}
                                                {/*        <summary style={{cursor: 'pointer'}}>Arbeitszeit</summary>*/}
                                                {/*        <ul>*/}
                                                {/*            <li>Vollzeit<span className={'number'}>34</span></li>*/}
                                                {/*            <li>Teilzeit</li>*/}
                                                {/*            <li>Minijob</li>*/}
                                                {/*            <li>Sonstiges</li>*/}
                                                {/*            <li>Schichtarbeit</li>*/}
                                                {/*        </ul>*/}
                                                {/*    </details>*/}
                                                {/*    <details key={isOpen + 3} open={isOpen == 'Anzeigeart'}*/}
                                                {/*             onClick={() => {*/}
                                                {/*                 if (isOpen == 'Anzeigeart') setIsOpen('');*/}
                                                {/*                 else setIsOpen('Anzeigeart')*/}
                                                {/*             }}>*/}
                                                {/*        <summary style={{cursor: 'pointer'}}>Anzeigeart</summary>*/}
                                                {/*        <ul>*/}
                                                {/*            <li>Text1</li>*/}
                                                {/*            <li>Text2</li>*/}
                                                {/*            <li>Text3</li>*/}
                                                {/*            <li>Text4</li>*/}
                                                {/*            <li>Text5</li>*/}
                                                {/*        </ul>*/}
                                                {/*    </details>*/}
                                                {/*    <details key={isOpen + 4} open={isOpen == 'Branche'}*/}
                                                {/*             onClick={() => {*/}
                                                {/*                 if (isOpen == 'Branche') setIsOpen('');*/}
                                                {/*                 else setIsOpen('Branche')*/}
                                                {/*             }}>*/}
                                                {/*        <summary style={{cursor: 'pointer'}}>Branche</summary>*/}
                                                {/*        <ul>*/}
                                                {/*            <li>Text1</li>*/}
                                                {/*            <li>Text2</li>*/}
                                                {/*            <li>Text3</li>*/}
                                                {/*            <li>Text4</li>*/}
                                                {/*            <li>Text5</li>*/}
                                                {/*        </ul>*/}
                                                {/*    </details>*/}
                                                {/*</div>*/}
                                            </div>

                                            <div style={{
                                                position: 'absolute',
                                                // opacity: stickyButtonClick ? 1 : 0,
                                                textAlign: 'right',
                                                width: (results > 0 || loading) ? 175 : 0,
                                                zIndex: 999999,
                                                overflow: 'hidden',
                                                transition: 'all 0.2s ease-in-out',
                                                bottom: 87.5,
                                                left: 75.5,
                                                // display: stickyButtonClick ? 'block' : 'none',
                                                // background: '#C7D300',
                                                backgroundImage: 'radial-gradient(circle at 1.5px 19px, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 26.5px, rgb(199, 211, 0) 26.5px)',
                                                borderRadius: 25,
                                                height: 20,
                                                padding: '7px 10px 10px',
                                                fontFamily: 'VagRoundedBold',
                                                color: '#00374e',
                                                whiteSpace: 'nowrap',
                                            }}>
                                                    <span style={{

                                                        display: (results > 0 && !loading) ? 'block' : 'none',
                                                        opacity: (results > 0 && !loading) ? 1 : 0,
                                                        paddingRight: 5
                                                    }}>{results} Jobs gefunden</span>
                                                {loading && <BouncingDotsLoader/>}
                                            </div>

                                        </div>
                                        <div style={{
                                            position: 'fixed',
                                            display: 'none',
                                            left: 36,
                                            bottom: 50,
                                            zIndex: 1,
                                        }}>
                                            <div id={'logoArbeitgeber1'} style={{
                                                borderRadius: 50,
                                                width: 64,
                                                height: 64,
                                                minWidth: 64,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: 'flex',
                                                // border: stickyButtonClick ? '4px solid rgba(0, 55, 78, 0)' : '4px solid rgba(0, 55, 78, 1)',
                                                border: stickyButtonClick ? '4px solid transparent' : '4px solid transparent',
                                                cursor: 'pointer',
                                                transitionDuration: '0.2s',
                                                transitionProperty: 'transform',
                                                transform: test ? stickyButtonClick ? 'rotate(90deg)' : 'rotate(-90deg)' : 'unset'
                                            }}
                                                 onClick={() => {
                                                     if (!stickyButtonClick) {
                                                         setStickyButtonClick(!stickyButtonClick);
                                                     } else {
                                                         setLoading(false);
                                                         setResults(0);
                                                         setJobs('');
                                                         setPlz('');
                                                         setCurrentRange(30);
                                                         setStickyButtonClickHover(false)
                                                         setTimeout(() => setStickyButtonClick(false), 100)
                                                     }


                                                 }}>
                                                {<div style={{
                                                    padding: 7.5,
                                                    background: 'rgba(0, 55, 78, 1)',
                                                    borderRadius: 50,
                                                    width: 57,
                                                    minWidth: 27,
                                                    height: 39.04,
                                                    minHeight: 35,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    border: '5px solid rgba(199, 211, 0, 1)'
                                                }}>
                                                    <LogoSvgArbeitgeber1
                                                        style={{
                                                            width: 30,
                                                        }}
                                                        className={stickyButtonClick ? 'stickyButtonClick' : ''}
                                                    />
                                                </div>}
                                            </div>
                                        </div>

                                    </div>
                                </React.Fragment>
                                {/*</CSSTransition>*/}
                                {/*</TransitionGroup>*/}
                            </div>
                        </div>

                    </ThemeProvider>
                </Router> : <React.Fragment>
                    <div style={{ marginTop: 100, textAlign: 'center' }}>
                        <LogoSvg
                            style={{
                                width: 350,
                                marginBottom: 50
                            }}
                            id={'logoFooter'}
                        />
                        <h1>Work in progress.</h1>
                        <p style={{ margin: 'auto', width: 300 }}>Nur als Desktop-Version verfügbar, bis die Optimierungen
                            für
                            mobile Endgeräte abgeschlossen sind.</p>
                        {ReactSession.get('user') &&
                            <button className={'fadeInOut'} onMouseOver={() => handleMouseIn('logoutButton')}
                                    onMouseOut={() => handleMouseOut('logoutButton')} style={{
                                fontFamily: 'VagRoundedBold',
                                marginTop: 10,
                                //background: logoutButtonHover ? '#00374e' : '#C7D300',
                                background: logoutButtonHover ? ReactSession.get('user') == 'Arbeitnehmer' ? 'white' : '#00374e' : ReactSession.get('user') == 'Arbeitnehmer' ? '#00374e' : '#C7D300',
                                cursor: 'pointer',
                                textAlign: 'center',
                                padding: '6px 10px 6px 10px',
                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                color: logoutButtonHover ? ReactSession.get('user') == 'Arbeitnehmer' ? '#C7D300' : '#C7D300' : ReactSession.get('user') == 'Arbeitnehmer' ? 'white' : '#00374e',
                                border: '2px solid ' + (logoutButtonHover ? '#00374e' : '#C7D300'),
                            }} onClick={() => {
                                ReactSession.set('user', undefined);
                                ReactSession.set('token', undefined);
                                ReactSession.set('login', undefined);
                                ReactSession.set('menuState', false);
                                notify('Du wurdest erfolgreich ausgeloggt');
                            }}>ausloggen
                            </button>}
                    </div>
                </React.Fragment>}
        </React.Fragment>
    );
}

export default App
