import React, { useEffect, useRef } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {
    Autocomplete,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Slider,
    Tooltip
} from "@mui/material";
import { ReactComponent as LogoSvgDenker } from "../images/svg/Illustration_TTVerlag_Denker_Flaeche.svg";
import { ReactComponent as LogoSvgEntdecker } from "../images/svg/Illustration_TTVerlag_Entdecker_Flaeche.svg";
import { ReactComponent as LogoSvgHelfer } from "../images/svg/Illustration_TTVerlag_Helfer_Flaeche.svg";
import { ReactComponent as LogoSvgDiplomat } from "../images/svg/Illustration_TTVerlag_Diplomat_Flaeche.svg";
import { useState } from "react";
import { CssTextField2 } from "./CssTextField2";
import InfoIcon from "@mui/icons-material/Info";
import ErrorIcon from '@mui/icons-material/Error';
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { makeStyles, styled } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";
import { useLocation } from "react-router";
import { ReactSession } from "react-client-session";
import useFetch from "./UseFetch";
import { Select2 } from "./Select2";
import { useMemo } from "react";
import debounce from "lodash.debounce";
import { ReactComponent as LogoSvgB } from "../images/svg/B.svg";
import { ReactComponent as LogoSvgC } from "../images/svg/C.svg";
import { ReactComponent as LogoSvgD } from "../images/svg/D.svg";
import { ReactComponent as LogoSvgL } from "../images/svg/L.svg";
import { ReactComponent as LogoSvgFL } from "../images/svg/FL.svg";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { isMobile } from "react-device-detect";
import { CssTextField3 } from "./CssTextField3";
import isNumeric from "validator/es/lib/isNumeric";
import GoogleMapReact from "google-map-react";

const PrettoSlider = styled(Slider)({
    color: '#C7D300',
    height: 3,
    width: 'calc(100% - 30px)',
    padding: 0,
    marginTop: 5,
    marginBottom: 5,
    '& .MuiSlider-rail': {
        color: 'white'
    },
    '& .MuiSlider-track': {
        border: 'none',
        color: '#C7D300'
    },
    '& .MuiSlider-thumb': {
        height: 14,
        width: 14,
        backgroundColor: 'white',
        border: '2px solid #C7D300',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
        '&:after': {
            height: 16,
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#52af77',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

const useStyles = makeStyles({
    select: {
        "& ul.MuiList-padding": {
            padding: 0
        },
        "& li": {
            color: '#00374e',
        }
    },
});

Array.prototype.unique = function () {
    let a = this.concat();
    for (let i = 0; i < a.length; ++i) {
        for (let j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
};

const BpIcon = styled('span')(({ theme }) => ({
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    borderRadius: 25,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#C7D300',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%2300374e'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#C7D300',
    },
});

function BpCheckbox(props) {
    return (
        <Checkbox
            sx={{
                '&:hover': { bgcolor: 'transparent' },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon/>}
            icon={<BpIcon/>}
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            {...props}
        />
    );
}

export default function Fragen(props) {
    const testRef = useRef([]);
    const api = useFetch();
    const classes = useStyles();
    const {
        activeStep,
        selected,
        getAnswers,
        getQuestions,
        answeredQuestions,
        selectedCompanyAddresses,
        jobTypes,
        setJobTypes,
        selectedJobTypes,
        setSelectedJobTypes
    } = props;

    const [buttonHover, setButtonHover] = useState(null);

    const [activeQuestion, setActiveQuestion] = useState(1);
    const [questions, setQuestions] = useState([]);
    const [questionsTemp, setQuestionsTemp] = useState([]);
    const [onChangeQuestion, setOnChangeQuestion] = useState(false);
    const [showSubQuestion, setShowSubQuestion] = useState([]);
    const [isFocused, setIsFocused] = useState(null);
    const [category, setCategory] = useState(null);
    const [answers, setAnswers] = React.useState([]);
    const [autocompleteLoading, setAutocompleteLoading] = useState(false);
    const [multiSelectOpen, setMultiSelectOpen] = useState(false);
    const multiSelectOpenRef = useRef();
    const [keyDown, setKeyDown] = useState(false);
    const [currentRange, setCurrentRange] = useState(30);
    const [autocomplete, setAutocomplete] = useState([]);
    const [autocompleteInput, setAutocompleteInput] = useState('');
    const [selectedAutocomplete, setSelectedAutocomplete] = useState('');
    const [test, setTest] = useState(false);

    const [errors, setErrors] = React.useState([]);
    const [mapCircles, setMapCircles] = React.useState([]);
    const [mapCirclesGoogle, setMapCirclesGoogle] = React.useState([]);
    const [currentMap, setCurrentMap] = useState(null);
    const [currentMaps, setCurrentMaps] = useState(null);
    const handleKeypressEnter = e => {
        //it triggers by pressing the enter key
        if (e.charCode === 13) {
            // getResultsZip();
        }
    };
    const validateQuestions = () => {
        let temp = [];
        answers.map((item, index) => {
            console.log(item);
            if ((item.answer && item.answer.length === 0 && (!item.autocomplete || item.autocomplete.length === 0)) || (item.answer && item.answer.length > 0 && item.subAnswer && item.subAnswer.length === 0) || (item.answer && item.answer.length > 0 && item.autocomplete && item.autocomplete.length === 0) || (item.answer && item.answer[0] && item.answer[0].answer == 'Studium' && item.autocomplete[0] && !item.autocomplete[0].dropdown)) {
                temp.push(item.id);
            }
        })
        console.log(temp);
        setErrors(temp);
        return temp;
    }

    props.fragenRef.validateQuestions = validateQuestions;

    const getZipcodes = async (url, newInputValue) => {
        let t = [];
        if (isNumeric(newInputValue)) {
            url += '?filter%5Bzipcode%5D%5Beq%5D=' + newInputValue;
        } else {
            url += '?filter%5Bname%5D%5Blike%5D=' + encodeURIComponent(newInputValue);
        }

        console.log(url);


        let { response, data } = await api(url);
        console.log(data);

        if (data && data.data) {
            data.data.map((item, index) => {
                if (t.filter(i => i.attributes.zipcode == item.attributes.zipcode && i.attributes.name == item.attributes.name).length == 0) t.push(item);
            })
            console.log(t);
            setAutocomplete([]);
            setAutocomplete(t);
            setAutocompleteLoading(false);
        }
        return t
    }

    const debouncedChangeHandlerZip = useMemo(
        () => debounce(getZipcodes, 400)
        , []);
    const checkQuestions = () => {

        if (questions && questions.length > 0) {
            let answers = [];

            questions.map((item, index) => {
                let temp = {}
                let test = [];
                temp.id = item.id;
                //temp.answer = Array.isArray(item.selected.answer) ? item.selected.answer : [item.selected.answer];
                if (item.selected && !Array.isArray(item.selected.answer)) {
                    let answerId = item.answers.filter(i => i.answer == item.selected.answer);
                    if (answerId) answerId = answerId[0].id;
                    test.push({ id: item.id, answer: item.selected.answer, answerId, isStrictFilter: answeredQuestions.find((ansquest) => ansquest.attributes.question_id == item.id && ansquest.attributes.answer_id == answerId)?.attributes.isStrictFilter })
                    temp.answer = test;
                }
                if (item.selected && Array.isArray(item.selected.answer)) {
                    item.selected.answer.map((answer, index) => {
                        // test.push(item.answer ?? item.input ?? item)

                        let answerId = item.answers.filter(i => ((i.answer && i.answer == answer.answer) || (i.answer && i.answer == answer) || (i.answer?.id && i.answer?.id == answer?.id) || (i.label && i.label == answer)));
                        let ans = answer;

                        if (
                            typeof ans === 'object' &&
                            !Array.isArray(ans) &&
                            ans !== null
                        ) {
                            answerId = item.answers.filter(i => ((i.answer && i.answer == answer.answer) || (i.answer && i.answer == answer) || (i.answer?.id && i.answer?.id == answer?.id) || (i.label && i.label == answer)));
                            ans = answer.answer;
                        }

                        // console.log(answerId);
                        // if (answerId) answerId = answerId[0].id;
                        if (answerId && answerId.length > 0) answerId = answerId[0].id;
                        else {
                            answerId = item.answers.filter(i => i.question_id == item.selected.id);
                            if (answerId && answerId.length == 1) answerId = answerId[0].id;
                        }
                        test.push({ id: item.id, answer: ans, answerId, isStrictFilter: answeredQuestions.find((ansquest) => ansquest.attributes.question_id == item.id && ansquest.attributes.answer_id == answerId)?.attributes.isStrictFilter })
                    })
                    temp.answer = test;
                }

                test = [];
                if (temp.answer && temp.answer.length > 0 && item.selected.autocomplete && item.selected.autocomplete.length > 0 && item.selected.autocomplete?.find(selectedAutoComplete => selectedAutoComplete.autocompleteType == "Zipcode")) {
                    item.selected.autocomplete.map((answer, index) => {
                        let answerId = item.answers.filter(i => i.type == 'autocomplete');
                        if (answerId) answerId = answerId[0].id;
                        const answerString = answer.answer ?? answer.input ?? answer.title
                        test.push({ id: item.id, answer: answerString, answerId, isStrictFilter: answeredQuestions.find((ansquest) => ansquest.attributes.question_id == item.id && ansquest.attributes.autocompleteText == answerString)?.attributes.isStrictFilter })
                    })
                    temp.autocomplete = test;
                }

                if (temp.answer && temp.answer.length == 0 && item.selected.autocomplete && item.selected.autocomplete.length > 0) {
                    //temp.answer = [item.selected.autocomplete[0].input];

                    item.selected.autocomplete.map((answer, index) => {
                        let answerId = item.answers.filter(i => i.type == 'autocomplete');
                        if (answerId) answerId = answerId[0].id;
                        const answerString = answer.answer ?? answer.input ?? answer.title
                        test.push({ id: item.id, answer: answerString, answerId, isStrictFilter: answeredQuestions.find((ansquest) => ansquest.attributes.question_id == item.id && ansquest.attributes.autocompleteText == answerString)?.attributes.isStrictFilter })
                    })
                    temp.autocomplete = test;
                }

                test = [];
                let t = null;
                if (temp.answer && temp.answer.length == 1) {
                    if (item.answers.filter(i => temp.answer[0].answer == i.answer && i.sub && i.sub.length > 0).length > 0) {
                        if (!item.selected.autocomplete || item.selected.autocomplete.length == 0) {
                            temp.subAnswer = [];
                            t = { id: item.id, answer: '', isStrictFilter: answeredQuestions.find((ansquest) => ansquest.attributes.question_id == item.id)?.attributes.isStrictFilter }
                        }
                        if (item.selected.subAnswer && item.selected.subAnswer.length > 0) {
                            temp.subAnswer = item.selected.subAnswer;
                            item.selected.subAnswer.map((answer, index) => {
                                let answerId = item.answers.filter(i => i.answer == answer || i.label == answer);
                                if (answerId) answerId = answerId[0].id;
                                test.push({ id: item.id, answer: answer, answerId, isStrictFilter: answeredQuestions.find((ansquest) => ansquest.attributes.question_id == item.id && ansquest.attributes.answer_id == answerId)?.attributes.isStrictFilter })
                                // t = {id: item.id, answer: answer.title}
                                // console.log(t);
                                // test.push(t)
                            })
                            temp.subAnswer = test;
                        }

                        test = [];
                        if (item.selected.autocomplete && item.selected.autocomplete.length > 0) {
                            //temp.answer = item.selected.autocomplete;
                            item.selected.autocomplete.map((answer, index) => {
                                let answerId = item.answers.filter(i => i.type == 'autocomplete');
                                if (answerId) answerId = answerId[0].id;
                                const answerString = answer.answer ?? answer.input ?? answer.title ?? answer
                                test.push({
                                    id: item.id,
                                    answer: answerString,
                                    answerId,
                                    dropdown: item.selected.dropdown, isStrictFilter: answeredQuestions.find((ansquest) => ansquest.attributes.question_id == item.id && ansquest.attributes.autocompleteText == answerString)?.attributes.isStrictFilter })
                                // t = {id: item.id, answer: answer.answer}
                                // test.push(t)
                            })
                            temp.autocomplete = test;
                        }
                    }
                }
                answers.push(temp);
            })
            let t = [];
            if (errors.length > 0) {
                answers.map((item, index) => {
                    if ((item.answer && item.answer.length === 0 && (!item.autocomplete || item.autocomplete.length === 0)) || (item.answer && item.answer.length > 0 && item.subAnswer && item.subAnswer.length === 0) || (item.answer && item.answer.length > 0 && item.autocomplete && item.autocomplete.length === 0)) {
                        t.push(item.id);
                    }
                })
            }
            setErrors(t);
            console.log(answers);
            setAnswers(answers);
            getAnswers(answers);
        }
        if (questions) {
            testRef.current = testRef.current.slice(0, questions.length);
        }
    }

    props.fragenRef.checkQuestions = checkQuestions;


    const autocompleteRef = useRef([]);
    const logoRef = useRef(null);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        disableScrollLock: true,
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                minHeight: 'unset',
            },
        },
        classes: { paper: classes.select }
    };

    const ignoreTypes = [
        '51', '52', '60'
    ];


    useEffect(() => {
        let rows = [];

        async function getData() {
            let url = ENV.REACT_APP_API_URL + '/questions/for/arbeitgeber?page%5Bsize%5D=999';
            console.log(url);
            //if (this.state.sort) url += '&sort=' + this.state.sort;

            let { response, data } = await api(url)


            if (!response || response.status == 401) {
                ReactSession.set('token', undefined);
                ReactSession.set('refreshToken', undefined);
                //history.push('/login');
                return;
            }
            console.log(data);
            let subquestions = [];

            if (data && data.data) {
                await data.data.map(async (questionItem, index1) => {


                    let id = questionItem.id;

                    let question = questionItem.question;
                    let helperText = questionItem.helperText;
                    let answers = questionItem.answers.sort((a, b) => a.sort - b.sort);
                    let type = questionItem.type;
                    let categoryT = questionItem.category;
                    let visibleFor = questionItem.visibleFor;
                    let canBeStrictFilter = !!Number(questionItem.canBeStrictFilter);

                    let topAnswers = [];
                    let subAnswers = [];
                    let selected = null;

                    console.log("ANSWERS", answers)
                    console.log("ANSWERED QUESTIONS", answeredQuestions)
                    answers.map((answerItem, index2) => {
                        if (answerItem.parent_id == null) {
                            answerItem.sub = answers.filter(i => i.parent_id == answerItem.id && questionItem.id != '58');

                            topAnswers.push(answerItem);


                            let an = [];
                            let au = [];
                            let dropdown = null;
                            answeredQuestions.filter(i => i.attributes.question_id == id && i.attributes.answerText != '').map((item, index) => {
                                console.log(id, id != 63 || id != 54 || (id == 54 && item.attributes.answerText && !item.attributes.autocompleteText))
                                if (id != 63 && (id != 54 || (id == 54 && item.attributes.answerText && !item.attributes.autocompleteText))) {
                                    an.push(item.attributes.answerText);
                                } else if (id == 63) {
                                    if (isNumeric(item.attributes.answerText)) setCurrentRange(Number(item.attributes.answerText))
                                    an.push(item.attributes.answerText);
                                } else {
                                    dropdown = item.attributes.answerText;
                                }
                            })
                            answeredQuestions.filter(i => i.attributes.question_id == id && i.attributes.autocompleteText != '').map(async (item, index) => {
                                if (id == 63) {
                                    au.push({
                                        id: item.id,
                                        title: item.attributes.autocompleteText,
                                        autocompleteType: 'Zipcode'
                                    });
                                    // setSelectedAutocomplete('AHAHAHAHAHAH')
                                    let url = ENV.REACT_APP_API_URL + '/zipcodes';
                                    const zipObject = await getZipcodes(url, item.attributes.autocompleteText)
                                    setSelectedAutocomplete(zipObject[0])
                                } else {
                                    au.push({ id: item.id, title: item.attributes.autocompleteText });
                                }
                            })


                            if (answerItem.sub.length === 0) {
                                selected = {
                                    id,
                                    'answer': an,
                                    autocomplete: au,
                                    autocompleteInput: '',
                                    dropdown
                                }
                            } else {
                                selected = {
                                    id,
                                    'answer': an,
                                    subAnswer: [],
                                    sub: true,
                                    autocomplete: au,
                                    autocompleteInput: '',
                                    dropdown
                                }
                            }
                            if (id == 56 && selected.autocomplete.length == 0) {
                                selected.autocomplete = [{ title: 'Deutsch', value: 'Deutsch' }, {
                                    title: 'Englisch',
                                    value: 'Englisch'
                                }];
                            }
                            if (id == 63 && selected.answer.length == 0) {
                                selected.answer = currentRange.toString()
                            }

                            if (id == 50 && selected.answer.length == 0) {
                                selected.answer = 'Denker'
                            }
                        } else {
                            answerItem.autocomplete = [];
                        }
                    })

                    let führerscheinLogos = {
                        B: LogoSvgB,
                        C: LogoSvgC,
                        D: LogoSvgD,
                        L: LogoSvgL,
                        FL: LogoSvgFL,
                    };


                    let logos = {
                        Denker: LogoSvgDenker,
                        Entdecker: LogoSvgEntdecker,
                        Helfer: LogoSvgHelfer,
                        Diplomat: LogoSvgDiplomat,
                    };

                    if (id == 59) {
                        console.log(selected);
                        props.setJobTypes({
                            id,
                            question,
                            helperText,
                            selected,
                            answers,
                            visibleFor,
                            category: categoryT,
                            führerscheinLogos,
                            logos,
                            type
                        })
                    }

                    rows.push({
                        id,
                        question,
                        helperText,
                        canBeStrictFilter,
                        selected,
                        answers,
                        visibleFor,
                        category: categoryT,
                        führerscheinLogos,
                        logos,
                        type
                    })

                })

            } else if (response.error) {
            }
            setShowSubQuestion(subquestions);
            setQuestionsTemp(rows);
            console.log("ROWS", rows);
        }

        window.addEventListener('scroll', handleScroll);
        getData();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    const getItemSize = (itemId) => {
        if(itemId == 63) return 7

        if(itemId == 59) return 5
        return 6
    }

    useEffect(() => {
        if (multiSelectOpen) multiSelectOpenRef.current = multiSelectOpen;
        else multiSelectOpenRef.current = null;
    }, [multiSelectOpen])

    const handleScroll = () => {
        if (multiSelectOpenRef && multiSelectOpenRef.current) {
            setMultiSelectOpen(false);
            multiSelectOpenRef.current = null;
        }
    }

    useEffect(() => {

        let temp = [];
        console.log(selected);
        if (questionsTemp) {
            let t = [];
            t = questionsTemp.filter(i => !ignoreTypes.includes(i.id));
            t.map((questionItem, questionIndex) => {
                questionItem.answers.map((answerItem, answerIndex) => {
                    if (questionItem.selected && questionItem.selected.answer && questionItem.selected.answer.includes(answerItem.answer)) {
                        let index = answers.indexOf(answers.filter(i => i.question_id == questionItem.id && i.answer == answerItem.answer)[0]);
                        console.log(index);
                        console.log(answers[index]);

                        temp.push('answer-' + questionIndex + '-' + answerIndex);
                    }
                })
            })


            setShowSubQuestion(temp);
            console.log("temp -> questions", t);
            setQuestions(t);
        }
    }, [questionsTemp])

    useEffect(() => {
        if (questions && questions[activeQuestion - 1] && questions[activeQuestion - 1].then && Object.keys(questions[activeQuestion - 1].then) > 0 && Object.keys(questions[activeQuestion - 1].then).filter(i => i.antwort !== '').length > 0) {
            console.log('ALARM')
            // 'question-' + index + '-' + childIndex
            let temp = [];
            Object.keys(questions[activeQuestion - 1].then).filter(i => i.antwort !== '').map((key, index) => {
                temp.push(('question-' + (activeQuestion - 1) + '-' + index));
            })
            setShowSubQuestion(temp);
        }

        //setShowSubQuestion(false)
        setTimeout(() => {
            setOnChangeQuestion(false);
        }, 300)
    }, [activeQuestion])

    useEffect(() => {
        checkQuestions();
        getQuestions(questions)
    }, [questions])

    useEffect(() => {
        async function fetchData() {
            const newCircles = []
            for (const adress of selectedCompanyAddresses) {
                //get coordinates and save to useState
                const url = ENV.REACT_APP_API_URL + '/zipcodes?filter%5Bzipcode%5D%5Beq%5D=' + adress.attributes.zipcode;

                console.log(url);


                let { response, data } = await api(url);

                if(data.data?.length > 0) {
                    console.log(data.data[0])
                    const tempCircle = {
                        lat: data.data[0].attributes.lat,
                        lng: data.data[0].attributes.long,

                    }
                    newCircles.push(tempCircle)

                }

                // if (data && data.data) {
                //     data.data.map((item, index) => {
                //         if (t.filter(i => i.attributes.zipcode == item.attributes.zipcode && i.attributes.name == item.attributes.name).length == 0) t.push(item);
                //     })
                //     console.log(t);
                //     setAutocomplete([]);
                //     setAutocomplete(t);
                //     setAutocompleteLoading(false);
                // }

            }
            setMapCircles(newCircles)
        }
        fetchData()
    }, [selectedCompanyAddresses])

    const apiIsLoaded = (map = currentMap, maps = currentMaps) => {
        if (!map || !maps) return


        for (const circle of mapCirclesGoogle) {
            circle.setMap(null)
        }


        const googleCircles = []
        for (const circle of mapCircles) {
            if(currentRange < 100) {
                googleCircles.push(new maps.Circle({
                    strokeColor: '#C7D300',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#C7D300',
                    fillOpacity: 0.3,
                    map,
                    center: { lat: parseFloat(circle.lat), lng: parseFloat(circle.lng) },
                    radius: currentRange * 1000,
                }))
            } else {
                googleCircles.push(new maps.Circle({
                    strokeColor: '#C7D300',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#C7D300',
                    fillOpacity: 0.3,
                    map,
                    center: { lat: parseFloat(circle.lat), lng: parseFloat(circle.lng) },
                    radius: 350 * 1000,
                }))
            }

            googleCircles.push(new maps.Marker({
                position: {lat: parseFloat(circle.lat), lng: parseFloat(circle.lng)},
                map,
                title: circle.title
            }))
        }
        setMapCirclesGoogle(googleCircles)



        map.disableDefaultUI = true
        // const test = new maps.LatLng(
        //     defaultProps.center.lat,
        //     defaultProps.center.lng,
        // )
        // //

        // test.zoom = 8
        // map.setCircle(circle)
        map.setOptions({disableDefaultUI:true})
        // // map.setCenter(test)
        // if(selectedAutocomplete) {
        //     if(currentRange < 20) {
        //         map.setZoom((isMobile) ? 10 : 10)
        //     } else if (currentRange < 40) {
        //         map.setZoom((isMobile) ? 8 : 9)
        //     } else if (currentRange < 70) {
        //         map.setZoom((isMobile) ? 7 : 8)
        //     } else if (currentRange < 100) {
        //         map.setZoom((isMobile) ? 6 : 7)
        //     } else {
        //         map.setZoom((isMobile) ? 4 : 4)
        //     }
        // }


        // Bind the resize listener
        // bindResizeListener(map, maps, bounds);
        setCurrentMap(map)
        setCurrentMaps(maps)
    };

    useEffect(() => {
        apiIsLoaded()
    }, [mapCircles, currentRange])

    const handleMouseIn = (type) => {
        setButtonHover(type)
    };

    const handleMouseOut = () => {
        setButtonHover(null)
    };

    const getResults = async (url, type, temp, index, childIndex, subIndex) => {
        console.log('GET RESULTS')
        let t = [];
        let { response, data } = await api(url);
        console.log(data);
        if (data && data.data) {
            data.data.map((item, index) => {
                t.push({
                    input: item.attributes.autocompleteData,
                    title: item.attributes.autocompleteData
                })
            })
        }
        console.log(type);
        if (type == 1) {
            temp[index].autocomplete = t;
        } else if (type == 2) {
            temp[index].answers[childIndex].sub[subIndex].autocomplete = t;
        }
        console.log(temp);
        setQuestions(temp);
        setAutocompleteLoading(false);
    }

    const debouncedChangeHandler = useMemo(
        () => debounce(getResults, 400)
        , []);

    return (
        <Grid container style={{
            padding: 0,
        }}>
            <Grid item xs={12} style={{
                // display: 'flex',
                // justifyContent: 'center',
                // alignItems: 'center',
                height: activeStep != 1 ? 'auto' : '100%',
                minHeight: activeStep != 1 ? 'auto' : '800px',
                opacity: activeStep === 1 ? 1 : 0,
                background: '#00374e',
                borderRadius: 25,
                paddingBottom: 40,
                boxShadow: 'inset 0px 2px 4px -1px rgb(0 0 0 / 20%), inset 0px 4px 5px 0px rgb(0 0 0 / 14%), inset 0px 1px 10px 0px rgb(0 0 0 / 12%)'
            }}>
                {questions && <Grid container style={{
                    padding: 0,
                    height: activeStep == 1 ? 'auto' : 200,
                    visibility: activeStep == 1 ? 'visible' : 'collapse',
                    // display: activeStep == 1 ? 'block' : 'none',
                }}>
                    <Grid item id="questionContainerRef" xs={12} style={{
                        // display: 'flex',
                        // justifyContent: 'center',
                        // alignItems: 'center',
                        width: '100%',
                        // height: 'calc(100vh - 20px)',
                        // background: '#00374e',
                        transition: 'all 0.2s ease-in-out'
                        //boxShadow: 'inset 0px 2px 4px -1px rgb(0 0 0 / 20%), inset 0px 4px 5px 0px rgb(0 0 0 / 14%), inset 0px 1px 10px 0px rgb(0 0 0 / 12%)'
                    }}>
                        {/*<div style={{marginBottom: 50, textAlign: 'left'}}>*/}
                        {/*    <pre>{JSON.stringify(questions, null, 2)}</pre>*/}
                        {/*</div>*/}
                        <div style={{
                            // paddingLeft: 100,
                            // paddingRight: 100,
                            textAlign: 'center',
                            opacity: 1
                        }}>
                            <Grid container style={{
                                // display: 'block',
                                // alignItems: 'flex-start',
                                overflow: 'hidden',
                                padding: 20,
                                transition: 'all 0.2s ease-in-out',
                                // width: ((Object.keys(questions).length * 100) + '%')
                            }}>
                                {questions.map((item, index) => {
                                    let subquestions = [...showSubQuestion];
                                    if (item.answers && item.answers.filter(i => i.type == 'logos').length > 0 && item.id != '50') {
                                        return (
                                            <Grid item xs={12} ref={logoRef} style={{
                                                display: 'block',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                transition: 'all 0.3s ease-in-out',
                                                margin: 'auto'
                                                // opacity: (activeQuestion === (index + 1)) ? 1 : 0,
                                                // width: (activeQuestion === (index + 1)) ? 'calc(100vw)' : 0,
                                            }}>
                                                <p style={{
                                                    fontSize: 64,
                                                    fontFamily: 'VagRoundedBold',
                                                    marginTop: 0,
                                                    marginBottom: 0,
                                                    position: 'relative',
                                                    color: (errors.length > 0 && errors.includes(item.id)) ? 'rgba(255,0,0,0.6)' : 'rgba(146,146,146,0.6)'
                                                }}>
                                                    {index}
                                                    {(errors.length > 0 && errors.includes(item.id)) &&
                                                        <Tooltip enterTouchDelay={0}
                                                                 title={'*Pflichtfeld'}>
                                                            <ErrorIcon style={{
                                                                marginLeft: 4,
                                                                position: 'absolute',
                                                                fontSize: 18,
                                                                marginTop: 15
                                                            }}
                                                                       fontSize={'10px'}
                                                                       id={'erroricon'}/>
                                                        </Tooltip>
                                                    }
                                                </p>
                                                <p style={{
                                                    fontSize: 16,
                                                    fontFamily: 'VagRoundedBold',
                                                    color: '#C7D300',
                                                    marginBottom: 0,
                                                }}>{(questions.length) >= activeQuestion ? questions[index].question + (questions[index].question?.endsWith('?') ? '' : ':') : 'ENDE'}{(questions[activeQuestion - 1].helperText) &&
                                                    <span className={'color-yellow'}>
                                                                                <Tooltip enterTouchDelay={0}
                                                                                         title={questions[index].helperText}>
                                                                                    <InfoIcon style={{ marginLeft: 4 }}
                                                                                              fontSize={'14px'}
                                                                                              id={'infoIcon'}/>
                                                                                </Tooltip></span>}</p>
                                                {questions[index].answers.filter(i => (i.type == 'multiple' || i.type == 'multipleDropdown')).length > 1 &&
                                                    <p style={{
                                                        minHeight: 25,
                                                        height: 25,
                                                        margin: 0,
                                                        padding: 0,
                                                        fontSize: 10,
                                                        color: 'white'
                                                    }}>(Mehrere Antworten möglich)</p>}
                                                <Grid container style={{ maxWidth: 800, margin: '20px auto 0' }}>
                                                    {(questions.length >= activeQuestion) && questions[index].answers.map((childItem, childIndex) => {
                                                        const Icon = item.logos[childItem.answer];
                                                        return (
                                                            <Grid item xs={3} style={{
                                                                // float: 'left',
                                                                // display: 'flex',
                                                                width: 200,
                                                            }}>
                                                                <Icon
                                                                    height={logoRef.current ? logoRef.current.offsetWidth / 4 : 0}
                                                                    style={{
                                                                        maxWidth: '100%',
                                                                        margin: 'auto',
                                                                        transition: 'all 0.2s ease-in-out',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={() => {
                                                                        let tq = [...questions];
                                                                        let t = tq[activeQuestion - 1];
                                                                        let temp = activeQuestion + 1;
                                                                        console.log(t.selected.answer);
                                                                        t.selected.answer = [childItem.answer];

                                                                        console.log(t);
                                                                        tq[activeQuestion - 1] = t;
                                                                        setQuestions(tq);
                                                                    }}
                                                                />
                                                                <div style={{ margin: '10px auto' }}>
                                                                    <BpCheckbox
                                                                        onClick={() => {
                                                                            let tq = [...questions];
                                                                            let t = tq[activeQuestion - 1];
                                                                            let temp = activeQuestion + 1;
                                                                            console.log(t.selected.answer);
                                                                            t.selected.answer = [childItem.answer];

                                                                            console.log(t);
                                                                            tq[activeQuestion - 1] = t;
                                                                            setQuestions(tq);
                                                                        }}
                                                                        checked={questions[index].selected.answer && questions[index].selected.answer.includes(childItem.answer) ? true : false}
                                                                    />
                                                                </div>
                                                                <span style={{ color: 'white' }}>{childItem.answer}
                                                                    <Tooltip enterTouchDelay={0}
                                                                             title={childItem.helperText}>
                                                    <InfoIcon
                                                        style={{ marginLeft: 4 }}
                                                        fontSize={'14px'}
                                                        id={'infoIcon'}
                                                    />
                                                </Tooltip>
                                            </span>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </Grid>
                                        );
                                    } else if (item.answers && item.answers.filter(i => i.type == 'führerschein').length > 0) {
                                        return (
                                            <Grid item xs={12} ref={logoRef} style={{
                                                display: 'block',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                transition: 'all 0.3s ease-in-out',
                                                margin: 'auto'
                                                // opacity: (activeQuestion === (index + 1)) ? 1 : 0,
                                                // width: (activeQuestion === (index + 1)) ? 'calc(100vw)' : 0,
                                            }}>
                                                <p style={{
                                                    fontSize: 64,
                                                    fontFamily: 'VagRoundedBold',
                                                    marginTop: 0,
                                                    marginBottom: 0,
                                                    position: 'relative',
                                                    color: (errors.length > 0 && errors.includes(item.id)) ? 'rgba(255,0,0,0.6)' : 'rgba(146,146,146,0.6)'
                                                }}>
                                                    {index}
                                                    {(errors.length > 0 && errors.includes(item.id)) &&
                                                        <Tooltip enterTouchDelay={0}
                                                                 title={'*Pflichtfeld'}>
                                                            <ErrorIcon style={{
                                                                marginLeft: 4,
                                                                position: 'absolute',
                                                                fontSize: 18,
                                                                marginTop: 15
                                                            }}
                                                                       fontSize={'10px'}
                                                                       id={'erroricon'}/>
                                                        </Tooltip>
                                                    }
                                                </p>
                                                <p style={{
                                                    fontSize: 16,
                                                    fontFamily: 'VagRoundedBold',
                                                    color: '#C7D300',
                                                    marginBottom: 0,
                                                }}>{(questions.length) >= activeQuestion ? questions[index].question + (questions[index].question?.endsWith('?') ? '' : ':') : 'ENDE'}{(questions[activeQuestion - 1].helperText) &&
                                                    <span className={'color-yellow'}>
                                                                                <Tooltip enterTouchDelay={0}
                                                                                         title={questions[index].helperText}>
                                                                                    <InfoIcon style={{ marginLeft: 4 }}
                                                                                              fontSize={'14px'}
                                                                                              id={'infoIcon'}/>
                                                                                </Tooltip></span>}</p>

                                                <p style={{
                                                    minHeight: 25,
                                                    height: 25,
                                                    margin: 0,
                                                    padding: 0,
                                                    fontSize: 10,
                                                    color: 'white'
                                                }}>(Mehrere Antworten möglich)</p>
                                                <Grid container style={{ maxWidth: 800, margin: '20px auto 0' }}>
                                                    <>
                                                        <div style={{ display: 'block', margin: 'auto' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                {(questions.length >= activeQuestion) && questions[index].answers.filter(i => i.type == 'führerschein' && i.label != 'Eine gültige Fahrerlaubnis ist nicht erforderlich' && i.answer != 'Nein').map((childItem, childIndex) => {
                                                                    const Icon = (item.führerscheinLogos.hasOwnProperty(childItem.answer)) ? item.führerscheinLogos[childItem.answer] : null;
                                                                    if(Icon)return (<>
                                                                            {childItem.answer !== 'Nein' &&
                                                                                <Grid item xs={3} style={{
                                                                                    // float: 'left',
                                                                                    // display: 'flex',
                                                                                    width: isMobile ? width / 5 - 20 : 80,
                                                                                    margin: '20px 25px',

                                                                                }}>
                                                                                    {
                                                                                        (childItem.answer === "FL" || childItem.answer === "L") ?
                                                                                            <Icon style={{
                                                                                                fill: "#c7d300",
                                                                                                margin: 'auto',
                                                                                                transition: 'all 0.2s ease-in-out',
                                                                                                cursor: 'pointer',
                                                                                                width: 'auto',
                                                                                                maxWidth: '100%',
                                                                                                height: 33
                                                                                            }}
                                                                                                  onClick={() => {
                                                                                                      let tq = [...questions];
                                                                                                      let t = tq[index];

                                                                                                      if (t.selected.answer && Array.isArray(t.selected.answer) && t.selected.answer.includes('Eine gültige Fahrerlaubnis ist nicht erforderlich')) {
                                                                                                          t.selected.answer = [];
                                                                                                      }

                                                                                                      if (!t.selected.answer.includes(childItem.answer)) {
                                                                                                          let asd = t.selected;
                                                                                                          asd.answer.push(childItem.answer)
                                                                                                          // asd.answer = [childItem.answer]
                                                                                                          t.selected = asd;

                                                                                                          if (t.selected.length === t.answers.length) {
                                                                                                              //setOnChangeQuestion(true);
                                                                                                              //setActiveQuestion(temp)
                                                                                                          }
                                                                                                      } else if (t.selected.answer.includes(childItem.answer)) {
                                                                                                          let asd = t.selected;
                                                                                                          let selectedIndex = asd.answer.indexOf(childItem.answer);
                                                                                                          asd.answer.splice(selectedIndex, 1);
                                                                                                          // asd.answer = [];
                                                                                                          t.selected = asd;
                                                                                                      }

                                                                                                      tq[index] = t;
                                                                                                      setQuestions(tq);
                                                                                                  }}/> : ""
                                                                                    }
                                                                                    {
                                                                                        (childItem.answer !== "FL" && childItem.answer !== "L") ?
                                                                                            <Icon style={{
                                                                                                fill: "#c7d300",
                                                                                                margin: 'auto',
                                                                                                transition: 'all 0.2s ease-in-out',
                                                                                                cursor: 'pointer',
                                                                                                width: 'auto',
                                                                                                maxWidth: '100%'
                                                                                            }}
                                                                                                  onClick={() => {
                                                                                                      let tq = [...questions];
                                                                                                      let t = tq[index];

                                                                                                      if (t.selected.answer && Array.isArray(t.selected.answer) && t.selected.answer.includes('Eine gültige Fahrerlaubnis ist nicht erforderlich')) {
                                                                                                          t.selected.answer = [];
                                                                                                      }

                                                                                                      if (!t.selected.answer.includes(childItem.answer)) {
                                                                                                          let asd = t.selected;
                                                                                                          asd.answer.push(childItem.answer)
                                                                                                          // asd.answer = [childItem.answer]
                                                                                                          t.selected = asd;

                                                                                                          if (t.selected.length === t.answers.length) {
                                                                                                              //setOnChangeQuestion(true);
                                                                                                              //setActiveQuestion(temp)
                                                                                                          }
                                                                                                      } else if (t.selected.answer.includes(childItem.answer)) {
                                                                                                          let asd = t.selected;
                                                                                                          let selectedIndex = asd.answer.indexOf(childItem.answer);
                                                                                                          asd.answer.splice(selectedIndex, 1);
                                                                                                          // asd.answer = [];
                                                                                                          t.selected = asd;
                                                                                                      }

                                                                                                      tq[index] = t;
                                                                                                      setQuestions(tq);
                                                                                                  }}/> : ""
                                                                                    }
                                                                                    {/*<Icon*/}
                                                                                    {/*    height={100}*/}
                                                                                    {/*    style={{*/}
                                                                                    {/*        margin: 'auto',*/}
                                                                                    {/*        transition: 'all 0.2s ease-in-out',*/}
                                                                                    {/*        cursor: 'pointer',*/}
                                                                                    {/*        width: 'auto',*/}
                                                                                    {/*        maxWidth: '100%'*/}
                                                                                    {/*    }}*/}
                                                                                    {/*    onClick={() => {*/}
                                                                                    {/*        let tq = [...questions];*/}
                                                                                    {/*        let t = tq[index];*/}

                                                                                    {/*        if (t.selected.answer && Array.isArray(t.selected.answer) && t.selected.answer.includes('Eine gültige Fahrerlaubnis ist nicht erforderlich')) {*/}
                                                                                    {/*            t.selected.answer = [];*/}
                                                                                    {/*        }*/}

                                                                                    {/*        if (!t.selected.answer.includes(childItem.answer)) {*/}
                                                                                    {/*            let asd = t.selected;*/}
                                                                                    {/*            asd.answer.push(childItem.answer)*/}
                                                                                    {/*            // asd.answer = [childItem.answer]*/}
                                                                                    {/*            t.selected = asd;*/}

                                                                                    {/*            if (t.selected.length === t.answers.length) {*/}
                                                                                    {/*                //setOnChangeQuestion(true);*/}
                                                                                    {/*                //setActiveQuestion(temp)*/}
                                                                                    {/*            }*/}
                                                                                    {/*        } else if (t.selected.answer.includes(childItem.answer)) {*/}
                                                                                    {/*            let asd = t.selected;*/}
                                                                                    {/*            let selectedIndex = asd.answer.indexOf(childItem.answer);*/}
                                                                                    {/*            asd.answer.splice(selectedIndex, 1);*/}
                                                                                    {/*            // asd.answer = [];*/}
                                                                                    {/*            t.selected = asd;*/}
                                                                                    {/*        }*/}

                                                                                    {/*        tq[index] = t;*/}
                                                                                    {/*        setQuestions(tq);*/}
                                                                                    {/*    }}*/}
                                                                                    {/*/>*/}
                                                                                    <div
                                                                                        style={{ margin: '0 auto 10px' }}>
                                                                                        <BpCheckbox
                                                                                            onClick={() => {
                                                                                                let tq = [...questions];
                                                                                                let t = tq[index];

                                                                                                if (t.selected.answer && Array.isArray(t.selected.answer) && t.selected.answer.includes('Eine gültige Fahrerlaubnis ist nicht erforderlich')) {
                                                                                                    t.selected.answer = [];
                                                                                                }

                                                                                                if (!t.selected.answer.includes(childItem.answer)) {
                                                                                                    let asd = t.selected;
                                                                                                    asd.answer.push(childItem.answer)
                                                                                                    // asd.answer = [childItem.answer]
                                                                                                    t.selected = asd;

                                                                                                    if (t.selected.length === t.answers.length) {
                                                                                                        //setOnChangeQuestion(true);
                                                                                                        //setActiveQuestion(temp)
                                                                                                    }
                                                                                                } else if (t.selected.answer.includes(childItem.answer)) {
                                                                                                    let asd = t.selected;
                                                                                                    let selectedIndex = asd.answer.indexOf(childItem.answer);
                                                                                                    asd.answer.splice(selectedIndex, 1);
                                                                                                    // asd.answer = [];
                                                                                                    t.selected = asd;
                                                                                                }

                                                                                                tq[index] = t;
                                                                                                setQuestions(tq);
                                                                                            }}
                                                                                            checked={questions[index].selected.answer && questions[index].selected.answer.includes(childItem.answer) ? true : false}
                                                                                        />
                                                                                    </div>
                                                                                    <span
                                                                                        style={{ color: 'white' }}>{childItem.answer}
                                                                                        <Tooltip enterTouchDelay={0}
                                                                                                 title={childItem.helperText}>
                                                    <InfoIcon
                                                        style={{ marginLeft: 4 }}
                                                        fontSize={'14px'}
                                                        id={'infoIcon'}
                                                    />
                                                </Tooltip>
                                            </span>
                                                                                </Grid>}
                                                                        </>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                        {(questions.length >= activeQuestion) && questions[index].answers.filter(i => i.type == 'führerschein' && i.label == 'Eine gültige Fahrerlaubnis ist nicht erforderlich').map((item, i) => {
                                                            return (
                                                                <div style={{
                                                                    display: 'block',
                                                                    opacity: 1,
                                                                    width: 'auto',
                                                                    margin: '20px auto 0',
                                                                }}>
                                                                    <a style={{
                                                                        color: questions[index].selected.answer.includes(item.label) ? '#C7D300' : 'white',
                                                                        textDecoration: 'underline',
                                                                        cursor: 'pointer'
                                                                    }} onClick={() => {
                                                                        let tq = [...questions];
                                                                        let t = tq[index];

                                                                        t.selected.answer = [item.label]
                                                                        console.log(t.selected.answer);
                                                                        tq[index] = t;
                                                                        setQuestions(tq);
                                                                    }}>{item.label}</a>
                                                                </div>
                                                            );
                                                        })}
                                                    </>
                                                </Grid>
                                            </Grid>
                                        );
                                    } else if(item.id != '50') {
                                        return (
                                            <Grid item xs={getItemSize(item.id)} style={{
                                                // display: 'block',
                                                // justifyContent: 'center',
                                                // alignItems: 'flex-start',
                                                // overflow: 'hidden',
                                                transition: 'all 0.3s ease-in-out',
                                                // opacity: (activeQuestion === (index + 1)) ? 1 : 0,
                                                // width: (activeQuestion === (index + 1)) ? 'calc(100vw)' : 0,
                                                // height: 'calc(100vh - 400px)',
                                                minHeight: 20,
                                                marginTop: 20
                                            }}>
                                                <p style={{
                                                    fontSize: 64,
                                                    fontFamily: 'VagRoundedBold',
                                                    marginTop: 0,
                                                    marginBottom: 0,
                                                    position: 'relative',
                                                    color: (errors.length > 0 && errors.includes(item.id)) ? 'rgba(255,0,0,0.6)' : 'rgba(146,146,146,0.6)'
                                                }}>
                                                    {index}
                                                    {(errors.length > 0 && errors.includes(item.id)) &&
                                                        <Tooltip enterTouchDelay={0}
                                                                 title={'*Pflichtfeld'}>
                                                            <ErrorIcon style={{
                                                                marginLeft: 4,
                                                                position: 'absolute',
                                                                fontSize: 18,
                                                                marginTop: 15
                                                            }}
                                                                       fontSize={'10px'}
                                                                       id={'erroricon'}/>
                                                        </Tooltip>
                                                    }
                                                </p>
                                                <p style={{
                                                    fontSize: 16,
                                                    fontFamily: 'VagRoundedBold',
                                                    color: '#C7D300',
                                                    marginBottom: (questions[index].type === 'multiple' || questions[index].type === 'multipleDropdown') ? 0 : 10,
                                                }}>{(questions.length) >= activeQuestion ? questions[index].question + (questions[index].question?.endsWith('?') ? '' : ':') : 'ENDE'}{(questions[index].helperText) &&
                                                    <span className={'color-yellow'}>
                                                                                <Tooltip enterTouchDelay={0}
                                                                                         title={questions[index].helperText}>
                                                                                    <InfoIcon style={{ marginLeft: 4 }}
                                                                                              fontSize={'14px'}
                                                                                              id={'infoIcon'}/>
                                                                                </Tooltip></span>}</p>
                                                {questions[index].answers.filter(i => (i.type == 'multiple' || i.type == 'multipleDropdown')).length > 1 &&
                                                    <p style={{
                                                        minHeight: 25,
                                                        height: 25,
                                                        marginBottom: 10,
                                                        padding: 0,
                                                        fontSize: 10,
                                                        color: 'white'
                                                    }}>(Mehrere Antworten möglich)</p>}

                                                {questions[index].answers && questions[index].answers.filter(i => i.type === 'multipleDropdown').length > 0 &&
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <FormControl
                                                            sx={{
                                                                width: '80%'
                                                            }}
                                                            style={{
                                                                marginTop: 5,
                                                                transition: 'all 0.15s ease',
                                                                minHeight: 46,
                                                            }}
                                                            className={'testClassSelect whiteLabel'}>
                                                            <Select2
                                                                id={'questionTest'}
                                                                value={questions[index].selected.answer}
                                                                onChange={(e) => {
                                                                    let tq = [...questions];
                                                                    tq[index].selected.answer = e.target.value;
                                                                    setQuestions(tq);
                                                                    setMultiSelectOpen(false);
                                                                }}
                                                                onClick={() => {
                                                                    if (!multiSelectOpen) setMultiSelectOpen(index)
                                                                }}
                                                                onClose={() => {
                                                                    setMultiSelectOpen(false);
                                                                }}
                                                                open={multiSelectOpen == index}
                                                                defaultValue={false}
                                                                fullWidth
                                                                multiple
                                                                style={{
                                                                    width: '100%',
                                                                    marginBottom: 10,
                                                                    color: 'white',
                                                                }}
                                                                MenuProps={MenuProps}
                                                                input={<Select2/>}
                                                            >
                                                                <MenuItem disabled value="">
                                                                </MenuItem>
                                                                {(questions.length >= activeQuestion) && questions[index].answers.map((childItem, childIndex) => {
                                                                    if (childItem.type == 'multipleDropdown') {
                                                                        return (
                                                                            <MenuItem
                                                                                value={childItem.answer}>{childItem.answer}{(childItem.helperText) &&
                                                                                <span style={{ color: 'white' }}>
                                                                    <Tooltip enterTouchDelay={0}
                                                                             title={childItem.helperText}>
                                                                        <InfoIcon
                                                                            style={{ marginLeft: 4 }}
                                                                            fontSize={'14px'}
                                                                            id={'infoIcon'}
                                                                        />
                                                                    </Tooltip>
                                                                </span>
                                                                            }</MenuItem>
                                                                        );
                                                                    }
                                                                })}
                                                            </Select2>
                                                        </FormControl>
                                                    </div>
                                                }
                                                {questions[index].answers && questions[index].answers.filter(i => i.type === 'singleDropdown').length > 0 &&
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}>
                                                        <FormControl
                                                            sx={{
                                                                width: '80%'
                                                            }} style={{
                                                            marginTop: 5,
                                                            transition: 'all 0.15s ease',
                                                            minHeight: 46,
                                                        }}
                                                            className={'testClassSelect whiteLabel'}>
                                                            <Select2
                                                                id={'questionTest'}
                                                                value={questions[index].selected.answer}
                                                                onChange={(e) => {
                                                                    let tq = [...questions];


                                                                    // questions[index].answers.filter(i => (i.type === 'single' || i.type === 'singleDropdown')).map((i, ind) => {
                                                                    //     if (e.target.value.includes(i.answer)) {
                                                                    //         let selectedIndex = e.target.value.indexOf(i.answer);
                                                                    //         e.target.value.splice(selectedIndex, 1);
                                                                    //     }
                                                                    // })


                                                                    tq[index].selected.answer = [e.target.value];
                                                                    setQuestions(tq);
                                                                }}
                                                                defaultValue={false}
                                                                fullWidth
                                                                sx={{
                                                                    // fieldset: {
                                                                    //     transition: 'all 1s ease',
                                                                    //     transform: showSubQuestion.includes('question-' + index + '-' + childIndex) ? 'scaleY(1)' : 'scaleY(0)'
                                                                    // }
                                                                }}
                                                                style={{
                                                                    width: '100%',
                                                                    marginBottom: 10,
                                                                    color: 'white',
                                                                }}
                                                                MenuProps={MenuProps}
                                                                input={<Select2/>}
                                                            >
                                                                <MenuItem disabled value="">
                                                                </MenuItem>
                                                                {(questions.length >= activeQuestion) && questions[index].answers.map((childItem, childIndex) => {
                                                                    if (childItem.type == 'singleDropdown') {
                                                                        return (
                                                                            <MenuItem
                                                                                value={childItem.answer}>{childItem.answer}{(childItem.helperText) &&
                                                                                <span style={{ color: 'white' }}>
                                                                    <Tooltip enterTouchDelay={0}
                                                                             title={childItem.helperText}>
                                                                        <InfoIcon
                                                                            style={{ marginLeft: 4 }}
                                                                            fontSize={'14px'}
                                                                            id={'infoIcon'}
                                                                        />
                                                                    </Tooltip>
                                                                </span>
                                                                            }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                })}
                                                            </Select2>
                                                        </FormControl>
                                                    </div>
                                                }
                                                {(questions.length >= activeQuestion && questions[index].answers) && questions[index].answers.map((childItem, childIndex) => {
                                                    return (
                                                        <>
                                                            {childItem.type == 'multiple' &&
                                                                <div
                                                                    style={{
                                                                        minHeight: 46,
                                                                        transition: 'all 0.15s ease',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: '100%',
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >
                                                                        <button
                                                                            key={index}
                                                                            onMouseOver={() => handleMouseIn('answer-' + index + '-' + childIndex)}
                                                                            onMouseOut={() => handleMouseOut()}
                                                                            style={{
                                                                                borderRadius: 20,
                                                                                cursor: 'pointer',
                                                                                background: 'transparent',
                                                                                width: '80%',
                                                                                border: '2px solid',
                                                                                padding: '10px 15px 8px 15px',
                                                                                margin: 5,
                                                                                borderColor: (buttonHover === ('answer-' + index + '-' + childIndex) || questions[index].selected.answer.includes(childItem.answer)) ? '#C7D300' : 'white',
                                                                                color: 'white'
                                                                            }}
                                                                            onClick={() => {
                                                                                let tq = [...questions];
                                                                                let t = tq[index].selected.answer;
                                                                                let temp = activeQuestion + 1;


                                                                                if (!t.includes(childItem.answer)) {
                                                                                    questions[index].answers.filter(i => (i.type === 'single' || i.type === 'singleDropdown')).map((i, ind) => {
                                                                                        if (t.includes(i.answer)) t = [];
                                                                                    })
                                                                                    t.push(childItem.answer)
                                                                                } else if (t.includes(childItem.answer)) {
                                                                                    let selectedIndex = t.indexOf(childItem.answer);
                                                                                    t.splice(selectedIndex, 1);
                                                                                }

                                                                                tq[index].selected.answer = t;
                                                                                console.log(tq[index].selected.answer)
                                                                                setQuestions(tq);
                                                                            }}
                                                                        >{childItem.answer}{(childItem.helperText) &&
                                                                            <span style={{ color: 'white' }}>
                                                                    <Tooltip enterTouchDelay={0}
                                                                             title={childItem.helperText}>
                                                                        <InfoIcon
                                                                            style={{ marginLeft: 4 }}
                                                                            fontSize={'14px'}
                                                                            id={'infoIcon'}
                                                                        />
                                                                    </Tooltip>
                                                                </span>
                                                                        }</button>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    );
                                                })}

                                                {(questions[index]?.id == 63) ?
                                                    <>
                                                        <div
                                                            style={{
                                                                transition: 'all 0.15s ease',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    // width: '90%',
                                                                    // margin: '0 calc(100% - 95%)',
                                                                    width: '100%',
                                                                    display: 'block',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >

                                                                <div style={{
                                                                    position: 'relative',
                                                                    paddingTop: 17
                                                                }}>
                                                                    {/*<Autocomplete*/}
                                                                    {/*    options={autocomplete}*/}
                                                                    {/*    getOptionLabel={(option) => {*/}
                                                                    {/*        if (option && option.attributes) {*/}
                                                                    {/*            return option.attributes.zipcode + ' ' + option.attributes.name;*/}
                                                                    {/*        } else {*/}
                                                                    {/*            return '';*/}
                                                                    {/*        }*/}
                                                                    {/*    }}*/}
                                                                    {/*    // dataSourceConfig={{text: props.title, value: props.input}}*/}
                                                                    {/*    name={''}*/}
                                                                    {/*    loading={autocompleteLoading === 'zipcode'}*/}
                                                                    {/*    freeSolo*/}
                                                                    {/*    openOnFocus={false}*/}
                                                                    {/*    value={selectedAutocomplete}*/}
                                                                    {/*    onChange={(event, newValue) => {*/}
                                                                    {/*        console.log("selectedAutoComüp", newValue);*/}
                                                                    {/*        if (newValue && newValue.attributes || newValue == null) {*/}
                                                                    {/*            setSelectedAutocomplete(newValue);*/}
                                                                    {/*        }*/}


                                                                    {/*        let tq = [...questions];*/}
                                                                    {/*        let t = [childItem];*/}

                                                                    {/*        //set Range also*/}
                                                                    {/*        let tDistance = [tq[index].answers.find((ans) => ans.answer == 'Egal')];*/}

                                                                    {/*        if (currentRange != 100) {*/}
                                                                    {/*            tDistance = [tq[index].answers.find((ans) => ans.answer == currentRange)];*/}
                                                                    {/*        }*/}

                                                                    {/*        tq[index].selected.answer = tDistance;*/}
                                                                    {/*        console.log("t found", t)*/}

                                                                    {/*        if (t) t[0].answer = newValue.attributes.zipcode*/}


                                                                    {/*        console.log("TQ", tq)*/}
                                                                    {/*        console.log("T", t.answer)*/}
                                                                    {/*        // tq[index].selected.answer = t;*/}
                                                                    {/*        console.log("selected", tq[index].selected)*/}
                                                                    {/*        tq[index].selected.autocomplete = t;*/}
                                                                    {/*        // tq[index].selected.subAutocomplete = [];*/}
                                                                    {/*        setQuestions(tq);*/}
                                                                    {/*    }}*/}
                                                                    {/*    inputValue={autocompleteInput}*/}
                                                                    {/*    onInputChange={async (event, newInputValue) => {*/}
                                                                    {/*        if (newInputValue == undefined || newInputValue == 'undefined') {*/}
                                                                    {/*            newInputValue = '';*/}
                                                                    {/*        }*/}
                                                                    {/*        setAutocompleteInput(newInputValue);*/}
                                                                    {/*        let t = [];*/}
                                                                    {/*        if (newInputValue && newInputValue.length > 0 || selectedAutocomplete && selectedAutocomplete.length > 0) setTest(true);*/}
                                                                    {/*        else setTest(false);*/}

                                                                    {/*        if (newInputValue && newInputValue.length > 1) {*/}
                                                                    {/*            let url = ENV.REACT_APP_API_URL + '/zipcodes';*/}
                                                                    {/*            setAutocompleteLoading('zipcode');*/}
                                                                    {/*            debouncedChangeHandlerZip(url, newInputValue);*/}

                                                                    {/*        }*/}
                                                                    {/*    }}*/}
                                                                    {/*    // open={(autocompleteInput.length > 2 && autocomplete.length > 0)}*/}
                                                                    {/*    open={(autocomplete.length > 0 && (!selectedAutocomplete && autocompleteInput || selectedAutocomplete && (selectedAutocomplete.attributes.zipcode + ' ' + selectedAutocomplete.attributes.name) != autocompleteInput))}*/}
                                                                    {/*    className={'autocompleteSearchJobsuche'}*/}
                                                                    {/*    onFocus={() => {*/}
                                                                    {/*        setTest(true);*/}
                                                                    {/*    }}*/}
                                                                    {/*    onBlur={() => {*/}
                                                                    {/*        if (!selectedAutocomplete || selectedAutocomplete.length === 0) {*/}
                                                                    {/*            setTest(false);*/}
                                                                    {/*            setAutocomplete([]);*/}
                                                                    {/*        }*/}
                                                                    {/*    }}*/}
                                                                    {/*    onKeyPress={handleKeypressEnter}*/}
                                                                    {/*    selectOnFocus*/}
                                                                    {/*    clearOnBlur*/}
                                                                    {/*    handleHomeEndKeys*/}
                                                                    {/*    renderInput={(params) =>*/}
                                                                    {/*        <>*/}
                                                                    {/*            <CssTextField3*/}
                                                                    {/*                {...params}*/}
                                                                    {/*                autoComplete="new-password"*/}
                                                                    {/*                label="PLZ oder Ort"*/}
                                                                    {/*                style={{*/}
                                                                    {/*                    transition: 'all 0.2s ease',*/}
                                                                    {/*                    margin: '0 0 8px 0',*/}
                                                                    {/*                    width: '100%',*/}
                                                                    {/*                    maxHeight: 41.13,*/}
                                                                    {/*                }}*/}
                                                                    {/*                className={'bg-transparent ASDF'}*/}
                                                                    {/*            />*/}
                                                                    {/*        </>*/}
                                                                    {/*    }*/}
                                                                    {/*/>*/}
                                                                    <div style={{
                                                                        position: 'relative',
                                                                        width: '80%',
                                                                        marginLeft: '10%',
                                                                        paddingBottom: 15
                                                                    }}>
                                                                        <p style={{
                                                                            transition: 'all 0.2s ease',
                                                                            textAlign: 'left',
                                                                            color: '#C7D300',
                                                                            marginLeft: 19,
                                                                            marginBottom: 0,
                                                                            fontSize: 10
                                                                        }}>Umkreis</p>
                                                                        <PrettoSlider
                                                                            size={'small'}
                                                                            defaultValue={30}
                                                                            step={10}
                                                                            min={10}
                                                                            value={currentRange}
                                                                            onChange={(event, newValue) => {

                                                                                if (newValue !== currentRange) {
                                                                                    setCurrentRange(newValue);
                                                                                    // getResultsZip()

                                                                                    //save answer
                                                                                    let tq = [...questions];
                                                                                    let t = [tq[index].answers.find((ans) => ans.answer == 'Egal')];

                                                                                    if (newValue != 100) {
                                                                                        t = [tq[index].answers.find((ans) => ans.answer == newValue)];
                                                                                    }

                                                                                    console.log("t found", t)
                                                                                    // if(t) t.answer = newValue


                                                                                    console.log("TQ autocomplete", JSON.stringify(tq[0].selected))
                                                                                    console.log("T", t)
                                                                                    tq[index].selected.answer = t;
                                                                                    // tq[index].selected.autocomplete = [t];
                                                                                    // tq[index].selected.subAutocomplete = [];
                                                                                    setQuestions(tq);
                                                                                    //debouncedChangeHandler(jobs, plz, newValue);
                                                                                }

                                                                            }}
                                                                            onKeyPress={handleKeypressEnter}
                                                                        />
                                                                    </div>
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        right: '10%',
                                                                        width: 40.13,
                                                                        height: 40.13,
                                                                        borderRadius: 25,
                                                                        background: '#C7D300',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        lineHeight: '12px'
                                                                    }}>
                                                                        <p style={{
                                                                            position: 'absolute',
                                                                            marginTop: 10,
                                                                            color: '#00374e',
                                                                            fontWeight: 'bold'
                                                                        }}>{currentRange}{(currentRange == 100) ? '+' : ''}</p>
                                                                        <p style={{
                                                                            position: 'absolute',
                                                                            fontSize: 10,
                                                                            marginTop: 25,
                                                                            color: '#00374e',
                                                                            fontWeight: 'bold'
                                                                        }}>km</p>
                                                                    </div>
                                                                </div>
                                                                <div

                                                                    style={{
                                                                        height: 350,
                                                                        marginLeft: '5%',
                                                                        width:  '90%',
                                                                        borderRadius: 15,
                                                                        marginTop: isMobile ? 5 : 10,
                                                                        marginBottom: isMobile ? 10 : 20,
                                                                        overflow: 'hidden',
                                                                        position: 'relative'
                                                                    }}
                                                                >
                                                                    {selectedCompanyAddresses.length < 1 && <div style={{
                                                                        height: '100%',
                                                                        width:  '100%',
                                                                        borderRadius: 15,
                                                                        background: 'rgba(0, 55, 78, 0.55)',
                                                                        color: 'white',
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        left: 0,
                                                                        zIndex: 999,
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center'
                                                                    }}><h2>Fehlender Arbeitsort der Stellenanzeige</h2></div>}
                                                                    <GoogleMapReact
                                                                        bootstrapURLKeys={{ key: ENV.GOOGLE_API_KEY }}
                                                                        defaultCenter={{
                                                                            lat: 51.13,
                                                                            lng: 9.21
                                                                        }}
                                                                        defaultZoom={5}
                                                                        yesIWantToUseGoogleMapApiInternals
                                                                        onGoogleApiLoaded={({
                                                                                                map,
                                                                                                maps
                                                                                            }) =>  apiIsLoaded(map, maps)
                                                                        }
                                                                    >
                                                                        {/*<div style={{background: 'red', height: 10, width: 10}}></div>*/}
                                                                        {/*<MapRadius*/}
                                                                        {/*    lat={defaultProps.center.lat}*/}
                                                                        {/*    lng={defaultProps.center.lng}*/}
                                                                        {/*    km={currentRange}*/}
                                                                        {/*/>*/}
                                                                        {/*<Circle*/}
                                                                        {/*    defaultCenter={{*/}
                                                                        {/*        lat: 55.555,*/}
                                                                        {/*        lng: 70.555*/}
                                                                        {/*    }}*/}
                                                                        {/*    radius={3000}*/}
                                                                        {/*    options={{ strokeColor: "#ff0000" }}*/}
                                                                        {/*/>*/}
                                                                    </GoogleMapReact>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> : <></>
                                                }
                                                {(questions[index].answers && questions[index].answers.filter(i => i.type == 'autocomplete' && i.parent_id == null).length > 0) && questions[index].answers.map((childItem, childIndex) => {
                                                    if (childItem.type == 'autocomplete') {

                                                    } else {
                                                        return;
                                                    }
                                                    return (
                                                        <>
                                                            <div
                                                                style={{
                                                                    transition: 'all 0.15s ease',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        // width: '90%',
                                                                        // margin: '0 calc(100% - 95%)',
                                                                        width: '100%',
                                                                        display: 'block',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    {childItem.autocompleteType == 'Zipcode' ?
                                                                        <>
                                                                            <div style={{
                                                                                position: 'relative',
                                                                                paddingTop: 17
                                                                            }}>
                                                                                <Autocomplete
                                                                                    options={autocomplete}
                                                                                    getOptionLabel={(option) => {
                                                                                        if (option && option.attributes) {
                                                                                            return option.attributes.zipcode + ' ' + option.attributes.name;
                                                                                        } else {
                                                                                            return '';
                                                                                        }
                                                                                    }}
                                                                                    // dataSourceConfig={{text: props.title, value: props.input}}
                                                                                    name={''}
                                                                                    loading={autocompleteLoading === 'zipcode'}
                                                                                    freeSolo
                                                                                    openOnFocus={false}
                                                                                    value={selectedAutocomplete}
                                                                                    onChange={(event, newValue) => {
                                                                                        console.log("selectedAutoComüp", newValue);
                                                                                        if (newValue && newValue.attributes || newValue == null) {
                                                                                            setSelectedAutocomplete(newValue);
                                                                                        }


                                                                                        let tq = [...questions];
                                                                                        let t = [childItem];

                                                                                        //set Range also
                                                                                        let tDistance = [tq[index].answers.find((ans) => ans.answer == 'Egal')];

                                                                                        if (currentRange != 100) {
                                                                                            tDistance = [tq[index].answers.find((ans) => ans.answer == currentRange)];
                                                                                        }

                                                                                        tq[index].selected.answer = tDistance;
                                                                                        console.log("t found", t)

                                                                                        if (t) t[0].answer = newValue.attributes.zipcode


                                                                                        console.log("TQ", tq)
                                                                                        console.log("T", t.answer)
                                                                                        // tq[index].selected.answer = t;
                                                                                        console.log("selected", tq[index].selected)
                                                                                        tq[index].selected.autocomplete = t;
                                                                                        // tq[index].selected.subAutocomplete = [];
                                                                                        setQuestions(tq);
                                                                                    }}
                                                                                    inputValue={autocompleteInput}
                                                                                    onInputChange={async (event, newInputValue) => {
                                                                                        if (newInputValue == undefined || newInputValue == 'undefined') {
                                                                                            newInputValue = '';
                                                                                        }
                                                                                        setAutocompleteInput(newInputValue);
                                                                                        let t = [];
                                                                                        if (newInputValue && newInputValue.length > 0 || selectedAutocomplete && selectedAutocomplete.length > 0) setTest(true);
                                                                                        else setTest(false);

                                                                                        if (newInputValue && newInputValue.length > 1) {
                                                                                            let url = ENV.REACT_APP_API_URL + '/zipcodes';
                                                                                            setAutocompleteLoading('zipcode');
                                                                                            debouncedChangeHandlerZip(url, newInputValue);

                                                                                        }
                                                                                    }}
                                                                                    // open={(autocompleteInput.length > 2 && autocomplete.length > 0)}
                                                                                    open={(autocomplete.length > 0 && (!selectedAutocomplete && autocompleteInput || selectedAutocomplete && (selectedAutocomplete.attributes.zipcode + ' ' + selectedAutocomplete.attributes.name) != autocompleteInput))}
                                                                                    className={'autocompleteSearchJobsuche'}
                                                                                    onFocus={() => {
                                                                                        setTest(true);
                                                                                    }}
                                                                                    onBlur={() => {
                                                                                        if (!selectedAutocomplete || selectedAutocomplete.length === 0) {
                                                                                            setTest(false);
                                                                                            setAutocomplete([]);
                                                                                        }
                                                                                    }}
                                                                                    onKeyPress={handleKeypressEnter}
                                                                                    selectOnFocus
                                                                                    clearOnBlur
                                                                                    handleHomeEndKeys
                                                                                    renderInput={(params) =>
                                                                                        <>
                                                                                            <CssTextField3
                                                                                                {...params}
                                                                                                autoComplete="new-password"
                                                                                                label="PLZ oder Ort"
                                                                                                style={{
                                                                                                    transition: 'all 0.2s ease',
                                                                                                    margin: '0 0 8px 0',
                                                                                                    width: '100%',
                                                                                                    maxHeight: 41.13,
                                                                                                }}
                                                                                                className={'bg-transparent ASDF'}
                                                                                            />
                                                                                        </>
                                                                                    }
                                                                                />
                                                                                <div style={{
                                                                                    position: 'absolute',
                                                                                    top: 17,
                                                                                    right: 0,
                                                                                    width: 40.13,
                                                                                    height: 40.13,
                                                                                    borderRadius: 25,
                                                                                    background: '#C7D300',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                    lineHeight: '12px'
                                                                                }}>
                                                                                    <p style={{
                                                                                        position: 'absolute',
                                                                                        marginTop: 10,
                                                                                        color: '#00374e'
                                                                                    }}>{currentRange}{(currentRange == 100) ? '+' : ''}</p>
                                                                                    <p style={{
                                                                                        position: 'absolute',
                                                                                        fontSize: 10,
                                                                                        marginTop: 25,
                                                                                        color: '#00374e'
                                                                                    }}>km</p>
                                                                                </div>
                                                                            </div>
                                                                            <div style={{
                                                                                position: 'relative',
                                                                                width: 'calc(100% - 14px)',
                                                                                marginLeft: 7,
                                                                                paddingBottom: 15
                                                                            }}>
                                                                                <p style={{
                                                                                    transition: 'all 0.2s ease',
                                                                                    textAlign: 'left',
                                                                                    color: '#C7D300',
                                                                                    marginLeft: 19,
                                                                                    marginBottom: 0,
                                                                                    fontSize: 10
                                                                                }}>Umkreis</p>
                                                                                <PrettoSlider
                                                                                    size={'small'}
                                                                                    defaultValue={30}
                                                                                    step={10}
                                                                                    min={10}
                                                                                    value={currentRange}
                                                                                    onChange={(event, newValue) => {

                                                                                        if (newValue !== currentRange) {
                                                                                            setCurrentRange(newValue);
                                                                                            // getResultsZip()

                                                                                            //save answer
                                                                                            let tq = [...questions];
                                                                                            let t = [tq[index].answers.find((ans) => ans.answer == 'Egal')];

                                                                                            if (newValue != 100) {
                                                                                                t = [tq[index].answers.find((ans) => ans.answer == newValue)];
                                                                                            }

                                                                                            console.log("t found", t)
                                                                                            // if(t) t.answer = newValue


                                                                                            console.log("TQ autocomplete", JSON.stringify(tq[0].selected))
                                                                                            console.log("T", t)
                                                                                            tq[index].selected.answer = t;
                                                                                            // tq[index].selected.autocomplete = [t];
                                                                                            // tq[index].selected.subAutocomplete = [];
                                                                                            setQuestions(tq);
                                                                                            //debouncedChangeHandler(jobs, plz, newValue);
                                                                                        }

                                                                                    }}
                                                                                    onKeyPress={handleKeypressEnter}
                                                                                />
                                                                            </div>
                                                                        </> :
                                                                        <Autocomplete
                                                                            options={questions[index].autocomplete}
                                                                            getOptionLabel={(option) => {
                                                                                return option.title ?? '';
                                                                            }}
                                                                            dataSourceConfig={{
                                                                                text: props.title,
                                                                                value: props.input
                                                                            }}
                                                                            ref={autocompleteRef}
                                                                            name={''}
                                                                            freeSolo
                                                                            multiple
                                                                            openOnFocus={false}
                                                                            value={questions[index].selected.autocomplete}
                                                                            groupBy={(option) => 'Vorschläge'}
                                                                            onChange={(event, newValue) => {
                                                                                let temp = [...questions];
                                                                                let t = [];

                                                                                if (newValue) {
                                                                                    newValue.map((item, index) => {
                                                                                        // let tem = item;
                                                                                        // if (subItem.autocompleteType == 'Studium') tem = ' ' + tem;
                                                                                        if (typeof item === 'string') {
                                                                                            t.push({
                                                                                                input: item,
                                                                                                title: item,
                                                                                            });
                                                                                        } else if (item && item.title) {
                                                                                            // Create a new value from the user input
                                                                                            t.push({
                                                                                                input: item.title,
                                                                                                title: item.title,
                                                                                            });
                                                                                        } else {
                                                                                            t.push(item);
                                                                                        }
                                                                                    })
                                                                                    temp[index].selected.autocomplete = t;
                                                                                    temp[index].selected.autocompleteInput = '';
                                                                                    temp[index].selected.autocompleteInputBak = '';
                                                                                    setQuestions(temp);
                                                                                }
                                                                                setIsFocused(null)

                                                                            }}
                                                                            loading={autocompleteLoading == index}
                                                                            inputValue={questions[index].selected.autocompleteInput}
                                                                            onInputChange={async (event, newInputValue) => {
                                                                                setIsFocused(('answer-' + index + '-' + childIndex))
                                                                                let temp = [...questions];
                                                                                if (newInputValue) {
                                                                                    let t = [];
                                                                                    if (childItem.autocompleteType && newInputValue && newInputValue.length > 0) {
                                                                                        let url = ENV.REACT_APP_API_URL + '/autocompletes?filter%5BautocompleteType%5D%5Beq%5D=' + childItem.autocompleteType + '&filter%5BautocompleteData%5D%5Blike%5D=' + newInputValue;
                                                                                        setAutocompleteLoading(index);
                                                                                        debouncedChangeHandler(url, 1, temp, index);
                                                                                    }
                                                                                }
                                                                                temp[index].selected.autocompleteInput = newInputValue;
                                                                                if (newInputValue.length > 0 || keyDown && newInputValue.length == 0) temp[index].selected.autocompleteInputBak = newInputValue;
                                                                                if (!temp[index].autocomplete) temp[index].autocomplete = [];
                                                                                setQuestions(temp);
                                                                            }}
                                                                            // open={questions[index].selected.autocompleteInput.length > 2 && questions[index].autocomplete && questions[index].autocomplete.length > 0 && (!questions[index].selected.autocomplete || questions[index].selected.autocomplete != questions[index].selected.autocompleteInput)}
                                                                            open={(questions[index].selected.autocompleteInput.length > 0)}
                                                                            className={'text-input-label-white ' + (isFocused === ('answer-' + index + '-' + childIndex) || (questions[index].selected.autocomplete && questions[index].selected.autocomplete.length > 0) ? '' : 'borderWhite')}
                                                                            onFocus={() => {
                                                                                setIsFocused(('answer-' + index + '-' + childIndex))
                                                                            }}
                                                                            onKeyDown={(e) => {
                                                                                if (e.keyCode == 8 || e.keyCode == 46) setKeyDown(true)
                                                                                else setKeyDown(false)
                                                                            }}
                                                                            onBlur={() => {
                                                                                setIsFocused(null)
                                                                                if (questions[index].selected.autocompleteInputBak) {
                                                                                    let temp = [...questions];
                                                                                    temp[index].selected.autocomplete.push({
                                                                                        input: questions[index].selected.autocompleteInputBak,
                                                                                        title: questions[index].selected.autocompleteInputBak
                                                                                    });
                                                                                    temp[index].selected.autocompleteInput = '';
                                                                                    temp[index].selected.autocompleteInputBak = '';
                                                                                    setQuestions(temp);
                                                                                }
                                                                                setKeyDown(false);
                                                                            }}
                                                                            selectOnFocus
                                                                            clearOnBlur
                                                                            handleHomeEndKeys
                                                                            renderInput={(params) =>
                                                                                <>
                                                                                    <CssTextField2
                                                                                        {...params}
                                                                                        sx={{ input: { color: 'white' } }}
                                                                                        label={childItem.label}
                                                                                        InputLabelProps={{
                                                                                            shrink: true
                                                                                        }}
                                                                                        variant="outlined"
                                                                                        style={{
                                                                                            width: '100%',
                                                                                            margin: '5px 0 0 0',
                                                                                            padding: 0,
                                                                                        }}
                                                                                        error={(!autocompleteLoading && questions[index].selected.autocompleteInput.length > 0 && (!questions[index].autocomplete || questions[index].autocomplete.length == 0))}
                                                                                        helperText={'Nutze wenn möglich unsere Vorschläge!'}
                                                                                    />
                                                                                </>
                                                                            }
                                                                        />}
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                                {questions[index].answers && questions[index].answers.filter(i => i.type === 'textfield').length > 0 && questions[index].answers.map((childItem, childIndex) => {
                                                    return (
                                                        <>
                                                            {(childItem.type == 'textfield') &&
                                                                <div
                                                                    style={{
                                                                        transition: 'all 0.15s ease',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            // width: '90%',
                                                                            // margin: '0 calc(100% - 95%)',
                                                                            width: '100%',
                                                                            display: 'block',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >
                                                                        <CssTextField2
                                                                            label={item.answer}
                                                                            InputLabelProps={{
                                                                                shrink: true
                                                                            }}
                                                                            value={Array.isArray(questions[index].selected.answer) ? '' : questions[index].selected.answer}
                                                                            onChange={(e) => {
                                                                                let tq = [...questions];
                                                                                let t = tq[index].selected.answer;

                                                                                questions[index].answers.filter(i => (i.type === 'single' || i.type === 'singleDropdown')).map((i, ind) => {
                                                                                    if (Array.isArray(t) && t.includes(i.answer)) t = [];
                                                                                })
                                                                                t = childItem.answer;
                                                                                tq[index].selected.answer = t;
                                                                                setQuestions(tq);
                                                                            }}
                                                                            className={'multiLineInputColor ' + (isFocused === ('answer-' + index + '-' + childIndex) || (questions[index].selected.subAnswer && questions[index].selected.subAnswer[0]) ? '' : 'borderWhite')}
                                                                            onFocus={() => {
                                                                                setIsFocused(('answer-' + index + '-' + childIndex))
                                                                            }}
                                                                            onBlur={() => {
                                                                                setIsFocused(null)
                                                                            }}
                                                                            multiline={childItem.rows ? true : false}
                                                                            rows={childItem.rows ?? 1}
                                                                            sx={{
                                                                                input: { color: 'white' },
                                                                                multilineColor: { color: 'white' },
                                                                                label: { color: 'white' },
                                                                                fieldset: { transition: 'all 1s ease', }
                                                                            }}
                                                                            style={{
                                                                                marginTop: 10,
                                                                                marginBottom: 5,
                                                                                width: '80%',
                                                                                transformOrigin: 'top',
                                                                                transition: 'all 0.1s ease-in',
                                                                            }}
                                                                            autoComplete={'off'}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    );
                                                })}
                                                {questions[index].answers && questions[index].answers.filter(i => i.type === 'datetimepicker').length > 0 && false && questions[index].answers.map((childItem, childIndex) => {
                                                    return (
                                                        <>
                                                            {(childItem.type == 'datetimepicker') &&
                                                                <div
                                                                    style={{
                                                                        transition: 'all 0.15s ease',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            // width: '90%',
                                                                            // margin: '0 calc(100% - 95%)',
                                                                            width: '80%',
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'flex-start',
                                                                            margin: 'auto',
                                                                            // height: 200,
                                                                        }}
                                                                    >
                                                                        {/*<MuiPickersUtilsProvider locale={de} utils={DayJsUtils}>*/}
                                                                        {/*    {console.log(new Date())}*/}
                                                                        {/*    <DatePicker*/}
                                                                        {/*        autoOk*/}
                                                                        {/*        openTo={'month'}*/}
                                                                        {/*        // onChange={(e) => {*/}
                                                                        {/*        //     console.log(e);*/}
                                                                        {/*        // }}*/}
                                                                        {/*        inputVariant="outlined"*/}
                                                                        {/*        views={['month', 'year']}*/}
                                                                        {/*        placeholder="Wähle ein Datum"*/}
                                                                        {/*        format="MMM YYYY"*/}
                                                                        {/*        minDate={new Date()}*/}
                                                                        {/*        ampm={false}*/}
                                                                        {/*        className={'dateTimeModalPicker text-input-label-white ' + (isFocused === ('answer-' + index + '-' + childIndex) || (questions[index].selected.autocomplete && questions[index].selected.autocomplete.length > 0) ? 'borderYellow' : 'borderWhite')}*/}
                                                                        {/*        onFocus={() => {*/}
                                                                        {/*            setIsFocused(('answer-' + index + '-' + childIndex))*/}
                                                                        {/*        }}*/}
                                                                        {/*        onBlur={() => {*/}
                                                                        {/*            setIsFocused(null)*/}
                                                                        {/*        }}*/}
                                                                        {/*        value={questions[index].selected.answer}*/}
                                                                        {/*        onChange={(e) => {*/}
                                                                        {/*            let tq = [...questions];*/}
                                                                        {/*            tq[index].selected.answer = e;*/}
                                                                        {/*            setQuestions(tq);*/}
                                                                        {/*        }}*/}
                                                                        {/*        selectOnFocus*/}
                                                                        {/*        clearOnBlur*/}
                                                                        {/*        handleHomeEndKeys*/}
                                                                        {/*        min*/}
                                                                        {/*        renderInput={(params) =>*/}
                                                                        {/*            <>*/}
                                                                        {/*                <CssTextField2*/}
                                                                        {/*                    {...params}*/}
                                                                        {/*                    sx={{input: {color: 'white'}}}*/}
                                                                        {/*                    label={childItem.label}*/}
                                                                        {/*                    InputLabelProps={{*/}
                                                                        {/*                        shrink: true*/}
                                                                        {/*                    }}*/}
                                                                        {/*                    variant="outlined"*/}
                                                                        {/*                    style={{*/}
                                                                        {/*                        width: '80%',*/}
                                                                        {/*                        margin: '5px 0 0 0',*/}
                                                                        {/*                        padding: 0,*/}
                                                                        {/*                    }}*/}
                                                                        {/*                />*/}
                                                                        {/*            </>*/}
                                                                        {/*        }*/}
                                                                        {/*    />*/}
                                                                        {/*</MuiPickersUtilsProvider>*/}
                                                                        <Grid container>
                                                                            <Grid item xs={7}
                                                                                  style={{ paddingRight: 5 }}>
                                                                                <FormControl
                                                                                    sx={{
                                                                                        width: '100%'
                                                                                    }}
                                                                                    style={{
                                                                                        marginTop: 15,
                                                                                        transition: 'all 0.15s ease',
                                                                                        minHeight: 46,
                                                                                    }}
                                                                                    className={'testClassSelect whiteLabel'}>
                                                                                    <InputLabel
                                                                                        className={'whiteLabel'}>Monat</InputLabel>
                                                                                    <Select2
                                                                                        id={'questionTest'}
                                                                                        // value={questions[index].selected.answer}
                                                                                        onChange={(e) => {
                                                                                            // let tq = [...questions];
                                                                                            // let t = tq[index].selected.answer;
                                                                                            // let temp = activeQuestion + 1;
                                                                                            //
                                                                                            //
                                                                                            // questions[index].answers.filter(i => (i.type === 'single' || i.type === 'singleDropdown')).map((i, ind) => {
                                                                                            //     if (e.target.value.includes(i.answer)) {
                                                                                            //         let selectedIndex = e.target.value.indexOf(i.answer);
                                                                                            //         e.target.value.splice(selectedIndex, 1);
                                                                                            //     }
                                                                                            // })
                                                                                            //
                                                                                            //
                                                                                            // t = e.target.value;
                                                                                            // tq[index].selected.answer = t;
                                                                                            // setQuestions(tq);
                                                                                        }}
                                                                                        defaultValue={false}
                                                                                        fullWidth
                                                                                        style={{
                                                                                            width: '100%',
                                                                                            marginBottom: 10,
                                                                                            color: 'white',
                                                                                        }}
                                                                                        MenuProps={MenuProps}
                                                                                        input={<Select2/>}
                                                                                    >
                                                                                        <MenuItem
                                                                                            value={'Januar'}>Januar</MenuItem>
                                                                                        <MenuItem
                                                                                            value={'Februar'}>Februar</MenuItem>
                                                                                        <MenuItem
                                                                                            value={'März'}>März</MenuItem>
                                                                                        <MenuItem
                                                                                            value={'April'}>April</MenuItem>
                                                                                        <MenuItem
                                                                                            value={'Mai'}>Mai</MenuItem>
                                                                                        <MenuItem
                                                                                            value={'Juni'}>Juni</MenuItem>
                                                                                        <MenuItem
                                                                                            value={'Juli'}>Juli</MenuItem>
                                                                                        <MenuItem
                                                                                            value={'August'}>August</MenuItem>
                                                                                        <MenuItem
                                                                                            value={'September'}>September</MenuItem>
                                                                                        <MenuItem
                                                                                            value={'Oktober'}>Oktober</MenuItem>
                                                                                        <MenuItem
                                                                                            value={'November'}>November</MenuItem>
                                                                                        <MenuItem
                                                                                            value={'Dezember'}>Dezember</MenuItem>
                                                                                    </Select2>
                                                                                </FormControl>
                                                                            </Grid>
                                                                            <Grid item xs={5}>
                                                                                <FormControl
                                                                                    sx={{
                                                                                        width: '100%'
                                                                                    }}
                                                                                    style={{
                                                                                        marginTop: 15,
                                                                                        transition: 'all 0.15s ease',
                                                                                        minHeight: 46,
                                                                                    }}
                                                                                    className={'testClassSelect whiteLabel'}>
                                                                                    <InputLabel shrink
                                                                                                className={'whiteLabel'}>Jahr</InputLabel>
                                                                                    <Select2
                                                                                        id={'questionTest'}
                                                                                        // value={questions[index].selected.answer}
                                                                                        onChange={(e) => {
                                                                                            // let tq = [...questions];
                                                                                            // let t = tq[index].selected.answer;
                                                                                            // let temp = activeQuestion + 1;
                                                                                            //
                                                                                            //
                                                                                            // questions[index].answers.filter(i => (i.type === 'single' || i.type === 'singleDropdown')).map((i, ind) => {
                                                                                            //     if (e.target.value.includes(i.answer)) {
                                                                                            //         let selectedIndex = e.target.value.indexOf(i.answer);
                                                                                            //         e.target.value.splice(selectedIndex, 1);
                                                                                            //     }
                                                                                            // })
                                                                                            //
                                                                                            //
                                                                                            // t = e.target.value;
                                                                                            // tq[index].selected.answer = t;
                                                                                            // setQuestions(tq);
                                                                                        }}
                                                                                        defaultValue={false}
                                                                                        fullWidth
                                                                                        style={{
                                                                                            width: '100%',
                                                                                            marginBottom: 10,
                                                                                            color: 'white',
                                                                                        }}
                                                                                        MenuProps={MenuProps}
                                                                                        input={<Select2/>}
                                                                                    >
                                                                                        <MenuItem
                                                                                            value={'2022'}>2022</MenuItem>
                                                                                        <MenuItem
                                                                                            value={'2023'}>2023</MenuItem>
                                                                                    </Select2>
                                                                                </FormControl>
                                                                            </Grid>
                                                                        </Grid>


                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    );
                                                })}
                                                {questions[index].answers && questions[index].answers.filter(i => i.type === 'single').length > 0 && questions[index].answers.map((childItem, childIndex) => {
                                                    let subquestions = [...showSubQuestion];

                                                    return (
                                                        <>
                                                            {(childItem.type == 'single') &&
                                                                <div
                                                                    style={{
                                                                        // height: subquestions.includes(('answer-' + index + '-' + childIndex)) ? childItem.type == 'autocomplete' ? 'calc(100% + 55px)' : ((childItem.sub ? childItem.sub.length : 1) * 50 + (childItem.sub && childItem.sub.filter(i => (i.type == 'multiple' || i.type == 'multipleDropdown')).length > 1 ? 86 : 66)) : 46,
                                                                        height: 'auto',
                                                                        transition: 'all 0.15s ease',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: '100%',
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >
                                                                        <button
                                                                            key={index}
                                                                            onMouseOver={() => handleMouseIn('answer-' + index + '-' + childIndex)}
                                                                            onMouseOut={() => handleMouseOut()}
                                                                            style={{
                                                                                borderRadius: 20,
                                                                                cursor: 'pointer',
                                                                                background: 'transparent',
                                                                                width: '80%',
                                                                                border: '2px solid',
                                                                                padding: '10px 15px 8px 15px',
                                                                                margin: 5,
                                                                                borderColor: (buttonHover === ('answer-' + index + '-' + childIndex) || Array.isArray(questions[index].selected.answer) && questions[index].selected.answer.includes(childItem.answer)) ? '#C7D300' : 'white',
                                                                                color: 'white',
                                                                                position: 'relative'
                                                                            }}
                                                                            onClick={() => {
                                                                                let tq = [...questions];
                                                                                let subquestions = [...showSubQuestion];
                                                                                let t = tq[index].selected.answer;
                                                                                let temp = activeQuestion + 1;

                                                                                if (childItem.sub && (childItem.sub.length > 0) && !subquestions.includes(('answer-' + index + '-' + childIndex))) {
                                                                                    subquestions.push('answer-' + index + '-' + childIndex);
                                                                                } else if (childItem.sub && (childItem.sub && childItem.sub.length > 0) && subquestions.includes(('answer-' + index + '-' + childIndex))) {
                                                                                    subquestions.splice(subquestions.indexOf('answer-' + index + '-' + childIndex), 1);
                                                                                    questions[index].selected.subAnswer = [];
                                                                                }

                                                                                if (tq[index].question === 'Was bist du derzeit?') {
                                                                                    console.log(childItem.answer)
                                                                                    setCategory(childItem.answer);
                                                                                    setOnChangeQuestion(true);
                                                                                }

                                                                                if (!Array.isArray(t) || !t.includes(childItem.answer)) {
                                                                                    subquestions.map((item, index2) => {
                                                                                        if (item.startsWith('answer-' + index + '-') && item !== ('answer-' + index + '-' + childIndex)) {
                                                                                            subquestions.splice(index2, 1);
                                                                                            tq[index].selected.subAnswer = [];
                                                                                            // tq[index].selected.autocomplete = [];
                                                                                        }
                                                                                    })
                                                                                    console.log(tq[index].selected.autocomplete);

                                                                                    t = [childItem.answer];
                                                                                } else if (t.includes(childItem.answer)) {
                                                                                    t = [];
                                                                                }
                                                                                console.log(subquestions);
                                                                                setShowSubQuestion(subquestions);

                                                                                tq[index].selected.answer = t;
                                                                                tq[index].selected.autocomplete = [];
                                                                                setQuestions(tq);
                                                                            }}
                                                                        >{childItem.answer}{(childItem.helperText) &&
                                                                            <span style={{ color: 'white' }}>
                                                                    <Tooltip enterTouchDelay={0}
                                                                             title={childItem.helperText}>
                                                                        <InfoIcon
                                                                            style={{ marginLeft: 4 }}
                                                                            fontSize={'14px'}
                                                                            id={'infoIcon'}
                                                                        />
                                                                    </Tooltip>
                                                                </span>
                                                                        }</button>
                                                                    </div>
                                                                    {(childItem.sub && (childItem.sub && childItem.sub.length > 0) || (childItem.sub && childItem.sub.url) || (childItem.sub && childItem.sub.autocomplete)) && subquestions.includes(('answer-' + index + '-' + childIndex)) &&
                                                                        <div
                                                                            style={{ margin: '5px calc(100% - 95%) 10px' }}>
                                                                            {/* Type multipleDropdown START */}
                                                                            {(childItem.sub && childItem.sub.length > 0 && childItem.sub.filter(i => i.type === 'multipleDropdown').length > 1) &&
                                                                                <div
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        display: 'flex',
                                                                                        justifyContent: 'center',
                                                                                        alignItems: 'center'
                                                                                    }}
                                                                                >
                                                                                    <FormControl
                                                                                        sx={{
                                                                                            width: '80%'
                                                                                        }} style={{
                                                                                        marginTop: 5,
                                                                                        transition: 'all 0.15s ease',
                                                                                        height: 46,
                                                                                    }}
                                                                                        className={'testClassSelect whiteLabel'}>
                                                                                        <Select2
                                                                                            id={'questionTest'}
                                                                                            value={questions[index].selected.answer}
                                                                                            onChange={(e) => {
                                                                                                let tq = [...questions];
                                                                                                let t = tq[index].selected.answer;
                                                                                                let temp = activeQuestion + 1;


                                                                                                questions[index].answers.filter(i => (i.type === 'single' || i.type === 'singleDropdown')).map((i, ind) => {
                                                                                                    if (e.target.value.includes(i.answer)) {
                                                                                                        let selectedIndex = e.target.value.indexOf(i.answer);
                                                                                                        e.target.value.splice(selectedIndex, 1);
                                                                                                    }
                                                                                                })


                                                                                                t = e.target.value;
                                                                                                tq[index].selected.answer = t;
                                                                                                setQuestions(tq);
                                                                                            }}
                                                                                            defaultValue={false}
                                                                                            fullWidth
                                                                                            multiple
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                marginBottom: 10,
                                                                                                color: 'white',
                                                                                            }}
                                                                                            MenuProps={MenuProps}
                                                                                            input={<Select2/>}
                                                                                        >
                                                                                            {childItem.sub.map((subItem, subIndex) => {
                                                                                                if (subItem.type == 'multipleDropdown') {
                                                                                                    return (
                                                                                                        <MenuItem
                                                                                                            value={subItem.answer}>{subItem.answer}</MenuItem>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                        </Select2>
                                                                                    </FormControl>
                                                                                </div>
                                                                            }

                                                                            {/* Type multiple START */}
                                                                            {(childItem.sub && childItem.sub.length > 0) && childItem.sub.map((subItem, subIndex) => {

                                                                                return (
                                                                                    <>
                                                                                        {subItem.type == 'multiple' &&
                                                                                            <div style={{
                                                                                                height: 46,
                                                                                                transition: 'all 0.15s ease',
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    width: '100%',
                                                                                                    display: 'flex',
                                                                                                    justifyContent: 'center',
                                                                                                    alignItems: 'center',
                                                                                                }}>
                                                                                                    <button
                                                                                                        key={index}
                                                                                                        onMouseOver={() => handleMouseIn('answer-' + index + '-' + childIndex + '-' + subIndex)}
                                                                                                        onMouseOut={() => handleMouseOut()}
                                                                                                        style={{
                                                                                                            borderRadius: 20,
                                                                                                            cursor: 'pointer',
                                                                                                            background: 'transparent',
                                                                                                            width: '80%',
                                                                                                            border: '2px solid',
                                                                                                            padding: '10px 15px 8px 15px',
                                                                                                            margin: 5,
                                                                                                            borderColor: (buttonHover === ('answer-' + index + '-' + childIndex + '-' + subIndex) || questions[index].selected.subAnswer.includes(subItem.answer)) ? '#C7D300' : 'white',
                                                                                                            color: 'white'
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            let tq = [...questions];
                                                                                                            let t = tq[index].selected.subAnswer;
                                                                                                            let temp = activeQuestion + 1;

                                                                                                            if (!t.includes(subItem.answer)) {
                                                                                                                //t = [subItem];
                                                                                                                // console.log(questions[index].answers)
                                                                                                                t.push(subItem.answer)
                                                                                                            } else if (t.includes(subItem.answer)) {
                                                                                                                //t.antwort = [];
                                                                                                                let selectedIndex = t.indexOf(subItem.answer);
                                                                                                                t.splice(selectedIndex, 1);
                                                                                                            }

                                                                                                            tq[index].selected.subAnswer = t;
                                                                                                            setQuestions(tq);
                                                                                                        }}
                                                                                                    >{subItem.answer}</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    </>
                                                                                );
                                                                            })}
                                                                            {/* Type multiple END */}

                                                                            {/* Type single START */}
                                                                            {(childItem.sub && childItem.sub.length > 0) && childItem.sub.map((subItem, subIndex) => {
                                                                                return (
                                                                                    <>
                                                                                        {(subItem.type == 'single') &&
                                                                                            <div style={{
                                                                                                transition: 'all 0.15s ease',
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    width: '100%',
                                                                                                    display: childItem.sub.subType == 'autocomplete' ? 'block' : 'flex',
                                                                                                    justifyContent: 'center',
                                                                                                    alignItems: 'center',
                                                                                                }}>
                                                                                                    {(childItem.sub.answer.length > 1) && <>
                                                                                                        <button
                                                                                                            key={index}
                                                                                                            onMouseOver={() => handleMouseIn('answer-' + index + '-' + childIndex + '-' + subIndex)}
                                                                                                            onMouseOut={() => handleMouseOut()}
                                                                                                            style={{
                                                                                                                borderRadius: 20,
                                                                                                                cursor: 'pointer',
                                                                                                                background: 'transparent',
                                                                                                                width: '80%',
                                                                                                                border: '2px solid',
                                                                                                                padding: '10px 15px 8px 15px',
                                                                                                                margin: 5,
                                                                                                                borderColor: (buttonHover === ('answer-' + index + '-' + childIndex + '-' + subIndex) || questions[index].selected.subAnswer.includes(childItem.answer)) ? '#C7D300' : 'white',
                                                                                                                color: 'white'
                                                                                                            }}
                                                                                                            onClick={() => {
                                                                                                                let tq = [...questions];
                                                                                                                let subquestions = [...showSubQuestion];
                                                                                                                let t = tq[index].selected.subAnswer;
                                                                                                                let temp = activeQuestion + 1;

                                                                                                                if (!t.includes(subItem.answer)) {
                                                                                                                    t = [subItem.answer];
                                                                                                                } else if (t.includes(subItem.answer)) {
                                                                                                                    t = [];
                                                                                                                }
                                                                                                                setShowSubQuestion(subquestions);

                                                                                                                tq[index].selected.answer = t;
                                                                                                                setQuestions(tq);
                                                                                                            }}
                                                                                                        >{subItem.answer}</button>
                                                                                                    </>
                                                                                                    }
                                                                                                    {(childItem.sub.answer.length == 1) &&
                                                                                                        <CssTextField2
                                                                                                            label={subItem.answer}
                                                                                                            InputLabelProps={{
                                                                                                                shrink: true
                                                                                                            }}
                                                                                                            value={questions[index].selected.subAnswer[0]}
                                                                                                            onChange={(e) => {
                                                                                                                console.log(e);
                                                                                                                let t = [...questions];
                                                                                                                questions[index].selected.subAnswer = [e.target.value];
                                                                                                                setQuestions(t);
                                                                                                            }}
                                                                                                            sx={{
                                                                                                                input: { color: 'white' },
                                                                                                                label: { color: 'white' },
                                                                                                                fieldset: {
                                                                                                                    transition: 'all 1s ease',
                                                                                                                    transform: showSubQuestion.includes('answer-' + index + '-' + childIndex) ? 'scaleY(1)' : 'scaleY(0)'
                                                                                                                }
                                                                                                            }}
                                                                                                            style={{
                                                                                                                marginTop: 10,
                                                                                                                marginBottom: 5,
                                                                                                                width: '80%',
                                                                                                                transformOrigin: 'top',
                                                                                                                transition: 'all 0.1s ease-in',
                                                                                                                transform: showSubQuestion.includes('answer-' + index + '-' + childIndex) ? 'scaleY(1)' : 'scaleY(0)'
                                                                                                            }}
                                                                                                            autoComplete={'off'}
                                                                                                        />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    </>
                                                                                );
                                                                            })}
                                                                            {(childItem.sub && childItem.sub.length > 0) && childItem.sub.map((subItem, subIndex) => {
                                                                                if (subItem.type == 'autocomplete') {
                                                                                    return (
                                                                                        <>
                                                                                            {subItem.autocompleteType == 'Studium' ? <>
                                                                                                <FormControl
                                                                                                    sx={{
                                                                                                        width: '80%'
                                                                                                    }}
                                                                                                    style={{
                                                                                                        marginTop: 5,
                                                                                                        transition: 'all 0.15s ease',
                                                                                                        minHeight: 46,
                                                                                                    }}
                                                                                                    className={'testClassSelect whiteLabel'}>
                                                                                                    <Select2
                                                                                                        id={'studiumDropdown'}
                                                                                                        value={questions[index].selected.dropdown ?? ''}
                                                                                                        onChange={(e) => {
                                                                                                            let tq = [...questions];
                                                                                                            console.log(e.target.value);
                                                                                                            console.log(tq[index].selected.autocomplete);
                                                                                                            tq[index].selected.dropdown = e.target.value;
                                                                                                            // tq[index].selected.autocomplete = e.target.value + tq[index].selected.autocomplete;
                                                                                                            setMultiSelectOpen(false);
                                                                                                            setQuestions(tq);
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            if (!multiSelectOpen) setMultiSelectOpen(index)
                                                                                                        }}
                                                                                                        onClose={() => {
                                                                                                            setMultiSelectOpen(false);
                                                                                                        }}
                                                                                                        open={multiSelectOpen == index}
                                                                                                        defaultValue={false}
                                                                                                        fullWidth
                                                                                                        on
                                                                                                        style={{
                                                                                                            width: '100%',
                                                                                                            marginBottom: 10,
                                                                                                            color: 'white',
                                                                                                        }}
                                                                                                        MenuProps={MenuProps}
                                                                                                        input={
                                                                                                            <Select2/>}
                                                                                                    >
                                                                                                        <MenuItem
                                                                                                            disabled
                                                                                                            value="">
                                                                                                        </MenuItem>
                                                                                                        <MenuItem
                                                                                                            value={'Bachelor'}>Bachelor</MenuItem>
                                                                                                        <MenuItem
                                                                                                            value={'Master'}>Master</MenuItem>
                                                                                                        <MenuItem
                                                                                                            value={'Diplom - Diplomgrad'}>Diplom
                                                                                                            -
                                                                                                            Diplomgrad</MenuItem>
                                                                                                        <MenuItem
                                                                                                            value={'Staatsexamen'}>Staatsexamen</MenuItem>
                                                                                                        <MenuItem
                                                                                                            value={'Magister-Magistergrad'}>Magister-Magistergrad</MenuItem>
                                                                                                    </Select2>
                                                                                                </FormControl>
                                                                                            </> : null}
                                                                                            <Autocomplete key={subIndex}
                                                                                                          options={questions[index].answers[childIndex].sub[subIndex].autocomplete ?? []}
                                                                                                          getOptionLabel={(option) => {
                                                                                                              return (subItem.autocompleteType != 'Studium' || option.title) ? option.title : (Array.isArray(option) && option[0] && option[0].title) ? option[0].title : '';
                                                                                                          }}
                                                                                                          dataSourceConfig={{
                                                                                                              text: props.title,
                                                                                                              value: props.input
                                                                                                          }}
                                                                                                          ref={autocompleteRef}
                                                                                                          name={''}
                                                                                                          freeSolo
                                                                                                // multiple={subItem.autocompleteType != 'Studium'}
                                                                                                          multiple
                                                                                                // value={(subItem.autocompleteType != 'Studium') ? questions[index].selected.autocomplete : questions[index].selected.autocomplete[0] }
                                                                                                          value={questions[index].selected.autocomplete}
                                                                                                          onChange={(event, newValue) => {
                                                                                                              let temp = [...questions];
                                                                                                              let t = [];
                                                                                                              if (newValue && !Array.isArray(newValue)) {
                                                                                                                  if (typeof newValue === 'string') {
                                                                                                                      t = [{
                                                                                                                          input: newValue,
                                                                                                                          title: newValue,
                                                                                                                      }];
                                                                                                                  } else if (newValue && newValue.title) {
                                                                                                                      // Create a new value from the user input
                                                                                                                      t = [{
                                                                                                                          input: newValue.title,
                                                                                                                          title: newValue.title,
                                                                                                                      }];
                                                                                                                  } else {
                                                                                                                      t = [newValue];
                                                                                                                  }
                                                                                                              } else if (newValue) {
                                                                                                                  newValue.map((item, index) => {
                                                                                                                      // let tem = item;
                                                                                                                      // if (subItem.autocompleteType == 'Studium') tem = ' ' + tem;
                                                                                                                      if (typeof item === 'string') {
                                                                                                                          t.push({
                                                                                                                              input: item,
                                                                                                                              title: item,
                                                                                                                          });
                                                                                                                      } else if (item && item.title) {
                                                                                                                          // Create a new value from the user input
                                                                                                                          t.push({
                                                                                                                              input: item.title,
                                                                                                                              title: item.title,
                                                                                                                          });
                                                                                                                      } else {
                                                                                                                          t.push(item);
                                                                                                                      }
                                                                                                                  })
                                                                                                              }
                                                                                                              temp[index].selected.autocomplete = t;
                                                                                                              temp[index].selected.autocompleteInput = '';
                                                                                                              temp[index].selected.autocompleteInputBak = '';
                                                                                                              console.log(temp[index].selected.autocomplete);
                                                                                                              setQuestions(temp);
                                                                                                              setIsFocused(null)
                                                                                                          }}
                                                                                                          loading={autocompleteLoading == (index + '-' + childIndex + '-' + subIndex)}
                                                                                                          inputValue={questions[index].selected.autocompleteInput}
                                                                                                          onInputChange={async (event, newInputValue) => {
                                                                                                              setIsFocused((index + '-' + childIndex + '-' + subIndex))
                                                                                                              let temp = [...questions];
                                                                                                              let t = [];


                                                                                                              if (newInputValue) {
                                                                                                                  console.log(subItem.autocompleteType);

                                                                                                                  if (subItem.autocompleteType && newInputValue && newInputValue.length > 0) {
                                                                                                                      let url = ENV.REACT_APP_API_URL + '/autocompletes?filter%5BautocompleteType%5D%5Beq%5D=' + subItem.autocompleteType + '&filter%5BautocompleteData%5D%5Blike%5D=' + newInputValue;
                                                                                                                      setAutocompleteLoading(index + '-' + childIndex + '-' + subIndex);
                                                                                                                      debouncedChangeHandler(url, 2, temp, index, childIndex, subIndex);
                                                                                                                  }
                                                                                                              }
                                                                                                              if (newInputValue.length > 0 || keyDown && newInputValue.length == 0) temp[index].selected.autocompleteInputBak = newInputValue;
                                                                                                              if (!temp[index].answers[childIndex].sub[subIndex].autocomplete) temp[index].answers[childIndex].sub[subIndex].autocomplete = [];
                                                                                                              temp[index].selected.autocompleteInput = newInputValue;
                                                                                                              setQuestions(temp);
                                                                                                          }}
                                                                                                // open={questions[index].selected.autocompleteInput.length > 2 && questions[index].answers[childIndex].sub[subIndex].autocomplete && (!questions[index].selected.autocomplete || questions[index].selected.autocomplete != questions[index].selected.autocompleteInput)}
                                                                                                //           open={questions[index].selected.autocompleteInput.length > 2}
                                                                                                          className={'text-input-label-white'}
                                                                                                // open={questions[index].answers[childIndex].sub[subIndex].autocomplete.length > 0 && isFocused == (index + '-' + childIndex + '-' + subIndex) && (questions[index].selected.autocompleteInput != questions[index].selected.autocomplete)}
                                                                                                          open={(questions[index].selected.autocompleteInput.length > 0 && isFocused == (index + '-' + childIndex + '-' + subIndex))}
                                                                                                          groupBy={(option) => 'Vorschläge'}
                                                                                                // className={'text-input-label-blue ' + (isFocused === 'Berufe' ? '' : '')}
                                                                                                          onFocus={() => {
                                                                                                              setIsFocused((index + '-' + childIndex + '-' + subIndex))
                                                                                                          }}
                                                                                                          onKeyDown={(e) => {
                                                                                                              if (e.keyCode == 8 || e.keyCode == 46) setKeyDown(true)
                                                                                                              else setKeyDown(false)
                                                                                                          }}
                                                                                                          onBlur={() => {
                                                                                                              setIsFocused(null)
                                                                                                              if (questions[index].selected.autocompleteInputBak) {
                                                                                                                  let temp = [...questions];
                                                                                                                  temp[index].selected.autocomplete.push({
                                                                                                                      input: questions[index].selected.autocompleteInputBak,
                                                                                                                      title: questions[index].selected.autocompleteInputBak
                                                                                                                  });
                                                                                                                  temp[index].selected.autocompleteInput = '';
                                                                                                                  temp[index].selected.autocompleteInputBak = '';
                                                                                                                  setQuestions(temp);
                                                                                                              }
                                                                                                              setKeyDown(false);
                                                                                                          }}
                                                                                                          selectOnFocus
                                                                                                          clearOnBlur
                                                                                                          handleHomeEndKeys
                                                                                                          renderInput={(params) =>
                                                                                                              <>
                                                                                                                  <CssTextField2 {...params}
                                                                                                                                 sx={{ input: { color: 'white' } }}
                                                                                                                                 label={childItem.sub[subIndex].label}
                                                                                                                                 InputLabelProps={{
                                                                                                                                     shrink: true
                                                                                                                                 }}
                                                                                                                                 variant="outlined"
                                                                                                                                 style={{
                                                                                                                                     width: '80%',
                                                                                                                                     margin: '5px 0 0 0',
                                                                                                                                     padding: 0,
                                                                                                                                     transition: 'all 1s ease',
                                                                                                                                     transform: showSubQuestion.includes('answer-' + index + '-' + childIndex) ? 'scaleY(1)' : 'scaleY(0)'
                                                                                                                                 }}
                                                                                                                                 error={(!autocompleteLoading && questions[index].selected.autocompleteInput.length > 2 && (!questions[index].answers[childIndex].sub[subIndex].autocomplete || questions[index].answers[childIndex].sub[subIndex].autocomplete.length == 0) && questions[index].selected.autocomplete.length == 0)}
                                                                                                                                 helperText={'Nutze wenn möglich unsere Vorschläge!'}
                                                                                                                  /></>
                                                                                                          }
                                                                                            /></>
                                                                                    );
                                                                                }
                                                                            })}
                                                                        </div>}
                                                                </div>}
                                                        </>
                                                    );
                                                })}

                                            </Grid>
                                        );
                                    }
                                })}
                            </Grid>
                            {/*{questions[activeQuestion - 1].type === 'logos' ? <p style={{minHeight: 30, margin: 0, color: 'white', fontSize: 12}}>**<InfoIcon fontSize={'14px'} id={'infoIcon'}/>Test</p> : <p style={{minHeight: 30, margin: 0}}></p>}*/}

                        </div>
                    </Grid>
                </Grid>}
            </Grid>
        </Grid>
    );
}

